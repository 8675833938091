import { default as api_45legacyawf7340sCkMeta } from "/codebuild/output/src4175480164/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardszcJo7Jxqy8Meta } from "/codebuild/output/src4175480164/src/horizon/pages/awards.vue?macro=true";
import { default as programsbBRd9ltIM9Meta } from "/codebuild/output/src4175480164/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_934meCE1XTcoMeta } from "/codebuild/output/src4175480164/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93es66nalrfwMeta } from "/codebuild/output/src4175480164/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as indexSWqwXYhA6wMeta } from "/codebuild/output/src4175480164/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta } from "/codebuild/output/src4175480164/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_93vdiqUBkm3AMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93AwnZtlFyT3Meta } from "/codebuild/output/src4175480164/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutzBNYEt8gKPMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/about.vue?macro=true";
import { default as indexEiAc7S7AWbMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productUJFo1YbAzOMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as indexJWtrwlD7yOMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as sales4rFn91HzuGMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talent_45archivegIj5kSOtabMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/careers/emerging-talent-archive.vue?macro=true";
import { default as indexYckK0SQAI2Meta } from "/codebuild/output/src4175480164/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as indexEaDpFbnDunMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_93l9qjcyNCK5Meta } from "/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as index971OraNAVLMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as diversityABnjUNpvOgMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/diversity.vue?macro=true";
import { default as indexY3N36IiCGsMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiencyJKYRwhSbEDMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safety2kTrxxQKwfMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilitywcg3L9Dk51Meta } from "/codebuild/output/src4175480164/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45newssqkP5NwBiBMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as indexqe2xINEGkWMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releasesD7kIOpp8KlMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacy1p952uwlTHMeta } from "/codebuild/output/src4175480164/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45toolkRQMSBIm8nMeta } from "/codebuild/output/src4175480164/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93kPbNKRc5BlMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as _91slug_938KlIZKJNX8Meta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/category/[slug].vue?macro=true";
import { default as index2P43gM3uljMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_939FhroCuTQAMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93MFgIwY3QikMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as index4JoKJHp1AsMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_93YpiSfEWgP7Meta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyond6koZgQ6HZ7Meta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performance2E2ra93AgAMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45service8Kjs1KEz0gMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indexYZTy42N2wSMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleetgT5tVvyJJOMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performanceEr9JMrnlRmMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performancexewSZKsmxaMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovator5mUJUJ9lgrMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as indexiPvnvT5qwHMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleet9LfFdFjNdhMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetPVf1W2CvsUMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexabVEPxWNROMeta } from "/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_93uAOYu4YPsIMeta } from "/codebuild/output/src4175480164/src/horizon/pages/events/[slug].vue?macro=true";
import { default as indexCeKf8Ywrj3Meta } from "/codebuild/output/src4175480164/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45app2w7NmdnEqsMeta } from "/codebuild/output/src4175480164/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93OfO36U4Y8MMeta } from "/codebuild/output/src4175480164/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93L3tThDqAIxMeta } from "/codebuild/output/src4175480164/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_93pzqADtP4cvMeta } from "/codebuild/output/src4175480164/src/horizon/pages/fleets/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93V0qi3DRxzKMeta } from "/codebuild/output/src4175480164/src/horizon/pages/fleets/[slug].vue?macro=true";
import { default as _91slug_938e0IVmYR1nMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talentTBCczFCE0hMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitslgKMk6dToaMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indexVpBof3OsXmMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assets6Os95zlXV8Meta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camsAZgWIpyndfMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as indexKTNueYckPgMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45programOklFRGIYT5Meta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roiSJcAVFvR2dMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as indexGd13FBnpmjMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futureVC7lkcdvCEMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teamsU5gwcoUSIVMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as index1nf5P7ltKqMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45dataGaTlEOCQDhMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93lgm9eyhHhfMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as indexe5R6dHYSRgMeta } from "/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexpaIotLc4P3Meta } from "/codebuild/output/src4175480164/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_93hCa8WA57lxMeta } from "/codebuild/output/src4175480164/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93FNCcBmbNRxMeta } from "/codebuild/output/src4175480164/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexfC14CKilboMeta } from "/codebuild/output/src4175480164/src/horizon/pages/industries/index.vue?macro=true";
import { default as citiessbwXB45ZnuMeta } from "/codebuild/output/src4175480164/src/horizon/pages/insights/cities.vue?macro=true";
import { default as indexCABvDyXRLZMeta } from "/codebuild/output/src4175480164/src/horizon/pages/integrations/index.vue?macro=true";
import { default as indexFhR6aylbtVMeta } from "/codebuild/output/src4175480164/src/horizon/pages/invite/index.vue?macro=true";
import { default as candidate_45privacy_45noticecZ4rqKACP6Meta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consentg3B76sWBqQMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policyKLdBKHMcnKMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendumokjRXGKIP5Meta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45terms69tuJrR83SMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantyozBwD0bNRPMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45sla2QsoCVK2AzMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conductqaYX0hsu54Meta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45serviceEGnIs8EVcFMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacyF6KlK2iQLqMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdataIELvq6b1iDMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as securityxiW78NN4VXMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreporttQ7OgIAAf9Meta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45servicep1IWmcUxwcMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choicesDgA6UECJ5ZMeta } from "/codebuild/output/src4175480164/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as pricingmWztgs5K0xMeta } from "/codebuild/output/src4175480164/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_93EjPW2TKQttMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93Kw9vumItN7Meta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45trackingeU68b0yPgLMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camsvREDIv6beQMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as constructionynyqYFuyaNMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/construction.vue?macro=true";
import { default as eversourceT4a0aZvfLHMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/eversource.vue?macro=true";
import { default as fleet_45managementjYXKOoW9xvMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as groundworkseRf6whAVbVMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/groundworks.vue?macro=true";
import { default as nationwidejxSFzxQPNlMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coachingWbecDGcH8fMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as unifirstavN06l0AoqMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pages/unifirst.vue?macro=true";
import { default as pricingdbygTTfXDiMeta } from "/codebuild/output/src4175480164/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_93jBQyDDUivPMeta } from "/codebuild/output/src4175480164/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93NGDHIEp0IxMeta } from "/codebuild/output/src4175480164/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingLhVRcRq4wWMeta } from "/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackingDk8nTdmxI6Meta } from "/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoringJJVRuUMTy4Meta } from "/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailersZ41Newpcw9Meta } from "/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45trackingHmJSQ0It3nMeta } from "/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as indexFHc6BUtMgvMeta } from "/codebuild/output/src4175480164/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_932Wp6HxR2NIMeta } from "/codebuild/output/src4175480164/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as indexmBJfwxce0tMeta } from "/codebuild/output/src4175480164/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackingZQtDZiZAlHMeta } from "/codebuild/output/src4175480164/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexoaZmV9mShiMeta } from "/codebuild/output/src4175480164/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as racingXTEYkG7JaxMeta } from "/codebuild/output/src4175480164/src/horizon/pages/racing.vue?macro=true";
import { default as referralsTp5qxmqaQUMeta } from "/codebuild/output/src4175480164/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_9334oAVpEPDSMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assetsArIxAeEcIjMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_93kNhM2dS05HMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45centerNkhiHenB7KMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as one_45click_45unsubscribesaRJD5huCEMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/content/one-click-unsubscribe.vue?macro=true";
import { default as _91slug_93yGpPGkLJ5oMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_93jiNna9Q2ETMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as indexNHrgcTSdwIMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as indexfUyYJ35gmlMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_934NfcnY1JzJMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93sIIiOK2yTEMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as indexiDJYkZIXYOMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsaralHKuJMVYqPMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programsFrrJ4aIlQzMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as indexca3TY5VxaWMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45services90LQOCu7wHMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsaraS2VvV1Fyb2Meta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as tourkESnyehwixMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videosJWIikqaR5wMeta } from "/codebuild/output/src4175480164/src/horizon/pages/resources/videos.vue?macro=true";
import { default as index9URiEWL8zhMeta } from "/codebuild/output/src4175480164/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45venturestCXtU9bcR9Meta } from "/codebuild/output/src4175480164/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summitrNtqf48VBSMeta } from "/codebuild/output/src4175480164/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_93qS2DwTA9mkMeta } from "/codebuild/output/src4175480164/src/horizon/pages/solutions/[prefix]/[slug].vue?macro=true";
import { default as _91slug_934lfuVQmOE6Meta } from "/codebuild/output/src4175480164/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as indexqxh7Nmn38OMeta } from "/codebuild/output/src4175480164/src/horizon/pages/solutions/index.vue?macro=true";
import { default as securityus976xk6FLMeta } from "/codebuild/output/src4175480164/src/horizon/pages/solutions/security.vue?macro=true";
import { default as spacecd19qZV4NYMeta } from "/codebuild/output/src4175480164/src/horizon/pages/space.vue?macro=true";
import { default as sparkvZKWohaP0gMeta } from "/codebuild/output/src4175480164/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93E7oW3W1KcXMeta } from "/codebuild/output/src4175480164/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_93aapXIBEJymMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_932XNRJJC65zMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policyeqjh8B9RMmMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faqnTkxFFQku6Meta } from "/codebuild/output/src4175480164/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanishk3UtU6ieWHMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45trainingckSbGAxpo5Meta } from "/codebuild/output/src4175480164/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as indextiCSl59T8jMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/index.vue?macro=true";
import { default as industrialUdZmMmJd3hMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statementdY1HbKgXbnMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policy5FBfJteFgmMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexXSyJEaYsJsMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45features4p9tFFzp6dMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listfr6w7X571UMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlesFeWOilHESwMeta } from "/codebuild/output/src4175480164/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pagex9ygZipSw7Meta } from "/codebuild/output/src4175480164/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruitingvSWGtuBsn0Meta } from "/codebuild/output/src4175480164/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referraltgK9BjQfOTMeta } from "/codebuild/output/src4175480164/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyouq81R1a4fQSMeta } from "/codebuild/output/src4175480164/src/horizon/pages/thankyou.vue?macro=true";
import { default as eliteQyxMSOUcZ4Meta } from "/codebuild/output/src4175480164/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as plusvBMspXsz8TMeta } from "/codebuild/output/src4175480164/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premierm3iOrktZiNMeta } from "/codebuild/output/src4175480164/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starterFbtt011i9vMeta } from "/codebuild/output/src4175480164/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processc0CdcLatrvMeta } from "/codebuild/output/src4175480164/src/horizon/pages/trial/process.vue?macro=true";
import { default as supportMXB8D6RRhsMeta } from "/codebuild/output/src4175480164/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93ILlhcSalJaMeta } from "/codebuild/output/src4175480164/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as index15anEVp2m9Meta } from "/codebuild/output/src4175480164/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_93qwBmwClP0kMeta } from "/codebuild/output/src4175480164/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
export default [
  {
    name: api_45legacyawf7340sCkMeta?.name ?? "api-legacy___en-US",
    path: api_45legacyawf7340sCkMeta?.path ?? "/api-legacy",
    meta: api_45legacyawf7340sCkMeta || {},
    alias: api_45legacyawf7340sCkMeta?.alias || [],
    redirect: api_45legacyawf7340sCkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyawf7340sCkMeta?.name ?? "api-legacy___ca",
    path: api_45legacyawf7340sCkMeta?.path ?? "/ca/api-legacy",
    meta: api_45legacyawf7340sCkMeta || {},
    alias: api_45legacyawf7340sCkMeta?.alias || [],
    redirect: api_45legacyawf7340sCkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyawf7340sCkMeta?.name ?? "api-legacy___nl",
    path: api_45legacyawf7340sCkMeta?.path ?? "/nl/api-legacy",
    meta: api_45legacyawf7340sCkMeta || {},
    alias: api_45legacyawf7340sCkMeta?.alias || [],
    redirect: api_45legacyawf7340sCkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyawf7340sCkMeta?.name ?? "api-legacy___uk",
    path: api_45legacyawf7340sCkMeta?.path ?? "/uk/api-legacy",
    meta: api_45legacyawf7340sCkMeta || {},
    alias: api_45legacyawf7340sCkMeta?.alias || [],
    redirect: api_45legacyawf7340sCkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyawf7340sCkMeta?.name ?? "api-legacy___de",
    path: api_45legacyawf7340sCkMeta?.path ?? "/de/api-legacy",
    meta: api_45legacyawf7340sCkMeta || {},
    alias: api_45legacyawf7340sCkMeta?.alias || [],
    redirect: api_45legacyawf7340sCkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyawf7340sCkMeta?.name ?? "api-legacy___mx",
    path: api_45legacyawf7340sCkMeta?.path ?? "/mx/api-legacy",
    meta: api_45legacyawf7340sCkMeta || {},
    alias: api_45legacyawf7340sCkMeta?.alias || [],
    redirect: api_45legacyawf7340sCkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyawf7340sCkMeta?.name ?? "api-legacy___fr",
    path: api_45legacyawf7340sCkMeta?.path ?? "/fr/api-legacy",
    meta: api_45legacyawf7340sCkMeta || {},
    alias: api_45legacyawf7340sCkMeta?.alias || [],
    redirect: api_45legacyawf7340sCkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyawf7340sCkMeta?.name ?? "api-legacy___fr-ca",
    path: api_45legacyawf7340sCkMeta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacyawf7340sCkMeta || {},
    alias: api_45legacyawf7340sCkMeta?.alias || [],
    redirect: api_45legacyawf7340sCkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awardszcJo7Jxqy8Meta?.name ?? "awards___en-US",
    path: awardszcJo7Jxqy8Meta?.path ?? "/awards",
    meta: awardszcJo7Jxqy8Meta || {},
    alias: awardszcJo7Jxqy8Meta?.alias || [],
    redirect: awardszcJo7Jxqy8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardszcJo7Jxqy8Meta?.name ?? "awards___ca",
    path: awardszcJo7Jxqy8Meta?.path ?? "/ca/awards",
    meta: awardszcJo7Jxqy8Meta || {},
    alias: awardszcJo7Jxqy8Meta?.alias || [],
    redirect: awardszcJo7Jxqy8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardszcJo7Jxqy8Meta?.name ?? "awards___nl",
    path: awardszcJo7Jxqy8Meta?.path ?? "/nl/awards",
    meta: awardszcJo7Jxqy8Meta || {},
    alias: awardszcJo7Jxqy8Meta?.alias || [],
    redirect: awardszcJo7Jxqy8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardszcJo7Jxqy8Meta?.name ?? "awards___uk",
    path: awardszcJo7Jxqy8Meta?.path ?? "/uk/awards",
    meta: awardszcJo7Jxqy8Meta || {},
    alias: awardszcJo7Jxqy8Meta?.alias || [],
    redirect: awardszcJo7Jxqy8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardszcJo7Jxqy8Meta?.name ?? "awards___de",
    path: awardszcJo7Jxqy8Meta?.path ?? "/de/awards",
    meta: awardszcJo7Jxqy8Meta || {},
    alias: awardszcJo7Jxqy8Meta?.alias || [],
    redirect: awardszcJo7Jxqy8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardszcJo7Jxqy8Meta?.name ?? "awards___mx",
    path: awardszcJo7Jxqy8Meta?.path ?? "/mx/awards",
    meta: awardszcJo7Jxqy8Meta || {},
    alias: awardszcJo7Jxqy8Meta?.alias || [],
    redirect: awardszcJo7Jxqy8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardszcJo7Jxqy8Meta?.name ?? "awards___fr",
    path: awardszcJo7Jxqy8Meta?.path ?? "/fr/awards",
    meta: awardszcJo7Jxqy8Meta || {},
    alias: awardszcJo7Jxqy8Meta?.alias || [],
    redirect: awardszcJo7Jxqy8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardszcJo7Jxqy8Meta?.name ?? "awards___fr-ca",
    path: awardszcJo7Jxqy8Meta?.path ?? "/fr-ca/awards",
    meta: awardszcJo7Jxqy8Meta || {},
    alias: awardszcJo7Jxqy8Meta?.alias || [],
    redirect: awardszcJo7Jxqy8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programsbBRd9ltIM9Meta?.name ?? "benefits-programs___en-US",
    path: programsbBRd9ltIM9Meta?.path ?? "/benefits/programs",
    meta: programsbBRd9ltIM9Meta || {},
    alias: programsbBRd9ltIM9Meta?.alias || [],
    redirect: programsbBRd9ltIM9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbBRd9ltIM9Meta?.name ?? "benefits-programs___ca",
    path: programsbBRd9ltIM9Meta?.path ?? "/ca/benefits/programs",
    meta: programsbBRd9ltIM9Meta || {},
    alias: programsbBRd9ltIM9Meta?.alias || [],
    redirect: programsbBRd9ltIM9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbBRd9ltIM9Meta?.name ?? "benefits-programs___nl",
    path: programsbBRd9ltIM9Meta?.path ?? "/nl/benefits/programs",
    meta: programsbBRd9ltIM9Meta || {},
    alias: programsbBRd9ltIM9Meta?.alias || [],
    redirect: programsbBRd9ltIM9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbBRd9ltIM9Meta?.name ?? "benefits-programs___uk",
    path: programsbBRd9ltIM9Meta?.path ?? "/uk/benefits/programs",
    meta: programsbBRd9ltIM9Meta || {},
    alias: programsbBRd9ltIM9Meta?.alias || [],
    redirect: programsbBRd9ltIM9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbBRd9ltIM9Meta?.name ?? "benefits-programs___de",
    path: programsbBRd9ltIM9Meta?.path ?? "/de/benefits/programs",
    meta: programsbBRd9ltIM9Meta || {},
    alias: programsbBRd9ltIM9Meta?.alias || [],
    redirect: programsbBRd9ltIM9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbBRd9ltIM9Meta?.name ?? "benefits-programs___mx",
    path: programsbBRd9ltIM9Meta?.path ?? "/mx/benefits/programs",
    meta: programsbBRd9ltIM9Meta || {},
    alias: programsbBRd9ltIM9Meta?.alias || [],
    redirect: programsbBRd9ltIM9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbBRd9ltIM9Meta?.name ?? "benefits-programs___fr",
    path: programsbBRd9ltIM9Meta?.path ?? "/fr/benefits/programs",
    meta: programsbBRd9ltIM9Meta || {},
    alias: programsbBRd9ltIM9Meta?.alias || [],
    redirect: programsbBRd9ltIM9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbBRd9ltIM9Meta?.name ?? "benefits-programs___fr-ca",
    path: programsbBRd9ltIM9Meta?.path ?? "/fr-ca/benefits/programs",
    meta: programsbBRd9ltIM9Meta || {},
    alias: programsbBRd9ltIM9Meta?.alias || [],
    redirect: programsbBRd9ltIM9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934meCE1XTcoMeta?.name ?? "blog-slug___en-US",
    path: _91slug_934meCE1XTcoMeta?.path ?? "/blog/:slug()",
    meta: _91slug_934meCE1XTcoMeta || {},
    alias: _91slug_934meCE1XTcoMeta?.alias || [],
    redirect: _91slug_934meCE1XTcoMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934meCE1XTcoMeta?.name ?? "blog-slug___ca",
    path: _91slug_934meCE1XTcoMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_934meCE1XTcoMeta || {},
    alias: _91slug_934meCE1XTcoMeta?.alias || [],
    redirect: _91slug_934meCE1XTcoMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934meCE1XTcoMeta?.name ?? "blog-slug___mx",
    path: _91slug_934meCE1XTcoMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_934meCE1XTcoMeta || {},
    alias: _91slug_934meCE1XTcoMeta?.alias || [],
    redirect: _91slug_934meCE1XTcoMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934meCE1XTcoMeta?.name ?? "blog-slug___uk",
    path: _91slug_934meCE1XTcoMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_934meCE1XTcoMeta || {},
    alias: _91slug_934meCE1XTcoMeta?.alias || [],
    redirect: _91slug_934meCE1XTcoMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934meCE1XTcoMeta?.name ?? "blog-slug___fr",
    path: _91slug_934meCE1XTcoMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_934meCE1XTcoMeta || {},
    alias: _91slug_934meCE1XTcoMeta?.alias || [],
    redirect: _91slug_934meCE1XTcoMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934meCE1XTcoMeta?.name ?? "blog-slug___de",
    path: _91slug_934meCE1XTcoMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_934meCE1XTcoMeta || {},
    alias: _91slug_934meCE1XTcoMeta?.alias || [],
    redirect: _91slug_934meCE1XTcoMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93es66nalrfwMeta?.name ?? "blog-category-slug___en-US",
    path: _91slug_93es66nalrfwMeta?.path ?? "/blog/category/:slug()",
    meta: _91slug_93es66nalrfwMeta || {},
    alias: _91slug_93es66nalrfwMeta?.alias || [],
    redirect: _91slug_93es66nalrfwMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93es66nalrfwMeta?.name ?? "blog-category-slug___ca",
    path: _91slug_93es66nalrfwMeta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_93es66nalrfwMeta || {},
    alias: _91slug_93es66nalrfwMeta?.alias || [],
    redirect: _91slug_93es66nalrfwMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93es66nalrfwMeta?.name ?? "blog-category-slug___mx",
    path: _91slug_93es66nalrfwMeta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_93es66nalrfwMeta || {},
    alias: _91slug_93es66nalrfwMeta?.alias || [],
    redirect: _91slug_93es66nalrfwMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93es66nalrfwMeta?.name ?? "blog-category-slug___uk",
    path: _91slug_93es66nalrfwMeta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_93es66nalrfwMeta || {},
    alias: _91slug_93es66nalrfwMeta?.alias || [],
    redirect: _91slug_93es66nalrfwMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93es66nalrfwMeta?.name ?? "blog-category-slug___fr",
    path: _91slug_93es66nalrfwMeta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_93es66nalrfwMeta || {},
    alias: _91slug_93es66nalrfwMeta?.alias || [],
    redirect: _91slug_93es66nalrfwMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93es66nalrfwMeta?.name ?? "blog-category-slug___de",
    path: _91slug_93es66nalrfwMeta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_93es66nalrfwMeta || {},
    alias: _91slug_93es66nalrfwMeta?.alias || [],
    redirect: _91slug_93es66nalrfwMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexSWqwXYhA6wMeta?.name ?? "blog___en-US",
    path: indexSWqwXYhA6wMeta?.path ?? "/blog",
    meta: indexSWqwXYhA6wMeta || {},
    alias: indexSWqwXYhA6wMeta?.alias || [],
    redirect: indexSWqwXYhA6wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWqwXYhA6wMeta?.name ?? "blog___ca",
    path: indexSWqwXYhA6wMeta?.path ?? "/ca/blog",
    meta: indexSWqwXYhA6wMeta || {},
    alias: indexSWqwXYhA6wMeta?.alias || [],
    redirect: indexSWqwXYhA6wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWqwXYhA6wMeta?.name ?? "blog___mx",
    path: indexSWqwXYhA6wMeta?.path ?? "/mx/blog",
    meta: indexSWqwXYhA6wMeta || {},
    alias: indexSWqwXYhA6wMeta?.alias || [],
    redirect: indexSWqwXYhA6wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWqwXYhA6wMeta?.name ?? "blog___uk",
    path: indexSWqwXYhA6wMeta?.path ?? "/uk/blog",
    meta: indexSWqwXYhA6wMeta || {},
    alias: indexSWqwXYhA6wMeta?.alias || [],
    redirect: indexSWqwXYhA6wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWqwXYhA6wMeta?.name ?? "blog___fr",
    path: indexSWqwXYhA6wMeta?.path ?? "/fr/blog",
    meta: indexSWqwXYhA6wMeta || {},
    alias: indexSWqwXYhA6wMeta?.alias || [],
    redirect: indexSWqwXYhA6wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWqwXYhA6wMeta?.name ?? "blog___de",
    path: indexSWqwXYhA6wMeta?.path ?? "/de/blog",
    meta: indexSWqwXYhA6wMeta || {},
    alias: indexSWqwXYhA6wMeta?.alias || [],
    redirect: indexSWqwXYhA6wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondhWCRKYIylkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vdiqUBkm3AMeta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_93vdiqUBkm3AMeta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_93vdiqUBkm3AMeta || {},
    alias: _91slug_93vdiqUBkm3AMeta?.alias || [],
    redirect: _91slug_93vdiqUBkm3AMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vdiqUBkm3AMeta?.name ?? "company-prefix-slug___ca",
    path: _91slug_93vdiqUBkm3AMeta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_93vdiqUBkm3AMeta || {},
    alias: _91slug_93vdiqUBkm3AMeta?.alias || [],
    redirect: _91slug_93vdiqUBkm3AMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vdiqUBkm3AMeta?.name ?? "company-prefix-slug___nl",
    path: _91slug_93vdiqUBkm3AMeta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_93vdiqUBkm3AMeta || {},
    alias: _91slug_93vdiqUBkm3AMeta?.alias || [],
    redirect: _91slug_93vdiqUBkm3AMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vdiqUBkm3AMeta?.name ?? "company-prefix-slug___uk",
    path: _91slug_93vdiqUBkm3AMeta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_93vdiqUBkm3AMeta || {},
    alias: _91slug_93vdiqUBkm3AMeta?.alias || [],
    redirect: _91slug_93vdiqUBkm3AMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vdiqUBkm3AMeta?.name ?? "company-prefix-slug___de",
    path: _91slug_93vdiqUBkm3AMeta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_93vdiqUBkm3AMeta || {},
    alias: _91slug_93vdiqUBkm3AMeta?.alias || [],
    redirect: _91slug_93vdiqUBkm3AMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vdiqUBkm3AMeta?.name ?? "company-prefix-slug___mx",
    path: _91slug_93vdiqUBkm3AMeta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_93vdiqUBkm3AMeta || {},
    alias: _91slug_93vdiqUBkm3AMeta?.alias || [],
    redirect: _91slug_93vdiqUBkm3AMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vdiqUBkm3AMeta?.name ?? "company-prefix-slug___fr",
    path: _91slug_93vdiqUBkm3AMeta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_93vdiqUBkm3AMeta || {},
    alias: _91slug_93vdiqUBkm3AMeta?.alias || [],
    redirect: _91slug_93vdiqUBkm3AMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93vdiqUBkm3AMeta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_93vdiqUBkm3AMeta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_93vdiqUBkm3AMeta || {},
    alias: _91slug_93vdiqUBkm3AMeta?.alias || [],
    redirect: _91slug_93vdiqUBkm3AMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AwnZtlFyT3Meta?.name ?? "company-slug___en-US",
    path: _91slug_93AwnZtlFyT3Meta?.path ?? "/company/:slug()",
    meta: _91slug_93AwnZtlFyT3Meta || {},
    alias: _91slug_93AwnZtlFyT3Meta?.alias || [],
    redirect: _91slug_93AwnZtlFyT3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AwnZtlFyT3Meta?.name ?? "company-slug___ca",
    path: _91slug_93AwnZtlFyT3Meta?.path ?? "/ca/company/:slug()",
    meta: _91slug_93AwnZtlFyT3Meta || {},
    alias: _91slug_93AwnZtlFyT3Meta?.alias || [],
    redirect: _91slug_93AwnZtlFyT3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AwnZtlFyT3Meta?.name ?? "company-slug___nl",
    path: _91slug_93AwnZtlFyT3Meta?.path ?? "/nl/company/:slug()",
    meta: _91slug_93AwnZtlFyT3Meta || {},
    alias: _91slug_93AwnZtlFyT3Meta?.alias || [],
    redirect: _91slug_93AwnZtlFyT3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AwnZtlFyT3Meta?.name ?? "company-slug___uk",
    path: _91slug_93AwnZtlFyT3Meta?.path ?? "/uk/company/:slug()",
    meta: _91slug_93AwnZtlFyT3Meta || {},
    alias: _91slug_93AwnZtlFyT3Meta?.alias || [],
    redirect: _91slug_93AwnZtlFyT3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AwnZtlFyT3Meta?.name ?? "company-slug___de",
    path: _91slug_93AwnZtlFyT3Meta?.path ?? "/de/company/:slug()",
    meta: _91slug_93AwnZtlFyT3Meta || {},
    alias: _91slug_93AwnZtlFyT3Meta?.alias || [],
    redirect: _91slug_93AwnZtlFyT3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AwnZtlFyT3Meta?.name ?? "company-slug___mx",
    path: _91slug_93AwnZtlFyT3Meta?.path ?? "/mx/company/:slug()",
    meta: _91slug_93AwnZtlFyT3Meta || {},
    alias: _91slug_93AwnZtlFyT3Meta?.alias || [],
    redirect: _91slug_93AwnZtlFyT3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AwnZtlFyT3Meta?.name ?? "company-slug___fr",
    path: _91slug_93AwnZtlFyT3Meta?.path ?? "/fr/company/:slug()",
    meta: _91slug_93AwnZtlFyT3Meta || {},
    alias: _91slug_93AwnZtlFyT3Meta?.alias || [],
    redirect: _91slug_93AwnZtlFyT3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AwnZtlFyT3Meta?.name ?? "company-slug___fr-ca",
    path: _91slug_93AwnZtlFyT3Meta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_93AwnZtlFyT3Meta || {},
    alias: _91slug_93AwnZtlFyT3Meta?.alias || [],
    redirect: _91slug_93AwnZtlFyT3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutzBNYEt8gKPMeta?.name ?? "company-about___en-US",
    path: aboutzBNYEt8gKPMeta?.path ?? "/company/about",
    meta: aboutzBNYEt8gKPMeta || {},
    alias: aboutzBNYEt8gKPMeta?.alias || [],
    redirect: aboutzBNYEt8gKPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutzBNYEt8gKPMeta?.name ?? "company-about___ca",
    path: aboutzBNYEt8gKPMeta?.path ?? "/ca/company/about",
    meta: aboutzBNYEt8gKPMeta || {},
    alias: aboutzBNYEt8gKPMeta?.alias || [],
    redirect: aboutzBNYEt8gKPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutzBNYEt8gKPMeta?.name ?? "company-about___nl",
    path: aboutzBNYEt8gKPMeta?.path ?? "/nl/company/about",
    meta: aboutzBNYEt8gKPMeta || {},
    alias: aboutzBNYEt8gKPMeta?.alias || [],
    redirect: aboutzBNYEt8gKPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutzBNYEt8gKPMeta?.name ?? "company-about___uk",
    path: aboutzBNYEt8gKPMeta?.path ?? "/uk/company/about",
    meta: aboutzBNYEt8gKPMeta || {},
    alias: aboutzBNYEt8gKPMeta?.alias || [],
    redirect: aboutzBNYEt8gKPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutzBNYEt8gKPMeta?.name ?? "company-about___de",
    path: aboutzBNYEt8gKPMeta?.path ?? "/de/company/about",
    meta: aboutzBNYEt8gKPMeta || {},
    alias: aboutzBNYEt8gKPMeta?.alias || [],
    redirect: aboutzBNYEt8gKPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutzBNYEt8gKPMeta?.name ?? "company-about___mx",
    path: aboutzBNYEt8gKPMeta?.path ?? "/mx/company/about",
    meta: aboutzBNYEt8gKPMeta || {},
    alias: aboutzBNYEt8gKPMeta?.alias || [],
    redirect: aboutzBNYEt8gKPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutzBNYEt8gKPMeta?.name ?? "company-about___fr",
    path: aboutzBNYEt8gKPMeta?.path ?? "/fr/company/about",
    meta: aboutzBNYEt8gKPMeta || {},
    alias: aboutzBNYEt8gKPMeta?.alias || [],
    redirect: aboutzBNYEt8gKPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutzBNYEt8gKPMeta?.name ?? "company-about___fr-ca",
    path: aboutzBNYEt8gKPMeta?.path ?? "/fr-ca/company/about",
    meta: aboutzBNYEt8gKPMeta || {},
    alias: aboutzBNYEt8gKPMeta?.alias || [],
    redirect: aboutzBNYEt8gKPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: indexEiAc7S7AWbMeta?.name ?? "company-careers-benefits___en-US",
    path: indexEiAc7S7AWbMeta?.path ?? "/company/careers/benefits",
    meta: indexEiAc7S7AWbMeta || {},
    alias: indexEiAc7S7AWbMeta?.alias || [],
    redirect: indexEiAc7S7AWbMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexEiAc7S7AWbMeta?.name ?? "company-careers-benefits___ca",
    path: indexEiAc7S7AWbMeta?.path ?? "/ca/company/careers/benefits",
    meta: indexEiAc7S7AWbMeta || {},
    alias: indexEiAc7S7AWbMeta?.alias || [],
    redirect: indexEiAc7S7AWbMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexEiAc7S7AWbMeta?.name ?? "company-careers-benefits___nl",
    path: indexEiAc7S7AWbMeta?.path ?? "/nl/company/careers/benefits",
    meta: indexEiAc7S7AWbMeta || {},
    alias: indexEiAc7S7AWbMeta?.alias || [],
    redirect: indexEiAc7S7AWbMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexEiAc7S7AWbMeta?.name ?? "company-careers-benefits___uk",
    path: indexEiAc7S7AWbMeta?.path ?? "/uk/company/careers/benefits",
    meta: indexEiAc7S7AWbMeta || {},
    alias: indexEiAc7S7AWbMeta?.alias || [],
    redirect: indexEiAc7S7AWbMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexEiAc7S7AWbMeta?.name ?? "company-careers-benefits___de",
    path: indexEiAc7S7AWbMeta?.path ?? "/de/company/careers/benefits",
    meta: indexEiAc7S7AWbMeta || {},
    alias: indexEiAc7S7AWbMeta?.alias || [],
    redirect: indexEiAc7S7AWbMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexEiAc7S7AWbMeta?.name ?? "company-careers-benefits___mx",
    path: indexEiAc7S7AWbMeta?.path ?? "/mx/company/careers/benefits",
    meta: indexEiAc7S7AWbMeta || {},
    alias: indexEiAc7S7AWbMeta?.alias || [],
    redirect: indexEiAc7S7AWbMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexEiAc7S7AWbMeta?.name ?? "company-careers-benefits___fr",
    path: indexEiAc7S7AWbMeta?.path ?? "/fr/company/careers/benefits",
    meta: indexEiAc7S7AWbMeta || {},
    alias: indexEiAc7S7AWbMeta?.alias || [],
    redirect: indexEiAc7S7AWbMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexEiAc7S7AWbMeta?.name ?? "company-careers-benefits___fr-ca",
    path: indexEiAc7S7AWbMeta?.path ?? "/fr-ca/company/careers/benefits",
    meta: indexEiAc7S7AWbMeta || {},
    alias: indexEiAc7S7AWbMeta?.alias || [],
    redirect: indexEiAc7S7AWbMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productUJFo1YbAzOMeta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45productUJFo1YbAzOMeta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productUJFo1YbAzOMeta || {},
    alias: engineering_45and_45productUJFo1YbAzOMeta?.alias || [],
    redirect: engineering_45and_45productUJFo1YbAzOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productUJFo1YbAzOMeta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45productUJFo1YbAzOMeta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productUJFo1YbAzOMeta || {},
    alias: engineering_45and_45productUJFo1YbAzOMeta?.alias || [],
    redirect: engineering_45and_45productUJFo1YbAzOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productUJFo1YbAzOMeta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45productUJFo1YbAzOMeta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productUJFo1YbAzOMeta || {},
    alias: engineering_45and_45productUJFo1YbAzOMeta?.alias || [],
    redirect: engineering_45and_45productUJFo1YbAzOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productUJFo1YbAzOMeta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45productUJFo1YbAzOMeta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productUJFo1YbAzOMeta || {},
    alias: engineering_45and_45productUJFo1YbAzOMeta?.alias || [],
    redirect: engineering_45and_45productUJFo1YbAzOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productUJFo1YbAzOMeta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45productUJFo1YbAzOMeta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productUJFo1YbAzOMeta || {},
    alias: engineering_45and_45productUJFo1YbAzOMeta?.alias || [],
    redirect: engineering_45and_45productUJFo1YbAzOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productUJFo1YbAzOMeta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45productUJFo1YbAzOMeta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productUJFo1YbAzOMeta || {},
    alias: engineering_45and_45productUJFo1YbAzOMeta?.alias || [],
    redirect: engineering_45and_45productUJFo1YbAzOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productUJFo1YbAzOMeta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45productUJFo1YbAzOMeta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productUJFo1YbAzOMeta || {},
    alias: engineering_45and_45productUJFo1YbAzOMeta?.alias || [],
    redirect: engineering_45and_45productUJFo1YbAzOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productUJFo1YbAzOMeta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45productUJFo1YbAzOMeta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productUJFo1YbAzOMeta || {},
    alias: engineering_45and_45productUJFo1YbAzOMeta?.alias || [],
    redirect: engineering_45and_45productUJFo1YbAzOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: indexJWtrwlD7yOMeta?.name ?? "company-careers-departments___en-US",
    path: indexJWtrwlD7yOMeta?.path ?? "/company/careers/departments",
    meta: indexJWtrwlD7yOMeta || {},
    alias: indexJWtrwlD7yOMeta?.alias || [],
    redirect: indexJWtrwlD7yOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexJWtrwlD7yOMeta?.name ?? "company-careers-departments___ca",
    path: indexJWtrwlD7yOMeta?.path ?? "/ca/company/careers/departments",
    meta: indexJWtrwlD7yOMeta || {},
    alias: indexJWtrwlD7yOMeta?.alias || [],
    redirect: indexJWtrwlD7yOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexJWtrwlD7yOMeta?.name ?? "company-careers-departments___nl",
    path: indexJWtrwlD7yOMeta?.path ?? "/nl/company/careers/departments",
    meta: indexJWtrwlD7yOMeta || {},
    alias: indexJWtrwlD7yOMeta?.alias || [],
    redirect: indexJWtrwlD7yOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexJWtrwlD7yOMeta?.name ?? "company-careers-departments___uk",
    path: indexJWtrwlD7yOMeta?.path ?? "/uk/company/careers/departments",
    meta: indexJWtrwlD7yOMeta || {},
    alias: indexJWtrwlD7yOMeta?.alias || [],
    redirect: indexJWtrwlD7yOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexJWtrwlD7yOMeta?.name ?? "company-careers-departments___de",
    path: indexJWtrwlD7yOMeta?.path ?? "/de/company/careers/departments",
    meta: indexJWtrwlD7yOMeta || {},
    alias: indexJWtrwlD7yOMeta?.alias || [],
    redirect: indexJWtrwlD7yOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexJWtrwlD7yOMeta?.name ?? "company-careers-departments___mx",
    path: indexJWtrwlD7yOMeta?.path ?? "/mx/company/careers/departments",
    meta: indexJWtrwlD7yOMeta || {},
    alias: indexJWtrwlD7yOMeta?.alias || [],
    redirect: indexJWtrwlD7yOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexJWtrwlD7yOMeta?.name ?? "company-careers-departments___fr",
    path: indexJWtrwlD7yOMeta?.path ?? "/fr/company/careers/departments",
    meta: indexJWtrwlD7yOMeta || {},
    alias: indexJWtrwlD7yOMeta?.alias || [],
    redirect: indexJWtrwlD7yOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexJWtrwlD7yOMeta?.name ?? "company-careers-departments___fr-ca",
    path: indexJWtrwlD7yOMeta?.path ?? "/fr-ca/company/careers/departments",
    meta: indexJWtrwlD7yOMeta || {},
    alias: indexJWtrwlD7yOMeta?.alias || [],
    redirect: indexJWtrwlD7yOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: sales4rFn91HzuGMeta?.name ?? "company-careers-departments-sales___en-US",
    path: sales4rFn91HzuGMeta?.path ?? "/company/careers/departments/sales",
    meta: sales4rFn91HzuGMeta || {},
    alias: sales4rFn91HzuGMeta?.alias || [],
    redirect: sales4rFn91HzuGMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales4rFn91HzuGMeta?.name ?? "company-careers-departments-sales___ca",
    path: sales4rFn91HzuGMeta?.path ?? "/ca/company/careers/departments/sales",
    meta: sales4rFn91HzuGMeta || {},
    alias: sales4rFn91HzuGMeta?.alias || [],
    redirect: sales4rFn91HzuGMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales4rFn91HzuGMeta?.name ?? "company-careers-departments-sales___nl",
    path: sales4rFn91HzuGMeta?.path ?? "/nl/company/careers/departments/sales",
    meta: sales4rFn91HzuGMeta || {},
    alias: sales4rFn91HzuGMeta?.alias || [],
    redirect: sales4rFn91HzuGMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales4rFn91HzuGMeta?.name ?? "company-careers-departments-sales___uk",
    path: sales4rFn91HzuGMeta?.path ?? "/uk/company/careers/departments/sales",
    meta: sales4rFn91HzuGMeta || {},
    alias: sales4rFn91HzuGMeta?.alias || [],
    redirect: sales4rFn91HzuGMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales4rFn91HzuGMeta?.name ?? "company-careers-departments-sales___de",
    path: sales4rFn91HzuGMeta?.path ?? "/de/company/careers/departments/sales",
    meta: sales4rFn91HzuGMeta || {},
    alias: sales4rFn91HzuGMeta?.alias || [],
    redirect: sales4rFn91HzuGMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales4rFn91HzuGMeta?.name ?? "company-careers-departments-sales___mx",
    path: sales4rFn91HzuGMeta?.path ?? "/mx/company/careers/departments/sales",
    meta: sales4rFn91HzuGMeta || {},
    alias: sales4rFn91HzuGMeta?.alias || [],
    redirect: sales4rFn91HzuGMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales4rFn91HzuGMeta?.name ?? "company-careers-departments-sales___fr",
    path: sales4rFn91HzuGMeta?.path ?? "/fr/company/careers/departments/sales",
    meta: sales4rFn91HzuGMeta || {},
    alias: sales4rFn91HzuGMeta?.alias || [],
    redirect: sales4rFn91HzuGMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: sales4rFn91HzuGMeta?.name ?? "company-careers-departments-sales___fr-ca",
    path: sales4rFn91HzuGMeta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: sales4rFn91HzuGMeta || {},
    alias: sales4rFn91HzuGMeta?.alias || [],
    redirect: sales4rFn91HzuGMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivegIj5kSOtabMeta?.name ?? "company-careers-emerging-talent-archive___en-US",
    path: emerging_45talent_45archivegIj5kSOtabMeta?.path ?? "/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivegIj5kSOtabMeta || {},
    alias: emerging_45talent_45archivegIj5kSOtabMeta?.alias || [],
    redirect: emerging_45talent_45archivegIj5kSOtabMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivegIj5kSOtabMeta?.name ?? "company-careers-emerging-talent-archive___ca",
    path: emerging_45talent_45archivegIj5kSOtabMeta?.path ?? "/ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivegIj5kSOtabMeta || {},
    alias: emerging_45talent_45archivegIj5kSOtabMeta?.alias || [],
    redirect: emerging_45talent_45archivegIj5kSOtabMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivegIj5kSOtabMeta?.name ?? "company-careers-emerging-talent-archive___nl",
    path: emerging_45talent_45archivegIj5kSOtabMeta?.path ?? "/nl/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivegIj5kSOtabMeta || {},
    alias: emerging_45talent_45archivegIj5kSOtabMeta?.alias || [],
    redirect: emerging_45talent_45archivegIj5kSOtabMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivegIj5kSOtabMeta?.name ?? "company-careers-emerging-talent-archive___uk",
    path: emerging_45talent_45archivegIj5kSOtabMeta?.path ?? "/uk/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivegIj5kSOtabMeta || {},
    alias: emerging_45talent_45archivegIj5kSOtabMeta?.alias || [],
    redirect: emerging_45talent_45archivegIj5kSOtabMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivegIj5kSOtabMeta?.name ?? "company-careers-emerging-talent-archive___de",
    path: emerging_45talent_45archivegIj5kSOtabMeta?.path ?? "/de/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivegIj5kSOtabMeta || {},
    alias: emerging_45talent_45archivegIj5kSOtabMeta?.alias || [],
    redirect: emerging_45talent_45archivegIj5kSOtabMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivegIj5kSOtabMeta?.name ?? "company-careers-emerging-talent-archive___mx",
    path: emerging_45talent_45archivegIj5kSOtabMeta?.path ?? "/mx/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivegIj5kSOtabMeta || {},
    alias: emerging_45talent_45archivegIj5kSOtabMeta?.alias || [],
    redirect: emerging_45talent_45archivegIj5kSOtabMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivegIj5kSOtabMeta?.name ?? "company-careers-emerging-talent-archive___fr",
    path: emerging_45talent_45archivegIj5kSOtabMeta?.path ?? "/fr/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivegIj5kSOtabMeta || {},
    alias: emerging_45talent_45archivegIj5kSOtabMeta?.alias || [],
    redirect: emerging_45talent_45archivegIj5kSOtabMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talent_45archivegIj5kSOtabMeta?.name ?? "company-careers-emerging-talent-archive___fr-ca",
    path: emerging_45talent_45archivegIj5kSOtabMeta?.path ?? "/fr-ca/company/careers/emerging-talent-archive",
    meta: emerging_45talent_45archivegIj5kSOtabMeta || {},
    alias: emerging_45talent_45archivegIj5kSOtabMeta?.alias || [],
    redirect: emerging_45talent_45archivegIj5kSOtabMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/emerging-talent-archive.vue").then(m => m.default || m)
  },
  {
    name: indexYckK0SQAI2Meta?.name ?? "company-careers___en-US",
    path: indexYckK0SQAI2Meta?.path ?? "/company/careers",
    meta: indexYckK0SQAI2Meta || {},
    alias: indexYckK0SQAI2Meta?.alias || [],
    redirect: indexYckK0SQAI2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexYckK0SQAI2Meta?.name ?? "company-careers___ca",
    path: indexYckK0SQAI2Meta?.path ?? "/ca/company/careers",
    meta: indexYckK0SQAI2Meta || {},
    alias: indexYckK0SQAI2Meta?.alias || [],
    redirect: indexYckK0SQAI2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexYckK0SQAI2Meta?.name ?? "company-careers___nl",
    path: indexYckK0SQAI2Meta?.path ?? "/nl/company/careers",
    meta: indexYckK0SQAI2Meta || {},
    alias: indexYckK0SQAI2Meta?.alias || [],
    redirect: indexYckK0SQAI2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexYckK0SQAI2Meta?.name ?? "company-careers___uk",
    path: indexYckK0SQAI2Meta?.path ?? "/uk/company/careers",
    meta: indexYckK0SQAI2Meta || {},
    alias: indexYckK0SQAI2Meta?.alias || [],
    redirect: indexYckK0SQAI2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexYckK0SQAI2Meta?.name ?? "company-careers___de",
    path: indexYckK0SQAI2Meta?.path ?? "/de/company/careers",
    meta: indexYckK0SQAI2Meta || {},
    alias: indexYckK0SQAI2Meta?.alias || [],
    redirect: indexYckK0SQAI2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexYckK0SQAI2Meta?.name ?? "company-careers___mx",
    path: indexYckK0SQAI2Meta?.path ?? "/mx/company/careers",
    meta: indexYckK0SQAI2Meta || {},
    alias: indexYckK0SQAI2Meta?.alias || [],
    redirect: indexYckK0SQAI2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexYckK0SQAI2Meta?.name ?? "company-careers___fr",
    path: indexYckK0SQAI2Meta?.path ?? "/fr/company/careers",
    meta: indexYckK0SQAI2Meta || {},
    alias: indexYckK0SQAI2Meta?.alias || [],
    redirect: indexYckK0SQAI2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexYckK0SQAI2Meta?.name ?? "company-careers___fr-ca",
    path: indexYckK0SQAI2Meta?.path ?? "/fr-ca/company/careers",
    meta: indexYckK0SQAI2Meta || {},
    alias: indexYckK0SQAI2Meta?.alias || [],
    redirect: indexYckK0SQAI2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexEaDpFbnDunMeta?.name ?? "company-careers-locations___en-US",
    path: indexEaDpFbnDunMeta?.path ?? "/company/careers/locations",
    meta: indexEaDpFbnDunMeta || {},
    alias: indexEaDpFbnDunMeta?.alias || [],
    redirect: indexEaDpFbnDunMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexEaDpFbnDunMeta?.name ?? "company-careers-locations___ca",
    path: indexEaDpFbnDunMeta?.path ?? "/ca/company/careers/locations",
    meta: indexEaDpFbnDunMeta || {},
    alias: indexEaDpFbnDunMeta?.alias || [],
    redirect: indexEaDpFbnDunMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexEaDpFbnDunMeta?.name ?? "company-careers-locations___nl",
    path: indexEaDpFbnDunMeta?.path ?? "/nl/company/careers/locations",
    meta: indexEaDpFbnDunMeta || {},
    alias: indexEaDpFbnDunMeta?.alias || [],
    redirect: indexEaDpFbnDunMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexEaDpFbnDunMeta?.name ?? "company-careers-locations___uk",
    path: indexEaDpFbnDunMeta?.path ?? "/uk/company/careers/locations",
    meta: indexEaDpFbnDunMeta || {},
    alias: indexEaDpFbnDunMeta?.alias || [],
    redirect: indexEaDpFbnDunMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexEaDpFbnDunMeta?.name ?? "company-careers-locations___de",
    path: indexEaDpFbnDunMeta?.path ?? "/de/company/careers/locations",
    meta: indexEaDpFbnDunMeta || {},
    alias: indexEaDpFbnDunMeta?.alias || [],
    redirect: indexEaDpFbnDunMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexEaDpFbnDunMeta?.name ?? "company-careers-locations___mx",
    path: indexEaDpFbnDunMeta?.path ?? "/mx/company/careers/locations",
    meta: indexEaDpFbnDunMeta || {},
    alias: indexEaDpFbnDunMeta?.alias || [],
    redirect: indexEaDpFbnDunMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexEaDpFbnDunMeta?.name ?? "company-careers-locations___fr",
    path: indexEaDpFbnDunMeta?.path ?? "/fr/company/careers/locations",
    meta: indexEaDpFbnDunMeta || {},
    alias: indexEaDpFbnDunMeta?.alias || [],
    redirect: indexEaDpFbnDunMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: indexEaDpFbnDunMeta?.name ?? "company-careers-locations___fr-ca",
    path: indexEaDpFbnDunMeta?.path ?? "/fr-ca/company/careers/locations",
    meta: indexEaDpFbnDunMeta || {},
    alias: indexEaDpFbnDunMeta?.alias || [],
    redirect: indexEaDpFbnDunMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l9qjcyNCK5Meta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_93l9qjcyNCK5Meta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_93l9qjcyNCK5Meta || {},
    alias: _91slug_93l9qjcyNCK5Meta?.alias || [],
    redirect: _91slug_93l9qjcyNCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l9qjcyNCK5Meta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_93l9qjcyNCK5Meta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_93l9qjcyNCK5Meta || {},
    alias: _91slug_93l9qjcyNCK5Meta?.alias || [],
    redirect: _91slug_93l9qjcyNCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l9qjcyNCK5Meta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_93l9qjcyNCK5Meta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_93l9qjcyNCK5Meta || {},
    alias: _91slug_93l9qjcyNCK5Meta?.alias || [],
    redirect: _91slug_93l9qjcyNCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l9qjcyNCK5Meta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_93l9qjcyNCK5Meta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_93l9qjcyNCK5Meta || {},
    alias: _91slug_93l9qjcyNCK5Meta?.alias || [],
    redirect: _91slug_93l9qjcyNCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l9qjcyNCK5Meta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_93l9qjcyNCK5Meta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_93l9qjcyNCK5Meta || {},
    alias: _91slug_93l9qjcyNCK5Meta?.alias || [],
    redirect: _91slug_93l9qjcyNCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l9qjcyNCK5Meta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_93l9qjcyNCK5Meta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_93l9qjcyNCK5Meta || {},
    alias: _91slug_93l9qjcyNCK5Meta?.alias || [],
    redirect: _91slug_93l9qjcyNCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l9qjcyNCK5Meta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_93l9qjcyNCK5Meta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_93l9qjcyNCK5Meta || {},
    alias: _91slug_93l9qjcyNCK5Meta?.alias || [],
    redirect: _91slug_93l9qjcyNCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l9qjcyNCK5Meta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_93l9qjcyNCK5Meta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_93l9qjcyNCK5Meta || {},
    alias: _91slug_93l9qjcyNCK5Meta?.alias || [],
    redirect: _91slug_93l9qjcyNCK5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: index971OraNAVLMeta?.name ?? "company-careers-roles___en-US",
    path: index971OraNAVLMeta?.path ?? "/company/careers/roles",
    meta: index971OraNAVLMeta || {},
    alias: index971OraNAVLMeta?.alias || [],
    redirect: index971OraNAVLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: index971OraNAVLMeta?.name ?? "company-careers-roles___ca",
    path: index971OraNAVLMeta?.path ?? "/ca/company/careers/roles",
    meta: index971OraNAVLMeta || {},
    alias: index971OraNAVLMeta?.alias || [],
    redirect: index971OraNAVLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: index971OraNAVLMeta?.name ?? "company-careers-roles___nl",
    path: index971OraNAVLMeta?.path ?? "/nl/company/careers/roles",
    meta: index971OraNAVLMeta || {},
    alias: index971OraNAVLMeta?.alias || [],
    redirect: index971OraNAVLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: index971OraNAVLMeta?.name ?? "company-careers-roles___uk",
    path: index971OraNAVLMeta?.path ?? "/uk/company/careers/roles",
    meta: index971OraNAVLMeta || {},
    alias: index971OraNAVLMeta?.alias || [],
    redirect: index971OraNAVLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: index971OraNAVLMeta?.name ?? "company-careers-roles___de",
    path: index971OraNAVLMeta?.path ?? "/de/company/careers/roles",
    meta: index971OraNAVLMeta || {},
    alias: index971OraNAVLMeta?.alias || [],
    redirect: index971OraNAVLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: index971OraNAVLMeta?.name ?? "company-careers-roles___mx",
    path: index971OraNAVLMeta?.path ?? "/mx/company/careers/roles",
    meta: index971OraNAVLMeta || {},
    alias: index971OraNAVLMeta?.alias || [],
    redirect: index971OraNAVLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: index971OraNAVLMeta?.name ?? "company-careers-roles___fr",
    path: index971OraNAVLMeta?.path ?? "/fr/company/careers/roles",
    meta: index971OraNAVLMeta || {},
    alias: index971OraNAVLMeta?.alias || [],
    redirect: index971OraNAVLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: index971OraNAVLMeta?.name ?? "company-careers-roles___fr-ca",
    path: index971OraNAVLMeta?.path ?? "/fr-ca/company/careers/roles",
    meta: index971OraNAVLMeta || {},
    alias: index971OraNAVLMeta?.alias || [],
    redirect: index971OraNAVLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: diversityABnjUNpvOgMeta?.name ?? "company-diversity___en-US",
    path: diversityABnjUNpvOgMeta?.path ?? "/company/diversity",
    meta: diversityABnjUNpvOgMeta || {},
    alias: diversityABnjUNpvOgMeta?.alias || [],
    redirect: diversityABnjUNpvOgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityABnjUNpvOgMeta?.name ?? "company-diversity___ca",
    path: diversityABnjUNpvOgMeta?.path ?? "/ca/company/diversity",
    meta: diversityABnjUNpvOgMeta || {},
    alias: diversityABnjUNpvOgMeta?.alias || [],
    redirect: diversityABnjUNpvOgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityABnjUNpvOgMeta?.name ?? "company-diversity___nl",
    path: diversityABnjUNpvOgMeta?.path ?? "/nl/company/diversity",
    meta: diversityABnjUNpvOgMeta || {},
    alias: diversityABnjUNpvOgMeta?.alias || [],
    redirect: diversityABnjUNpvOgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityABnjUNpvOgMeta?.name ?? "company-diversity___uk",
    path: diversityABnjUNpvOgMeta?.path ?? "/uk/company/diversity",
    meta: diversityABnjUNpvOgMeta || {},
    alias: diversityABnjUNpvOgMeta?.alias || [],
    redirect: diversityABnjUNpvOgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityABnjUNpvOgMeta?.name ?? "company-diversity___de",
    path: diversityABnjUNpvOgMeta?.path ?? "/de/company/diversity",
    meta: diversityABnjUNpvOgMeta || {},
    alias: diversityABnjUNpvOgMeta?.alias || [],
    redirect: diversityABnjUNpvOgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityABnjUNpvOgMeta?.name ?? "company-diversity___mx",
    path: diversityABnjUNpvOgMeta?.path ?? "/mx/company/diversity",
    meta: diversityABnjUNpvOgMeta || {},
    alias: diversityABnjUNpvOgMeta?.alias || [],
    redirect: diversityABnjUNpvOgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityABnjUNpvOgMeta?.name ?? "company-diversity___fr",
    path: diversityABnjUNpvOgMeta?.path ?? "/fr/company/diversity",
    meta: diversityABnjUNpvOgMeta || {},
    alias: diversityABnjUNpvOgMeta?.alias || [],
    redirect: diversityABnjUNpvOgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversityABnjUNpvOgMeta?.name ?? "company-diversity___fr-ca",
    path: diversityABnjUNpvOgMeta?.path ?? "/fr-ca/company/diversity",
    meta: diversityABnjUNpvOgMeta || {},
    alias: diversityABnjUNpvOgMeta?.alias || [],
    redirect: diversityABnjUNpvOgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: indexY3N36IiCGsMeta?.name ?? "company___en-US",
    path: indexY3N36IiCGsMeta?.path ?? "/company",
    meta: indexY3N36IiCGsMeta || {},
    alias: indexY3N36IiCGsMeta?.alias || [],
    redirect: indexY3N36IiCGsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexY3N36IiCGsMeta?.name ?? "company___ca",
    path: indexY3N36IiCGsMeta?.path ?? "/ca/company",
    meta: indexY3N36IiCGsMeta || {},
    alias: indexY3N36IiCGsMeta?.alias || [],
    redirect: indexY3N36IiCGsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexY3N36IiCGsMeta?.name ?? "company___nl",
    path: indexY3N36IiCGsMeta?.path ?? "/nl/company",
    meta: indexY3N36IiCGsMeta || {},
    alias: indexY3N36IiCGsMeta?.alias || [],
    redirect: indexY3N36IiCGsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexY3N36IiCGsMeta?.name ?? "company___uk",
    path: indexY3N36IiCGsMeta?.path ?? "/uk/company",
    meta: indexY3N36IiCGsMeta || {},
    alias: indexY3N36IiCGsMeta?.alias || [],
    redirect: indexY3N36IiCGsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexY3N36IiCGsMeta?.name ?? "company___de",
    path: indexY3N36IiCGsMeta?.path ?? "/de/company",
    meta: indexY3N36IiCGsMeta || {},
    alias: indexY3N36IiCGsMeta?.alias || [],
    redirect: indexY3N36IiCGsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexY3N36IiCGsMeta?.name ?? "company___mx",
    path: indexY3N36IiCGsMeta?.path ?? "/mx/company",
    meta: indexY3N36IiCGsMeta || {},
    alias: indexY3N36IiCGsMeta?.alias || [],
    redirect: indexY3N36IiCGsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexY3N36IiCGsMeta?.name ?? "company___fr",
    path: indexY3N36IiCGsMeta?.path ?? "/fr/company",
    meta: indexY3N36IiCGsMeta || {},
    alias: indexY3N36IiCGsMeta?.alias || [],
    redirect: indexY3N36IiCGsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexY3N36IiCGsMeta?.name ?? "company___fr-ca",
    path: indexY3N36IiCGsMeta?.path ?? "/fr-ca/company",
    meta: indexY3N36IiCGsMeta || {},
    alias: indexY3N36IiCGsMeta?.alias || [],
    redirect: indexY3N36IiCGsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiencyJKYRwhSbEDMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiencyJKYRwhSbEDMeta?.path ?? "/company/mission/efficiency",
    meta: efficiencyJKYRwhSbEDMeta || {},
    alias: efficiencyJKYRwhSbEDMeta?.alias || [],
    redirect: efficiencyJKYRwhSbEDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyJKYRwhSbEDMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiencyJKYRwhSbEDMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiencyJKYRwhSbEDMeta || {},
    alias: efficiencyJKYRwhSbEDMeta?.alias || [],
    redirect: efficiencyJKYRwhSbEDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyJKYRwhSbEDMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiencyJKYRwhSbEDMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiencyJKYRwhSbEDMeta || {},
    alias: efficiencyJKYRwhSbEDMeta?.alias || [],
    redirect: efficiencyJKYRwhSbEDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyJKYRwhSbEDMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiencyJKYRwhSbEDMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiencyJKYRwhSbEDMeta || {},
    alias: efficiencyJKYRwhSbEDMeta?.alias || [],
    redirect: efficiencyJKYRwhSbEDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyJKYRwhSbEDMeta?.name ?? "company-mission-efficiency___de",
    path: efficiencyJKYRwhSbEDMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiencyJKYRwhSbEDMeta || {},
    alias: efficiencyJKYRwhSbEDMeta?.alias || [],
    redirect: efficiencyJKYRwhSbEDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyJKYRwhSbEDMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiencyJKYRwhSbEDMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiencyJKYRwhSbEDMeta || {},
    alias: efficiencyJKYRwhSbEDMeta?.alias || [],
    redirect: efficiencyJKYRwhSbEDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyJKYRwhSbEDMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiencyJKYRwhSbEDMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiencyJKYRwhSbEDMeta || {},
    alias: efficiencyJKYRwhSbEDMeta?.alias || [],
    redirect: efficiencyJKYRwhSbEDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyJKYRwhSbEDMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiencyJKYRwhSbEDMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiencyJKYRwhSbEDMeta || {},
    alias: efficiencyJKYRwhSbEDMeta?.alias || [],
    redirect: efficiencyJKYRwhSbEDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safety2kTrxxQKwfMeta?.name ?? "company-mission-safety___en-US",
    path: safety2kTrxxQKwfMeta?.path ?? "/company/mission/safety",
    meta: safety2kTrxxQKwfMeta || {},
    alias: safety2kTrxxQKwfMeta?.alias || [],
    redirect: safety2kTrxxQKwfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2kTrxxQKwfMeta?.name ?? "company-mission-safety___ca",
    path: safety2kTrxxQKwfMeta?.path ?? "/ca/company/mission/safety",
    meta: safety2kTrxxQKwfMeta || {},
    alias: safety2kTrxxQKwfMeta?.alias || [],
    redirect: safety2kTrxxQKwfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2kTrxxQKwfMeta?.name ?? "company-mission-safety___nl",
    path: safety2kTrxxQKwfMeta?.path ?? "/nl/company/mission/safety",
    meta: safety2kTrxxQKwfMeta || {},
    alias: safety2kTrxxQKwfMeta?.alias || [],
    redirect: safety2kTrxxQKwfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2kTrxxQKwfMeta?.name ?? "company-mission-safety___uk",
    path: safety2kTrxxQKwfMeta?.path ?? "/uk/company/mission/safety",
    meta: safety2kTrxxQKwfMeta || {},
    alias: safety2kTrxxQKwfMeta?.alias || [],
    redirect: safety2kTrxxQKwfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2kTrxxQKwfMeta?.name ?? "company-mission-safety___de",
    path: safety2kTrxxQKwfMeta?.path ?? "/de/company/mission/safety",
    meta: safety2kTrxxQKwfMeta || {},
    alias: safety2kTrxxQKwfMeta?.alias || [],
    redirect: safety2kTrxxQKwfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2kTrxxQKwfMeta?.name ?? "company-mission-safety___mx",
    path: safety2kTrxxQKwfMeta?.path ?? "/mx/company/mission/safety",
    meta: safety2kTrxxQKwfMeta || {},
    alias: safety2kTrxxQKwfMeta?.alias || [],
    redirect: safety2kTrxxQKwfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2kTrxxQKwfMeta?.name ?? "company-mission-safety___fr",
    path: safety2kTrxxQKwfMeta?.path ?? "/fr/company/mission/safety",
    meta: safety2kTrxxQKwfMeta || {},
    alias: safety2kTrxxQKwfMeta?.alias || [],
    redirect: safety2kTrxxQKwfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safety2kTrxxQKwfMeta?.name ?? "company-mission-safety___fr-ca",
    path: safety2kTrxxQKwfMeta?.path ?? "/fr-ca/company/mission/safety",
    meta: safety2kTrxxQKwfMeta || {},
    alias: safety2kTrxxQKwfMeta?.alias || [],
    redirect: safety2kTrxxQKwfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitywcg3L9Dk51Meta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilitywcg3L9Dk51Meta?.path ?? "/company/mission/sustainability",
    meta: sustainabilitywcg3L9Dk51Meta || {},
    alias: sustainabilitywcg3L9Dk51Meta?.alias || [],
    redirect: sustainabilitywcg3L9Dk51Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitywcg3L9Dk51Meta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilitywcg3L9Dk51Meta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilitywcg3L9Dk51Meta || {},
    alias: sustainabilitywcg3L9Dk51Meta?.alias || [],
    redirect: sustainabilitywcg3L9Dk51Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitywcg3L9Dk51Meta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilitywcg3L9Dk51Meta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilitywcg3L9Dk51Meta || {},
    alias: sustainabilitywcg3L9Dk51Meta?.alias || [],
    redirect: sustainabilitywcg3L9Dk51Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitywcg3L9Dk51Meta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilitywcg3L9Dk51Meta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilitywcg3L9Dk51Meta || {},
    alias: sustainabilitywcg3L9Dk51Meta?.alias || [],
    redirect: sustainabilitywcg3L9Dk51Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitywcg3L9Dk51Meta?.name ?? "company-mission-sustainability___de",
    path: sustainabilitywcg3L9Dk51Meta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilitywcg3L9Dk51Meta || {},
    alias: sustainabilitywcg3L9Dk51Meta?.alias || [],
    redirect: sustainabilitywcg3L9Dk51Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitywcg3L9Dk51Meta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilitywcg3L9Dk51Meta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilitywcg3L9Dk51Meta || {},
    alias: sustainabilitywcg3L9Dk51Meta?.alias || [],
    redirect: sustainabilitywcg3L9Dk51Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitywcg3L9Dk51Meta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilitywcg3L9Dk51Meta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilitywcg3L9Dk51Meta || {},
    alias: sustainabilitywcg3L9Dk51Meta?.alias || [],
    redirect: sustainabilitywcg3L9Dk51Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitywcg3L9Dk51Meta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilitywcg3L9Dk51Meta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilitywcg3L9Dk51Meta || {},
    alias: sustainabilitywcg3L9Dk51Meta?.alias || [],
    redirect: sustainabilitywcg3L9Dk51Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newssqkP5NwBiBMeta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45newssqkP5NwBiBMeta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45newssqkP5NwBiBMeta || {},
    alias: in_45the_45newssqkP5NwBiBMeta?.alias || [],
    redirect: in_45the_45newssqkP5NwBiBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newssqkP5NwBiBMeta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45newssqkP5NwBiBMeta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45newssqkP5NwBiBMeta || {},
    alias: in_45the_45newssqkP5NwBiBMeta?.alias || [],
    redirect: in_45the_45newssqkP5NwBiBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newssqkP5NwBiBMeta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45newssqkP5NwBiBMeta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45newssqkP5NwBiBMeta || {},
    alias: in_45the_45newssqkP5NwBiBMeta?.alias || [],
    redirect: in_45the_45newssqkP5NwBiBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newssqkP5NwBiBMeta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45newssqkP5NwBiBMeta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45newssqkP5NwBiBMeta || {},
    alias: in_45the_45newssqkP5NwBiBMeta?.alias || [],
    redirect: in_45the_45newssqkP5NwBiBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newssqkP5NwBiBMeta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45newssqkP5NwBiBMeta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45newssqkP5NwBiBMeta || {},
    alias: in_45the_45newssqkP5NwBiBMeta?.alias || [],
    redirect: in_45the_45newssqkP5NwBiBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newssqkP5NwBiBMeta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45newssqkP5NwBiBMeta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45newssqkP5NwBiBMeta || {},
    alias: in_45the_45newssqkP5NwBiBMeta?.alias || [],
    redirect: in_45the_45newssqkP5NwBiBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newssqkP5NwBiBMeta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45newssqkP5NwBiBMeta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45newssqkP5NwBiBMeta || {},
    alias: in_45the_45newssqkP5NwBiBMeta?.alias || [],
    redirect: in_45the_45newssqkP5NwBiBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newssqkP5NwBiBMeta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45newssqkP5NwBiBMeta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45newssqkP5NwBiBMeta || {},
    alias: in_45the_45newssqkP5NwBiBMeta?.alias || [],
    redirect: in_45the_45newssqkP5NwBiBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: indexqe2xINEGkWMeta?.name ?? "company-news___en-US",
    path: indexqe2xINEGkWMeta?.path ?? "/company/news",
    meta: indexqe2xINEGkWMeta || {},
    alias: indexqe2xINEGkWMeta?.alias || [],
    redirect: indexqe2xINEGkWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexqe2xINEGkWMeta?.name ?? "company-news___ca",
    path: indexqe2xINEGkWMeta?.path ?? "/ca/company/news",
    meta: indexqe2xINEGkWMeta || {},
    alias: indexqe2xINEGkWMeta?.alias || [],
    redirect: indexqe2xINEGkWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexqe2xINEGkWMeta?.name ?? "company-news___mx",
    path: indexqe2xINEGkWMeta?.path ?? "/mx/company/news",
    meta: indexqe2xINEGkWMeta || {},
    alias: indexqe2xINEGkWMeta?.alias || [],
    redirect: indexqe2xINEGkWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexqe2xINEGkWMeta?.name ?? "company-news___uk",
    path: indexqe2xINEGkWMeta?.path ?? "/uk/company/news",
    meta: indexqe2xINEGkWMeta || {},
    alias: indexqe2xINEGkWMeta?.alias || [],
    redirect: indexqe2xINEGkWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesD7kIOpp8KlMeta?.name ?? "company-news-press-releases___en-US",
    path: press_45releasesD7kIOpp8KlMeta?.path ?? "/company/news/press-releases",
    meta: press_45releasesD7kIOpp8KlMeta || {},
    alias: press_45releasesD7kIOpp8KlMeta?.alias || [],
    redirect: press_45releasesD7kIOpp8KlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesD7kIOpp8KlMeta?.name ?? "company-news-press-releases___ca",
    path: press_45releasesD7kIOpp8KlMeta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releasesD7kIOpp8KlMeta || {},
    alias: press_45releasesD7kIOpp8KlMeta?.alias || [],
    redirect: press_45releasesD7kIOpp8KlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesD7kIOpp8KlMeta?.name ?? "company-news-press-releases___nl",
    path: press_45releasesD7kIOpp8KlMeta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releasesD7kIOpp8KlMeta || {},
    alias: press_45releasesD7kIOpp8KlMeta?.alias || [],
    redirect: press_45releasesD7kIOpp8KlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesD7kIOpp8KlMeta?.name ?? "company-news-press-releases___uk",
    path: press_45releasesD7kIOpp8KlMeta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releasesD7kIOpp8KlMeta || {},
    alias: press_45releasesD7kIOpp8KlMeta?.alias || [],
    redirect: press_45releasesD7kIOpp8KlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesD7kIOpp8KlMeta?.name ?? "company-news-press-releases___de",
    path: press_45releasesD7kIOpp8KlMeta?.path ?? "/de/company/news/press-releases",
    meta: press_45releasesD7kIOpp8KlMeta || {},
    alias: press_45releasesD7kIOpp8KlMeta?.alias || [],
    redirect: press_45releasesD7kIOpp8KlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesD7kIOpp8KlMeta?.name ?? "company-news-press-releases___mx",
    path: press_45releasesD7kIOpp8KlMeta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releasesD7kIOpp8KlMeta || {},
    alias: press_45releasesD7kIOpp8KlMeta?.alias || [],
    redirect: press_45releasesD7kIOpp8KlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesD7kIOpp8KlMeta?.name ?? "company-news-press-releases___fr",
    path: press_45releasesD7kIOpp8KlMeta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releasesD7kIOpp8KlMeta || {},
    alias: press_45releasesD7kIOpp8KlMeta?.alias || [],
    redirect: press_45releasesD7kIOpp8KlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesD7kIOpp8KlMeta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releasesD7kIOpp8KlMeta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releasesD7kIOpp8KlMeta || {},
    alias: press_45releasesD7kIOpp8KlMeta?.alias || [],
    redirect: press_45releasesD7kIOpp8KlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacy1p952uwlTHMeta?.name ?? "company-privacy___en-US",
    path: privacy1p952uwlTHMeta?.path ?? "/company/privacy",
    meta: privacy1p952uwlTHMeta || {},
    alias: privacy1p952uwlTHMeta?.alias || [],
    redirect: privacy1p952uwlTHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy1p952uwlTHMeta?.name ?? "company-privacy___ca",
    path: privacy1p952uwlTHMeta?.path ?? "/ca/company/privacy",
    meta: privacy1p952uwlTHMeta || {},
    alias: privacy1p952uwlTHMeta?.alias || [],
    redirect: privacy1p952uwlTHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy1p952uwlTHMeta?.name ?? "company-privacy___nl",
    path: privacy1p952uwlTHMeta?.path ?? "/nl/company/privacy",
    meta: privacy1p952uwlTHMeta || {},
    alias: privacy1p952uwlTHMeta?.alias || [],
    redirect: privacy1p952uwlTHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy1p952uwlTHMeta?.name ?? "company-privacy___uk",
    path: privacy1p952uwlTHMeta?.path ?? "/uk/company/privacy",
    meta: privacy1p952uwlTHMeta || {},
    alias: privacy1p952uwlTHMeta?.alias || [],
    redirect: privacy1p952uwlTHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy1p952uwlTHMeta?.name ?? "company-privacy___de",
    path: privacy1p952uwlTHMeta?.path ?? "/de/company/privacy",
    meta: privacy1p952uwlTHMeta || {},
    alias: privacy1p952uwlTHMeta?.alias || [],
    redirect: privacy1p952uwlTHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy1p952uwlTHMeta?.name ?? "company-privacy___mx",
    path: privacy1p952uwlTHMeta?.path ?? "/mx/company/privacy",
    meta: privacy1p952uwlTHMeta || {},
    alias: privacy1p952uwlTHMeta?.alias || [],
    redirect: privacy1p952uwlTHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy1p952uwlTHMeta?.name ?? "company-privacy___fr",
    path: privacy1p952uwlTHMeta?.path ?? "/fr/company/privacy",
    meta: privacy1p952uwlTHMeta || {},
    alias: privacy1p952uwlTHMeta?.alias || [],
    redirect: privacy1p952uwlTHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacy1p952uwlTHMeta?.name ?? "company-privacy___fr-ca",
    path: privacy1p952uwlTHMeta?.path ?? "/fr-ca/company/privacy",
    meta: privacy1p952uwlTHMeta || {},
    alias: privacy1p952uwlTHMeta?.alias || [],
    redirect: privacy1p952uwlTHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolkRQMSBIm8nMeta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45toolkRQMSBIm8nMeta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45toolkRQMSBIm8nMeta || {},
    alias: cost_45assessment_45toolkRQMSBIm8nMeta?.alias || [],
    redirect: cost_45assessment_45toolkRQMSBIm8nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolkRQMSBIm8nMeta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45toolkRQMSBIm8nMeta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45toolkRQMSBIm8nMeta || {},
    alias: cost_45assessment_45toolkRQMSBIm8nMeta?.alias || [],
    redirect: cost_45assessment_45toolkRQMSBIm8nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolkRQMSBIm8nMeta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45toolkRQMSBIm8nMeta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45toolkRQMSBIm8nMeta || {},
    alias: cost_45assessment_45toolkRQMSBIm8nMeta?.alias || [],
    redirect: cost_45assessment_45toolkRQMSBIm8nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolkRQMSBIm8nMeta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45toolkRQMSBIm8nMeta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45toolkRQMSBIm8nMeta || {},
    alias: cost_45assessment_45toolkRQMSBIm8nMeta?.alias || [],
    redirect: cost_45assessment_45toolkRQMSBIm8nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolkRQMSBIm8nMeta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45toolkRQMSBIm8nMeta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45toolkRQMSBIm8nMeta || {},
    alias: cost_45assessment_45toolkRQMSBIm8nMeta?.alias || [],
    redirect: cost_45assessment_45toolkRQMSBIm8nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolkRQMSBIm8nMeta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45toolkRQMSBIm8nMeta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45toolkRQMSBIm8nMeta || {},
    alias: cost_45assessment_45toolkRQMSBIm8nMeta?.alias || [],
    redirect: cost_45assessment_45toolkRQMSBIm8nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolkRQMSBIm8nMeta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45toolkRQMSBIm8nMeta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45toolkRQMSBIm8nMeta || {},
    alias: cost_45assessment_45toolkRQMSBIm8nMeta?.alias || [],
    redirect: cost_45assessment_45toolkRQMSBIm8nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45toolkRQMSBIm8nMeta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45toolkRQMSBIm8nMeta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45toolkRQMSBIm8nMeta || {},
    alias: cost_45assessment_45toolkRQMSBIm8nMeta?.alias || [],
    redirect: cost_45assessment_45toolkRQMSBIm8nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPbNKRc5BlMeta?.name ?? "customers-slug___en-US",
    path: _91slug_93kPbNKRc5BlMeta?.path ?? "/customers/:slug()",
    meta: _91slug_93kPbNKRc5BlMeta || {},
    alias: _91slug_93kPbNKRc5BlMeta?.alias || [],
    redirect: _91slug_93kPbNKRc5BlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPbNKRc5BlMeta?.name ?? "customers-slug___ca",
    path: _91slug_93kPbNKRc5BlMeta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93kPbNKRc5BlMeta || {},
    alias: _91slug_93kPbNKRc5BlMeta?.alias || [],
    redirect: _91slug_93kPbNKRc5BlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPbNKRc5BlMeta?.name ?? "customers-slug___nl",
    path: _91slug_93kPbNKRc5BlMeta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93kPbNKRc5BlMeta || {},
    alias: _91slug_93kPbNKRc5BlMeta?.alias || [],
    redirect: _91slug_93kPbNKRc5BlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPbNKRc5BlMeta?.name ?? "customers-slug___uk",
    path: _91slug_93kPbNKRc5BlMeta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93kPbNKRc5BlMeta || {},
    alias: _91slug_93kPbNKRc5BlMeta?.alias || [],
    redirect: _91slug_93kPbNKRc5BlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPbNKRc5BlMeta?.name ?? "customers-slug___de",
    path: _91slug_93kPbNKRc5BlMeta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93kPbNKRc5BlMeta || {},
    alias: _91slug_93kPbNKRc5BlMeta?.alias || [],
    redirect: _91slug_93kPbNKRc5BlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPbNKRc5BlMeta?.name ?? "customers-slug___mx",
    path: _91slug_93kPbNKRc5BlMeta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93kPbNKRc5BlMeta || {},
    alias: _91slug_93kPbNKRc5BlMeta?.alias || [],
    redirect: _91slug_93kPbNKRc5BlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPbNKRc5BlMeta?.name ?? "customers-slug___fr",
    path: _91slug_93kPbNKRc5BlMeta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93kPbNKRc5BlMeta || {},
    alias: _91slug_93kPbNKRc5BlMeta?.alias || [],
    redirect: _91slug_93kPbNKRc5BlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kPbNKRc5BlMeta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93kPbNKRc5BlMeta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93kPbNKRc5BlMeta || {},
    alias: _91slug_93kPbNKRc5BlMeta?.alias || [],
    redirect: _91slug_93kPbNKRc5BlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938KlIZKJNX8Meta?.name ?? "customers-category-slug___en-US",
    path: _91slug_938KlIZKJNX8Meta?.path ?? "/customers/category/:slug()",
    meta: _91slug_938KlIZKJNX8Meta || {},
    alias: _91slug_938KlIZKJNX8Meta?.alias || [],
    redirect: _91slug_938KlIZKJNX8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938KlIZKJNX8Meta?.name ?? "customers-category-slug___ca",
    path: _91slug_938KlIZKJNX8Meta?.path ?? "/ca/customers/category/:slug()",
    meta: _91slug_938KlIZKJNX8Meta || {},
    alias: _91slug_938KlIZKJNX8Meta?.alias || [],
    redirect: _91slug_938KlIZKJNX8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938KlIZKJNX8Meta?.name ?? "customers-category-slug___nl",
    path: _91slug_938KlIZKJNX8Meta?.path ?? "/nl/customers/category/:slug()",
    meta: _91slug_938KlIZKJNX8Meta || {},
    alias: _91slug_938KlIZKJNX8Meta?.alias || [],
    redirect: _91slug_938KlIZKJNX8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938KlIZKJNX8Meta?.name ?? "customers-category-slug___uk",
    path: _91slug_938KlIZKJNX8Meta?.path ?? "/uk/customers/category/:slug()",
    meta: _91slug_938KlIZKJNX8Meta || {},
    alias: _91slug_938KlIZKJNX8Meta?.alias || [],
    redirect: _91slug_938KlIZKJNX8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938KlIZKJNX8Meta?.name ?? "customers-category-slug___de",
    path: _91slug_938KlIZKJNX8Meta?.path ?? "/de/customers/category/:slug()",
    meta: _91slug_938KlIZKJNX8Meta || {},
    alias: _91slug_938KlIZKJNX8Meta?.alias || [],
    redirect: _91slug_938KlIZKJNX8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938KlIZKJNX8Meta?.name ?? "customers-category-slug___mx",
    path: _91slug_938KlIZKJNX8Meta?.path ?? "/mx/customers/category/:slug()",
    meta: _91slug_938KlIZKJNX8Meta || {},
    alias: _91slug_938KlIZKJNX8Meta?.alias || [],
    redirect: _91slug_938KlIZKJNX8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938KlIZKJNX8Meta?.name ?? "customers-category-slug___fr",
    path: _91slug_938KlIZKJNX8Meta?.path ?? "/fr/customers/category/:slug()",
    meta: _91slug_938KlIZKJNX8Meta || {},
    alias: _91slug_938KlIZKJNX8Meta?.alias || [],
    redirect: _91slug_938KlIZKJNX8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938KlIZKJNX8Meta?.name ?? "customers-category-slug___fr-ca",
    path: _91slug_938KlIZKJNX8Meta?.path ?? "/fr-ca/customers/category/:slug()",
    meta: _91slug_938KlIZKJNX8Meta || {},
    alias: _91slug_938KlIZKJNX8Meta?.alias || [],
    redirect: _91slug_938KlIZKJNX8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index2P43gM3uljMeta?.name ?? "customers___en-US",
    path: index2P43gM3uljMeta?.path ?? "/customers",
    meta: index2P43gM3uljMeta || {},
    alias: index2P43gM3uljMeta?.alias || [],
    redirect: index2P43gM3uljMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index2P43gM3uljMeta?.name ?? "customers___ca",
    path: index2P43gM3uljMeta?.path ?? "/ca/customers",
    meta: index2P43gM3uljMeta || {},
    alias: index2P43gM3uljMeta?.alias || [],
    redirect: index2P43gM3uljMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index2P43gM3uljMeta?.name ?? "customers___nl",
    path: index2P43gM3uljMeta?.path ?? "/nl/customers",
    meta: index2P43gM3uljMeta || {},
    alias: index2P43gM3uljMeta?.alias || [],
    redirect: index2P43gM3uljMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index2P43gM3uljMeta?.name ?? "customers___uk",
    path: index2P43gM3uljMeta?.path ?? "/uk/customers",
    meta: index2P43gM3uljMeta || {},
    alias: index2P43gM3uljMeta?.alias || [],
    redirect: index2P43gM3uljMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index2P43gM3uljMeta?.name ?? "customers___de",
    path: index2P43gM3uljMeta?.path ?? "/de/customers",
    meta: index2P43gM3uljMeta || {},
    alias: index2P43gM3uljMeta?.alias || [],
    redirect: index2P43gM3uljMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index2P43gM3uljMeta?.name ?? "customers___mx",
    path: index2P43gM3uljMeta?.path ?? "/mx/customers",
    meta: index2P43gM3uljMeta || {},
    alias: index2P43gM3uljMeta?.alias || [],
    redirect: index2P43gM3uljMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index2P43gM3uljMeta?.name ?? "customers___fr",
    path: index2P43gM3uljMeta?.path ?? "/fr/customers",
    meta: index2P43gM3uljMeta || {},
    alias: index2P43gM3uljMeta?.alias || [],
    redirect: index2P43gM3uljMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: index2P43gM3uljMeta?.name ?? "customers___fr-ca",
    path: index2P43gM3uljMeta?.path ?? "/fr-ca/customers",
    meta: index2P43gM3uljMeta || {},
    alias: index2P43gM3uljMeta?.alias || [],
    redirect: index2P43gM3uljMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939FhroCuTQAMeta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_939FhroCuTQAMeta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_939FhroCuTQAMeta || {},
    alias: _91slug_939FhroCuTQAMeta?.alias || [],
    redirect: _91slug_939FhroCuTQAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939FhroCuTQAMeta?.name ?? "customers-tips-slug___ca",
    path: _91slug_939FhroCuTQAMeta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_939FhroCuTQAMeta || {},
    alias: _91slug_939FhroCuTQAMeta?.alias || [],
    redirect: _91slug_939FhroCuTQAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939FhroCuTQAMeta?.name ?? "customers-tips-slug___nl",
    path: _91slug_939FhroCuTQAMeta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_939FhroCuTQAMeta || {},
    alias: _91slug_939FhroCuTQAMeta?.alias || [],
    redirect: _91slug_939FhroCuTQAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939FhroCuTQAMeta?.name ?? "customers-tips-slug___uk",
    path: _91slug_939FhroCuTQAMeta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_939FhroCuTQAMeta || {},
    alias: _91slug_939FhroCuTQAMeta?.alias || [],
    redirect: _91slug_939FhroCuTQAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939FhroCuTQAMeta?.name ?? "customers-tips-slug___de",
    path: _91slug_939FhroCuTQAMeta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_939FhroCuTQAMeta || {},
    alias: _91slug_939FhroCuTQAMeta?.alias || [],
    redirect: _91slug_939FhroCuTQAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939FhroCuTQAMeta?.name ?? "customers-tips-slug___mx",
    path: _91slug_939FhroCuTQAMeta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_939FhroCuTQAMeta || {},
    alias: _91slug_939FhroCuTQAMeta?.alias || [],
    redirect: _91slug_939FhroCuTQAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939FhroCuTQAMeta?.name ?? "customers-tips-slug___fr",
    path: _91slug_939FhroCuTQAMeta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_939FhroCuTQAMeta || {},
    alias: _91slug_939FhroCuTQAMeta?.alias || [],
    redirect: _91slug_939FhroCuTQAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939FhroCuTQAMeta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_939FhroCuTQAMeta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_939FhroCuTQAMeta || {},
    alias: _91slug_939FhroCuTQAMeta?.alias || [],
    redirect: _91slug_939FhroCuTQAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MFgIwY3QikMeta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93MFgIwY3QikMeta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93MFgIwY3QikMeta || {},
    alias: _91slug_93MFgIwY3QikMeta?.alias || [],
    redirect: _91slug_93MFgIwY3QikMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MFgIwY3QikMeta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93MFgIwY3QikMeta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93MFgIwY3QikMeta || {},
    alias: _91slug_93MFgIwY3QikMeta?.alias || [],
    redirect: _91slug_93MFgIwY3QikMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MFgIwY3QikMeta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93MFgIwY3QikMeta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93MFgIwY3QikMeta || {},
    alias: _91slug_93MFgIwY3QikMeta?.alias || [],
    redirect: _91slug_93MFgIwY3QikMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MFgIwY3QikMeta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93MFgIwY3QikMeta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93MFgIwY3QikMeta || {},
    alias: _91slug_93MFgIwY3QikMeta?.alias || [],
    redirect: _91slug_93MFgIwY3QikMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MFgIwY3QikMeta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93MFgIwY3QikMeta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93MFgIwY3QikMeta || {},
    alias: _91slug_93MFgIwY3QikMeta?.alias || [],
    redirect: _91slug_93MFgIwY3QikMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MFgIwY3QikMeta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93MFgIwY3QikMeta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93MFgIwY3QikMeta || {},
    alias: _91slug_93MFgIwY3QikMeta?.alias || [],
    redirect: _91slug_93MFgIwY3QikMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MFgIwY3QikMeta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93MFgIwY3QikMeta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93MFgIwY3QikMeta || {},
    alias: _91slug_93MFgIwY3QikMeta?.alias || [],
    redirect: _91slug_93MFgIwY3QikMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MFgIwY3QikMeta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93MFgIwY3QikMeta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93MFgIwY3QikMeta || {},
    alias: _91slug_93MFgIwY3QikMeta?.alias || [],
    redirect: _91slug_93MFgIwY3QikMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4JoKJHp1AsMeta?.name ?? "customers-tips___en-US",
    path: index4JoKJHp1AsMeta?.path ?? "/customers/tips",
    meta: index4JoKJHp1AsMeta || {},
    alias: index4JoKJHp1AsMeta?.alias || [],
    redirect: index4JoKJHp1AsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index4JoKJHp1AsMeta?.name ?? "customers-tips___ca",
    path: index4JoKJHp1AsMeta?.path ?? "/ca/customers/tips",
    meta: index4JoKJHp1AsMeta || {},
    alias: index4JoKJHp1AsMeta?.alias || [],
    redirect: index4JoKJHp1AsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index4JoKJHp1AsMeta?.name ?? "customers-tips___nl",
    path: index4JoKJHp1AsMeta?.path ?? "/nl/customers/tips",
    meta: index4JoKJHp1AsMeta || {},
    alias: index4JoKJHp1AsMeta?.alias || [],
    redirect: index4JoKJHp1AsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index4JoKJHp1AsMeta?.name ?? "customers-tips___uk",
    path: index4JoKJHp1AsMeta?.path ?? "/uk/customers/tips",
    meta: index4JoKJHp1AsMeta || {},
    alias: index4JoKJHp1AsMeta?.alias || [],
    redirect: index4JoKJHp1AsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index4JoKJHp1AsMeta?.name ?? "customers-tips___de",
    path: index4JoKJHp1AsMeta?.path ?? "/de/customers/tips",
    meta: index4JoKJHp1AsMeta || {},
    alias: index4JoKJHp1AsMeta?.alias || [],
    redirect: index4JoKJHp1AsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index4JoKJHp1AsMeta?.name ?? "customers-tips___mx",
    path: index4JoKJHp1AsMeta?.path ?? "/mx/customers/tips",
    meta: index4JoKJHp1AsMeta || {},
    alias: index4JoKJHp1AsMeta?.alias || [],
    redirect: index4JoKJHp1AsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index4JoKJHp1AsMeta?.name ?? "customers-tips___fr",
    path: index4JoKJHp1AsMeta?.path ?? "/fr/customers/tips",
    meta: index4JoKJHp1AsMeta || {},
    alias: index4JoKJHp1AsMeta?.alias || [],
    redirect: index4JoKJHp1AsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: index4JoKJHp1AsMeta?.name ?? "customers-tips___fr-ca",
    path: index4JoKJHp1AsMeta?.path ?? "/fr-ca/customers/tips",
    meta: index4JoKJHp1AsMeta || {},
    alias: index4JoKJHp1AsMeta?.alias || [],
    redirect: index4JoKJHp1AsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93YpiSfEWgP7Meta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_93YpiSfEWgP7Meta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93YpiSfEWgP7Meta || {},
    alias: _91_46_46_46slug_93YpiSfEWgP7Meta?.alias || [],
    redirect: _91_46_46_46slug_93YpiSfEWgP7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93YpiSfEWgP7Meta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_93YpiSfEWgP7Meta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93YpiSfEWgP7Meta || {},
    alias: _91_46_46_46slug_93YpiSfEWgP7Meta?.alias || [],
    redirect: _91_46_46_46slug_93YpiSfEWgP7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93YpiSfEWgP7Meta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_93YpiSfEWgP7Meta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93YpiSfEWgP7Meta || {},
    alias: _91_46_46_46slug_93YpiSfEWgP7Meta?.alias || [],
    redirect: _91_46_46_46slug_93YpiSfEWgP7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93YpiSfEWgP7Meta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_93YpiSfEWgP7Meta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93YpiSfEWgP7Meta || {},
    alias: _91_46_46_46slug_93YpiSfEWgP7Meta?.alias || [],
    redirect: _91_46_46_46slug_93YpiSfEWgP7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93YpiSfEWgP7Meta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_93YpiSfEWgP7Meta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93YpiSfEWgP7Meta || {},
    alias: _91_46_46_46slug_93YpiSfEWgP7Meta?.alias || [],
    redirect: _91_46_46_46slug_93YpiSfEWgP7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93YpiSfEWgP7Meta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_93YpiSfEWgP7Meta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93YpiSfEWgP7Meta || {},
    alias: _91_46_46_46slug_93YpiSfEWgP7Meta?.alias || [],
    redirect: _91_46_46_46slug_93YpiSfEWgP7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93YpiSfEWgP7Meta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_93YpiSfEWgP7Meta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93YpiSfEWgP7Meta || {},
    alias: _91_46_46_46slug_93YpiSfEWgP7Meta?.alias || [],
    redirect: _91_46_46_46slug_93YpiSfEWgP7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93YpiSfEWgP7Meta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_93YpiSfEWgP7Meta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93YpiSfEWgP7Meta || {},
    alias: _91_46_46_46slug_93YpiSfEWgP7Meta?.alias || [],
    redirect: _91_46_46_46slug_93YpiSfEWgP7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyond6koZgQ6HZ7Meta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyond6koZgQ6HZ7Meta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyond6koZgQ6HZ7Meta || {},
    alias: above_45beyond6koZgQ6HZ7Meta?.alias || [],
    redirect: above_45beyond6koZgQ6HZ7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond6koZgQ6HZ7Meta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyond6koZgQ6HZ7Meta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyond6koZgQ6HZ7Meta || {},
    alias: above_45beyond6koZgQ6HZ7Meta?.alias || [],
    redirect: above_45beyond6koZgQ6HZ7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond6koZgQ6HZ7Meta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyond6koZgQ6HZ7Meta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyond6koZgQ6HZ7Meta || {},
    alias: above_45beyond6koZgQ6HZ7Meta?.alias || [],
    redirect: above_45beyond6koZgQ6HZ7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond6koZgQ6HZ7Meta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyond6koZgQ6HZ7Meta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyond6koZgQ6HZ7Meta || {},
    alias: above_45beyond6koZgQ6HZ7Meta?.alias || [],
    redirect: above_45beyond6koZgQ6HZ7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond6koZgQ6HZ7Meta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyond6koZgQ6HZ7Meta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyond6koZgQ6HZ7Meta || {},
    alias: above_45beyond6koZgQ6HZ7Meta?.alias || [],
    redirect: above_45beyond6koZgQ6HZ7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond6koZgQ6HZ7Meta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyond6koZgQ6HZ7Meta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyond6koZgQ6HZ7Meta || {},
    alias: above_45beyond6koZgQ6HZ7Meta?.alias || [],
    redirect: above_45beyond6koZgQ6HZ7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond6koZgQ6HZ7Meta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyond6koZgQ6HZ7Meta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyond6koZgQ6HZ7Meta || {},
    alias: above_45beyond6koZgQ6HZ7Meta?.alias || [],
    redirect: above_45beyond6koZgQ6HZ7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyond6koZgQ6HZ7Meta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyond6koZgQ6HZ7Meta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyond6koZgQ6HZ7Meta || {},
    alias: above_45beyond6koZgQ6HZ7Meta?.alias || [],
    redirect: above_45beyond6koZgQ6HZ7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2E2ra93AgAMeta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performance2E2ra93AgAMeta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance2E2ra93AgAMeta || {},
    alias: excellence_45performance2E2ra93AgAMeta?.alias || [],
    redirect: excellence_45performance2E2ra93AgAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2E2ra93AgAMeta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performance2E2ra93AgAMeta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance2E2ra93AgAMeta || {},
    alias: excellence_45performance2E2ra93AgAMeta?.alias || [],
    redirect: excellence_45performance2E2ra93AgAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2E2ra93AgAMeta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performance2E2ra93AgAMeta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance2E2ra93AgAMeta || {},
    alias: excellence_45performance2E2ra93AgAMeta?.alias || [],
    redirect: excellence_45performance2E2ra93AgAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2E2ra93AgAMeta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performance2E2ra93AgAMeta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance2E2ra93AgAMeta || {},
    alias: excellence_45performance2E2ra93AgAMeta?.alias || [],
    redirect: excellence_45performance2E2ra93AgAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2E2ra93AgAMeta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performance2E2ra93AgAMeta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance2E2ra93AgAMeta || {},
    alias: excellence_45performance2E2ra93AgAMeta?.alias || [],
    redirect: excellence_45performance2E2ra93AgAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2E2ra93AgAMeta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performance2E2ra93AgAMeta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance2E2ra93AgAMeta || {},
    alias: excellence_45performance2E2ra93AgAMeta?.alias || [],
    redirect: excellence_45performance2E2ra93AgAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2E2ra93AgAMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performance2E2ra93AgAMeta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance2E2ra93AgAMeta || {},
    alias: excellence_45performance2E2ra93AgAMeta?.alias || [],
    redirect: excellence_45performance2E2ra93AgAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performance2E2ra93AgAMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performance2E2ra93AgAMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performance2E2ra93AgAMeta || {},
    alias: excellence_45performance2E2ra93AgAMeta?.alias || [],
    redirect: excellence_45performance2E2ra93AgAMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service8Kjs1KEz0gMeta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45service8Kjs1KEz0gMeta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45service8Kjs1KEz0gMeta || {},
    alias: excellence_45service8Kjs1KEz0gMeta?.alias || [],
    redirect: excellence_45service8Kjs1KEz0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service8Kjs1KEz0gMeta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45service8Kjs1KEz0gMeta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45service8Kjs1KEz0gMeta || {},
    alias: excellence_45service8Kjs1KEz0gMeta?.alias || [],
    redirect: excellence_45service8Kjs1KEz0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service8Kjs1KEz0gMeta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45service8Kjs1KEz0gMeta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45service8Kjs1KEz0gMeta || {},
    alias: excellence_45service8Kjs1KEz0gMeta?.alias || [],
    redirect: excellence_45service8Kjs1KEz0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service8Kjs1KEz0gMeta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45service8Kjs1KEz0gMeta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45service8Kjs1KEz0gMeta || {},
    alias: excellence_45service8Kjs1KEz0gMeta?.alias || [],
    redirect: excellence_45service8Kjs1KEz0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service8Kjs1KEz0gMeta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45service8Kjs1KEz0gMeta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45service8Kjs1KEz0gMeta || {},
    alias: excellence_45service8Kjs1KEz0gMeta?.alias || [],
    redirect: excellence_45service8Kjs1KEz0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service8Kjs1KEz0gMeta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45service8Kjs1KEz0gMeta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45service8Kjs1KEz0gMeta || {},
    alias: excellence_45service8Kjs1KEz0gMeta?.alias || [],
    redirect: excellence_45service8Kjs1KEz0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service8Kjs1KEz0gMeta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45service8Kjs1KEz0gMeta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45service8Kjs1KEz0gMeta || {},
    alias: excellence_45service8Kjs1KEz0gMeta?.alias || [],
    redirect: excellence_45service8Kjs1KEz0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service8Kjs1KEz0gMeta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45service8Kjs1KEz0gMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45service8Kjs1KEz0gMeta || {},
    alias: excellence_45service8Kjs1KEz0gMeta?.alias || [],
    redirect: excellence_45service8Kjs1KEz0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: indexYZTy42N2wSMeta?.name ?? "customers-topfleets-2019___en-US",
    path: indexYZTy42N2wSMeta?.path ?? "/customers/topfleets/2019",
    meta: indexYZTy42N2wSMeta || {},
    alias: indexYZTy42N2wSMeta?.alias || [],
    redirect: indexYZTy42N2wSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexYZTy42N2wSMeta?.name ?? "customers-topfleets-2019___ca",
    path: indexYZTy42N2wSMeta?.path ?? "/ca/customers/topfleets/2019",
    meta: indexYZTy42N2wSMeta || {},
    alias: indexYZTy42N2wSMeta?.alias || [],
    redirect: indexYZTy42N2wSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexYZTy42N2wSMeta?.name ?? "customers-topfleets-2019___nl",
    path: indexYZTy42N2wSMeta?.path ?? "/nl/customers/topfleets/2019",
    meta: indexYZTy42N2wSMeta || {},
    alias: indexYZTy42N2wSMeta?.alias || [],
    redirect: indexYZTy42N2wSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexYZTy42N2wSMeta?.name ?? "customers-topfleets-2019___uk",
    path: indexYZTy42N2wSMeta?.path ?? "/uk/customers/topfleets/2019",
    meta: indexYZTy42N2wSMeta || {},
    alias: indexYZTy42N2wSMeta?.alias || [],
    redirect: indexYZTy42N2wSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexYZTy42N2wSMeta?.name ?? "customers-topfleets-2019___de",
    path: indexYZTy42N2wSMeta?.path ?? "/de/customers/topfleets/2019",
    meta: indexYZTy42N2wSMeta || {},
    alias: indexYZTy42N2wSMeta?.alias || [],
    redirect: indexYZTy42N2wSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexYZTy42N2wSMeta?.name ?? "customers-topfleets-2019___mx",
    path: indexYZTy42N2wSMeta?.path ?? "/mx/customers/topfleets/2019",
    meta: indexYZTy42N2wSMeta || {},
    alias: indexYZTy42N2wSMeta?.alias || [],
    redirect: indexYZTy42N2wSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexYZTy42N2wSMeta?.name ?? "customers-topfleets-2019___fr",
    path: indexYZTy42N2wSMeta?.path ?? "/fr/customers/topfleets/2019",
    meta: indexYZTy42N2wSMeta || {},
    alias: indexYZTy42N2wSMeta?.alias || [],
    redirect: indexYZTy42N2wSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexYZTy42N2wSMeta?.name ?? "customers-topfleets-2019___fr-ca",
    path: indexYZTy42N2wSMeta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: indexYZTy42N2wSMeta || {},
    alias: indexYZTy42N2wSMeta?.alias || [],
    redirect: indexYZTy42N2wSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetgT5tVvyJJOMeta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleetgT5tVvyJJOMeta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetgT5tVvyJJOMeta || {},
    alias: safest_45fleetgT5tVvyJJOMeta?.alias || [],
    redirect: safest_45fleetgT5tVvyJJOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetgT5tVvyJJOMeta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleetgT5tVvyJJOMeta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetgT5tVvyJJOMeta || {},
    alias: safest_45fleetgT5tVvyJJOMeta?.alias || [],
    redirect: safest_45fleetgT5tVvyJJOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetgT5tVvyJJOMeta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleetgT5tVvyJJOMeta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetgT5tVvyJJOMeta || {},
    alias: safest_45fleetgT5tVvyJJOMeta?.alias || [],
    redirect: safest_45fleetgT5tVvyJJOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetgT5tVvyJJOMeta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleetgT5tVvyJJOMeta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetgT5tVvyJJOMeta || {},
    alias: safest_45fleetgT5tVvyJJOMeta?.alias || [],
    redirect: safest_45fleetgT5tVvyJJOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetgT5tVvyJJOMeta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleetgT5tVvyJJOMeta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetgT5tVvyJJOMeta || {},
    alias: safest_45fleetgT5tVvyJJOMeta?.alias || [],
    redirect: safest_45fleetgT5tVvyJJOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetgT5tVvyJJOMeta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleetgT5tVvyJJOMeta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetgT5tVvyJJOMeta || {},
    alias: safest_45fleetgT5tVvyJJOMeta?.alias || [],
    redirect: safest_45fleetgT5tVvyJJOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetgT5tVvyJJOMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleetgT5tVvyJJOMeta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetgT5tVvyJJOMeta || {},
    alias: safest_45fleetgT5tVvyJJOMeta?.alias || [],
    redirect: safest_45fleetgT5tVvyJJOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetgT5tVvyJJOMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleetgT5tVvyJJOMeta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleetgT5tVvyJJOMeta || {},
    alias: safest_45fleetgT5tVvyJJOMeta?.alias || [],
    redirect: safest_45fleetgT5tVvyJJOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceEr9JMrnlRmMeta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performanceEr9JMrnlRmMeta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceEr9JMrnlRmMeta || {},
    alias: driver_45performanceEr9JMrnlRmMeta?.alias || [],
    redirect: driver_45performanceEr9JMrnlRmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceEr9JMrnlRmMeta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performanceEr9JMrnlRmMeta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceEr9JMrnlRmMeta || {},
    alias: driver_45performanceEr9JMrnlRmMeta?.alias || [],
    redirect: driver_45performanceEr9JMrnlRmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceEr9JMrnlRmMeta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performanceEr9JMrnlRmMeta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceEr9JMrnlRmMeta || {},
    alias: driver_45performanceEr9JMrnlRmMeta?.alias || [],
    redirect: driver_45performanceEr9JMrnlRmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceEr9JMrnlRmMeta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performanceEr9JMrnlRmMeta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceEr9JMrnlRmMeta || {},
    alias: driver_45performanceEr9JMrnlRmMeta?.alias || [],
    redirect: driver_45performanceEr9JMrnlRmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceEr9JMrnlRmMeta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performanceEr9JMrnlRmMeta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceEr9JMrnlRmMeta || {},
    alias: driver_45performanceEr9JMrnlRmMeta?.alias || [],
    redirect: driver_45performanceEr9JMrnlRmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceEr9JMrnlRmMeta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performanceEr9JMrnlRmMeta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceEr9JMrnlRmMeta || {},
    alias: driver_45performanceEr9JMrnlRmMeta?.alias || [],
    redirect: driver_45performanceEr9JMrnlRmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceEr9JMrnlRmMeta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performanceEr9JMrnlRmMeta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceEr9JMrnlRmMeta || {},
    alias: driver_45performanceEr9JMrnlRmMeta?.alias || [],
    redirect: driver_45performanceEr9JMrnlRmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceEr9JMrnlRmMeta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performanceEr9JMrnlRmMeta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceEr9JMrnlRmMeta || {},
    alias: driver_45performanceEr9JMrnlRmMeta?.alias || [],
    redirect: driver_45performanceEr9JMrnlRmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancexewSZKsmxaMeta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performancexewSZKsmxaMeta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancexewSZKsmxaMeta || {},
    alias: excellence_45performancexewSZKsmxaMeta?.alias || [],
    redirect: excellence_45performancexewSZKsmxaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancexewSZKsmxaMeta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performancexewSZKsmxaMeta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancexewSZKsmxaMeta || {},
    alias: excellence_45performancexewSZKsmxaMeta?.alias || [],
    redirect: excellence_45performancexewSZKsmxaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancexewSZKsmxaMeta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performancexewSZKsmxaMeta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancexewSZKsmxaMeta || {},
    alias: excellence_45performancexewSZKsmxaMeta?.alias || [],
    redirect: excellence_45performancexewSZKsmxaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancexewSZKsmxaMeta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performancexewSZKsmxaMeta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancexewSZKsmxaMeta || {},
    alias: excellence_45performancexewSZKsmxaMeta?.alias || [],
    redirect: excellence_45performancexewSZKsmxaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancexewSZKsmxaMeta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performancexewSZKsmxaMeta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancexewSZKsmxaMeta || {},
    alias: excellence_45performancexewSZKsmxaMeta?.alias || [],
    redirect: excellence_45performancexewSZKsmxaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancexewSZKsmxaMeta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performancexewSZKsmxaMeta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancexewSZKsmxaMeta || {},
    alias: excellence_45performancexewSZKsmxaMeta?.alias || [],
    redirect: excellence_45performancexewSZKsmxaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancexewSZKsmxaMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performancexewSZKsmxaMeta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancexewSZKsmxaMeta || {},
    alias: excellence_45performancexewSZKsmxaMeta?.alias || [],
    redirect: excellence_45performancexewSZKsmxaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancexewSZKsmxaMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performancexewSZKsmxaMeta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancexewSZKsmxaMeta || {},
    alias: excellence_45performancexewSZKsmxaMeta?.alias || [],
    redirect: excellence_45performancexewSZKsmxaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator5mUJUJ9lgrMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovator5mUJUJ9lgrMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator5mUJUJ9lgrMeta || {},
    alias: fleet_45innovator5mUJUJ9lgrMeta?.alias || [],
    redirect: fleet_45innovator5mUJUJ9lgrMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator5mUJUJ9lgrMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovator5mUJUJ9lgrMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator5mUJUJ9lgrMeta || {},
    alias: fleet_45innovator5mUJUJ9lgrMeta?.alias || [],
    redirect: fleet_45innovator5mUJUJ9lgrMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator5mUJUJ9lgrMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovator5mUJUJ9lgrMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator5mUJUJ9lgrMeta || {},
    alias: fleet_45innovator5mUJUJ9lgrMeta?.alias || [],
    redirect: fleet_45innovator5mUJUJ9lgrMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator5mUJUJ9lgrMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovator5mUJUJ9lgrMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator5mUJUJ9lgrMeta || {},
    alias: fleet_45innovator5mUJUJ9lgrMeta?.alias || [],
    redirect: fleet_45innovator5mUJUJ9lgrMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator5mUJUJ9lgrMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovator5mUJUJ9lgrMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator5mUJUJ9lgrMeta || {},
    alias: fleet_45innovator5mUJUJ9lgrMeta?.alias || [],
    redirect: fleet_45innovator5mUJUJ9lgrMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator5mUJUJ9lgrMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovator5mUJUJ9lgrMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator5mUJUJ9lgrMeta || {},
    alias: fleet_45innovator5mUJUJ9lgrMeta?.alias || [],
    redirect: fleet_45innovator5mUJUJ9lgrMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator5mUJUJ9lgrMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovator5mUJUJ9lgrMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator5mUJUJ9lgrMeta || {},
    alias: fleet_45innovator5mUJUJ9lgrMeta?.alias || [],
    redirect: fleet_45innovator5mUJUJ9lgrMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator5mUJUJ9lgrMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovator5mUJUJ9lgrMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator5mUJUJ9lgrMeta || {},
    alias: fleet_45innovator5mUJUJ9lgrMeta?.alias || [],
    redirect: fleet_45innovator5mUJUJ9lgrMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: indexiPvnvT5qwHMeta?.name ?? "customers-topfleets-2020___en-US",
    path: indexiPvnvT5qwHMeta?.path ?? "/customers/topfleets/2020",
    meta: indexiPvnvT5qwHMeta || {},
    alias: indexiPvnvT5qwHMeta?.alias || [],
    redirect: indexiPvnvT5qwHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPvnvT5qwHMeta?.name ?? "customers-topfleets-2020___ca",
    path: indexiPvnvT5qwHMeta?.path ?? "/ca/customers/topfleets/2020",
    meta: indexiPvnvT5qwHMeta || {},
    alias: indexiPvnvT5qwHMeta?.alias || [],
    redirect: indexiPvnvT5qwHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPvnvT5qwHMeta?.name ?? "customers-topfleets-2020___nl",
    path: indexiPvnvT5qwHMeta?.path ?? "/nl/customers/topfleets/2020",
    meta: indexiPvnvT5qwHMeta || {},
    alias: indexiPvnvT5qwHMeta?.alias || [],
    redirect: indexiPvnvT5qwHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPvnvT5qwHMeta?.name ?? "customers-topfleets-2020___uk",
    path: indexiPvnvT5qwHMeta?.path ?? "/uk/customers/topfleets/2020",
    meta: indexiPvnvT5qwHMeta || {},
    alias: indexiPvnvT5qwHMeta?.alias || [],
    redirect: indexiPvnvT5qwHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPvnvT5qwHMeta?.name ?? "customers-topfleets-2020___de",
    path: indexiPvnvT5qwHMeta?.path ?? "/de/customers/topfleets/2020",
    meta: indexiPvnvT5qwHMeta || {},
    alias: indexiPvnvT5qwHMeta?.alias || [],
    redirect: indexiPvnvT5qwHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPvnvT5qwHMeta?.name ?? "customers-topfleets-2020___mx",
    path: indexiPvnvT5qwHMeta?.path ?? "/mx/customers/topfleets/2020",
    meta: indexiPvnvT5qwHMeta || {},
    alias: indexiPvnvT5qwHMeta?.alias || [],
    redirect: indexiPvnvT5qwHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPvnvT5qwHMeta?.name ?? "customers-topfleets-2020___fr",
    path: indexiPvnvT5qwHMeta?.path ?? "/fr/customers/topfleets/2020",
    meta: indexiPvnvT5qwHMeta || {},
    alias: indexiPvnvT5qwHMeta?.alias || [],
    redirect: indexiPvnvT5qwHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPvnvT5qwHMeta?.name ?? "customers-topfleets-2020___fr-ca",
    path: indexiPvnvT5qwHMeta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: indexiPvnvT5qwHMeta || {},
    alias: indexiPvnvT5qwHMeta?.alias || [],
    redirect: indexiPvnvT5qwHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleet9LfFdFjNdhMeta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleet9LfFdFjNdhMeta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleet9LfFdFjNdhMeta || {},
    alias: public_45fleet9LfFdFjNdhMeta?.alias || [],
    redirect: public_45fleet9LfFdFjNdhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleet9LfFdFjNdhMeta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleet9LfFdFjNdhMeta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleet9LfFdFjNdhMeta || {},
    alias: public_45fleet9LfFdFjNdhMeta?.alias || [],
    redirect: public_45fleet9LfFdFjNdhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleet9LfFdFjNdhMeta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleet9LfFdFjNdhMeta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleet9LfFdFjNdhMeta || {},
    alias: public_45fleet9LfFdFjNdhMeta?.alias || [],
    redirect: public_45fleet9LfFdFjNdhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleet9LfFdFjNdhMeta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleet9LfFdFjNdhMeta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleet9LfFdFjNdhMeta || {},
    alias: public_45fleet9LfFdFjNdhMeta?.alias || [],
    redirect: public_45fleet9LfFdFjNdhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleet9LfFdFjNdhMeta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleet9LfFdFjNdhMeta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleet9LfFdFjNdhMeta || {},
    alias: public_45fleet9LfFdFjNdhMeta?.alias || [],
    redirect: public_45fleet9LfFdFjNdhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleet9LfFdFjNdhMeta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleet9LfFdFjNdhMeta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleet9LfFdFjNdhMeta || {},
    alias: public_45fleet9LfFdFjNdhMeta?.alias || [],
    redirect: public_45fleet9LfFdFjNdhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleet9LfFdFjNdhMeta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleet9LfFdFjNdhMeta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleet9LfFdFjNdhMeta || {},
    alias: public_45fleet9LfFdFjNdhMeta?.alias || [],
    redirect: public_45fleet9LfFdFjNdhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleet9LfFdFjNdhMeta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleet9LfFdFjNdhMeta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleet9LfFdFjNdhMeta || {},
    alias: public_45fleet9LfFdFjNdhMeta?.alias || [],
    redirect: public_45fleet9LfFdFjNdhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetPVf1W2CvsUMeta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetPVf1W2CvsUMeta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetPVf1W2CvsUMeta || {},
    alias: safest_45fleetPVf1W2CvsUMeta?.alias || [],
    redirect: safest_45fleetPVf1W2CvsUMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetPVf1W2CvsUMeta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetPVf1W2CvsUMeta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetPVf1W2CvsUMeta || {},
    alias: safest_45fleetPVf1W2CvsUMeta?.alias || [],
    redirect: safest_45fleetPVf1W2CvsUMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: indexabVEPxWNROMeta?.name ?? "customers-topfleets___en-US",
    path: indexabVEPxWNROMeta?.path ?? "/customers/topfleets",
    meta: indexabVEPxWNROMeta || {},
    alias: indexabVEPxWNROMeta?.alias || [],
    redirect: indexabVEPxWNROMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexabVEPxWNROMeta?.name ?? "customers-topfleets___ca",
    path: indexabVEPxWNROMeta?.path ?? "/ca/customers/topfleets",
    meta: indexabVEPxWNROMeta || {},
    alias: indexabVEPxWNROMeta?.alias || [],
    redirect: indexabVEPxWNROMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexabVEPxWNROMeta?.name ?? "customers-topfleets___nl",
    path: indexabVEPxWNROMeta?.path ?? "/nl/customers/topfleets",
    meta: indexabVEPxWNROMeta || {},
    alias: indexabVEPxWNROMeta?.alias || [],
    redirect: indexabVEPxWNROMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexabVEPxWNROMeta?.name ?? "customers-topfleets___uk",
    path: indexabVEPxWNROMeta?.path ?? "/uk/customers/topfleets",
    meta: indexabVEPxWNROMeta || {},
    alias: indexabVEPxWNROMeta?.alias || [],
    redirect: indexabVEPxWNROMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexabVEPxWNROMeta?.name ?? "customers-topfleets___de",
    path: indexabVEPxWNROMeta?.path ?? "/de/customers/topfleets",
    meta: indexabVEPxWNROMeta || {},
    alias: indexabVEPxWNROMeta?.alias || [],
    redirect: indexabVEPxWNROMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexabVEPxWNROMeta?.name ?? "customers-topfleets___mx",
    path: indexabVEPxWNROMeta?.path ?? "/mx/customers/topfleets",
    meta: indexabVEPxWNROMeta || {},
    alias: indexabVEPxWNROMeta?.alias || [],
    redirect: indexabVEPxWNROMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexabVEPxWNROMeta?.name ?? "customers-topfleets___fr",
    path: indexabVEPxWNROMeta?.path ?? "/fr/customers/topfleets",
    meta: indexabVEPxWNROMeta || {},
    alias: indexabVEPxWNROMeta?.alias || [],
    redirect: indexabVEPxWNROMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexabVEPxWNROMeta?.name ?? "customers-topfleets___fr-ca",
    path: indexabVEPxWNROMeta?.path ?? "/fr-ca/customers/topfleets",
    meta: indexabVEPxWNROMeta || {},
    alias: indexabVEPxWNROMeta?.alias || [],
    redirect: indexabVEPxWNROMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uAOYu4YPsIMeta?.name ?? "events-slug___en-US",
    path: _91slug_93uAOYu4YPsIMeta?.path ?? "/events/:slug()",
    meta: _91slug_93uAOYu4YPsIMeta || {},
    alias: _91slug_93uAOYu4YPsIMeta?.alias || [],
    redirect: _91slug_93uAOYu4YPsIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uAOYu4YPsIMeta?.name ?? "events-slug___ca",
    path: _91slug_93uAOYu4YPsIMeta?.path ?? "/ca/events/:slug()",
    meta: _91slug_93uAOYu4YPsIMeta || {},
    alias: _91slug_93uAOYu4YPsIMeta?.alias || [],
    redirect: _91slug_93uAOYu4YPsIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uAOYu4YPsIMeta?.name ?? "events-slug___nl",
    path: _91slug_93uAOYu4YPsIMeta?.path ?? "/nl/events/:slug()",
    meta: _91slug_93uAOYu4YPsIMeta || {},
    alias: _91slug_93uAOYu4YPsIMeta?.alias || [],
    redirect: _91slug_93uAOYu4YPsIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uAOYu4YPsIMeta?.name ?? "events-slug___uk",
    path: _91slug_93uAOYu4YPsIMeta?.path ?? "/uk/events/:slug()",
    meta: _91slug_93uAOYu4YPsIMeta || {},
    alias: _91slug_93uAOYu4YPsIMeta?.alias || [],
    redirect: _91slug_93uAOYu4YPsIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uAOYu4YPsIMeta?.name ?? "events-slug___de",
    path: _91slug_93uAOYu4YPsIMeta?.path ?? "/de/events/:slug()",
    meta: _91slug_93uAOYu4YPsIMeta || {},
    alias: _91slug_93uAOYu4YPsIMeta?.alias || [],
    redirect: _91slug_93uAOYu4YPsIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uAOYu4YPsIMeta?.name ?? "events-slug___mx",
    path: _91slug_93uAOYu4YPsIMeta?.path ?? "/mx/events/:slug()",
    meta: _91slug_93uAOYu4YPsIMeta || {},
    alias: _91slug_93uAOYu4YPsIMeta?.alias || [],
    redirect: _91slug_93uAOYu4YPsIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uAOYu4YPsIMeta?.name ?? "events-slug___fr",
    path: _91slug_93uAOYu4YPsIMeta?.path ?? "/fr/events/:slug()",
    meta: _91slug_93uAOYu4YPsIMeta || {},
    alias: _91slug_93uAOYu4YPsIMeta?.alias || [],
    redirect: _91slug_93uAOYu4YPsIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93uAOYu4YPsIMeta?.name ?? "events-slug___fr-ca",
    path: _91slug_93uAOYu4YPsIMeta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_93uAOYu4YPsIMeta || {},
    alias: _91slug_93uAOYu4YPsIMeta?.alias || [],
    redirect: _91slug_93uAOYu4YPsIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexCeKf8Ywrj3Meta?.name ?? "events___en-US",
    path: indexCeKf8Ywrj3Meta?.path ?? "/events",
    meta: indexCeKf8Ywrj3Meta || {},
    alias: indexCeKf8Ywrj3Meta?.alias || [],
    redirect: indexCeKf8Ywrj3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexCeKf8Ywrj3Meta?.name ?? "events___ca",
    path: indexCeKf8Ywrj3Meta?.path ?? "/ca/events",
    meta: indexCeKf8Ywrj3Meta || {},
    alias: indexCeKf8Ywrj3Meta?.alias || [],
    redirect: indexCeKf8Ywrj3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexCeKf8Ywrj3Meta?.name ?? "events___nl",
    path: indexCeKf8Ywrj3Meta?.path ?? "/nl/events",
    meta: indexCeKf8Ywrj3Meta || {},
    alias: indexCeKf8Ywrj3Meta?.alias || [],
    redirect: indexCeKf8Ywrj3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexCeKf8Ywrj3Meta?.name ?? "events___uk",
    path: indexCeKf8Ywrj3Meta?.path ?? "/uk/events",
    meta: indexCeKf8Ywrj3Meta || {},
    alias: indexCeKf8Ywrj3Meta?.alias || [],
    redirect: indexCeKf8Ywrj3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexCeKf8Ywrj3Meta?.name ?? "events___de",
    path: indexCeKf8Ywrj3Meta?.path ?? "/de/events",
    meta: indexCeKf8Ywrj3Meta || {},
    alias: indexCeKf8Ywrj3Meta?.alias || [],
    redirect: indexCeKf8Ywrj3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexCeKf8Ywrj3Meta?.name ?? "events___mx",
    path: indexCeKf8Ywrj3Meta?.path ?? "/mx/events",
    meta: indexCeKf8Ywrj3Meta || {},
    alias: indexCeKf8Ywrj3Meta?.alias || [],
    redirect: indexCeKf8Ywrj3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexCeKf8Ywrj3Meta?.name ?? "events___fr",
    path: indexCeKf8Ywrj3Meta?.path ?? "/fr/events",
    meta: indexCeKf8Ywrj3Meta || {},
    alias: indexCeKf8Ywrj3Meta?.alias || [],
    redirect: indexCeKf8Ywrj3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexCeKf8Ywrj3Meta?.name ?? "events___fr-ca",
    path: indexCeKf8Ywrj3Meta?.path ?? "/fr-ca/events",
    meta: indexCeKf8Ywrj3Meta || {},
    alias: indexCeKf8Ywrj3Meta?.alias || [],
    redirect: indexCeKf8Ywrj3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app2w7NmdnEqsMeta?.name ?? "fleet-app___en-US",
    path: fleet_45app2w7NmdnEqsMeta?.path ?? "/fleet-app",
    meta: fleet_45app2w7NmdnEqsMeta || {},
    alias: fleet_45app2w7NmdnEqsMeta?.alias || [],
    redirect: fleet_45app2w7NmdnEqsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app2w7NmdnEqsMeta?.name ?? "fleet-app___ca",
    path: fleet_45app2w7NmdnEqsMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45app2w7NmdnEqsMeta || {},
    alias: fleet_45app2w7NmdnEqsMeta?.alias || [],
    redirect: fleet_45app2w7NmdnEqsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app2w7NmdnEqsMeta?.name ?? "fleet-app___nl",
    path: fleet_45app2w7NmdnEqsMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45app2w7NmdnEqsMeta || {},
    alias: fleet_45app2w7NmdnEqsMeta?.alias || [],
    redirect: fleet_45app2w7NmdnEqsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app2w7NmdnEqsMeta?.name ?? "fleet-app___uk",
    path: fleet_45app2w7NmdnEqsMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45app2w7NmdnEqsMeta || {},
    alias: fleet_45app2w7NmdnEqsMeta?.alias || [],
    redirect: fleet_45app2w7NmdnEqsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app2w7NmdnEqsMeta?.name ?? "fleet-app___de",
    path: fleet_45app2w7NmdnEqsMeta?.path ?? "/de/fleet-app",
    meta: fleet_45app2w7NmdnEqsMeta || {},
    alias: fleet_45app2w7NmdnEqsMeta?.alias || [],
    redirect: fleet_45app2w7NmdnEqsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app2w7NmdnEqsMeta?.name ?? "fleet-app___mx",
    path: fleet_45app2w7NmdnEqsMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45app2w7NmdnEqsMeta || {},
    alias: fleet_45app2w7NmdnEqsMeta?.alias || [],
    redirect: fleet_45app2w7NmdnEqsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app2w7NmdnEqsMeta?.name ?? "fleet-app___fr",
    path: fleet_45app2w7NmdnEqsMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45app2w7NmdnEqsMeta || {},
    alias: fleet_45app2w7NmdnEqsMeta?.alias || [],
    redirect: fleet_45app2w7NmdnEqsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app2w7NmdnEqsMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45app2w7NmdnEqsMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45app2w7NmdnEqsMeta || {},
    alias: fleet_45app2w7NmdnEqsMeta?.alias || [],
    redirect: fleet_45app2w7NmdnEqsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfO36U4Y8MMeta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_93OfO36U4Y8MMeta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_93OfO36U4Y8MMeta || {},
    alias: _91slug_93OfO36U4Y8MMeta?.alias || [],
    redirect: _91slug_93OfO36U4Y8MMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfO36U4Y8MMeta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_93OfO36U4Y8MMeta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_93OfO36U4Y8MMeta || {},
    alias: _91slug_93OfO36U4Y8MMeta?.alias || [],
    redirect: _91slug_93OfO36U4Y8MMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfO36U4Y8MMeta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_93OfO36U4Y8MMeta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_93OfO36U4Y8MMeta || {},
    alias: _91slug_93OfO36U4Y8MMeta?.alias || [],
    redirect: _91slug_93OfO36U4Y8MMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfO36U4Y8MMeta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_93OfO36U4Y8MMeta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_93OfO36U4Y8MMeta || {},
    alias: _91slug_93OfO36U4Y8MMeta?.alias || [],
    redirect: _91slug_93OfO36U4Y8MMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfO36U4Y8MMeta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_93OfO36U4Y8MMeta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_93OfO36U4Y8MMeta || {},
    alias: _91slug_93OfO36U4Y8MMeta?.alias || [],
    redirect: _91slug_93OfO36U4Y8MMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfO36U4Y8MMeta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_93OfO36U4Y8MMeta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_93OfO36U4Y8MMeta || {},
    alias: _91slug_93OfO36U4Y8MMeta?.alias || [],
    redirect: _91slug_93OfO36U4Y8MMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfO36U4Y8MMeta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_93OfO36U4Y8MMeta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_93OfO36U4Y8MMeta || {},
    alias: _91slug_93OfO36U4Y8MMeta?.alias || [],
    redirect: _91slug_93OfO36U4Y8MMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfO36U4Y8MMeta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_93OfO36U4Y8MMeta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_93OfO36U4Y8MMeta || {},
    alias: _91slug_93OfO36U4Y8MMeta?.alias || [],
    redirect: _91slug_93OfO36U4Y8MMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3tThDqAIxMeta?.name ?? "fleet-slug___en-US",
    path: _91slug_93L3tThDqAIxMeta?.path ?? "/fleet/:slug()",
    meta: _91slug_93L3tThDqAIxMeta || {},
    alias: _91slug_93L3tThDqAIxMeta?.alias || [],
    redirect: _91slug_93L3tThDqAIxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3tThDqAIxMeta?.name ?? "fleet-slug___ca",
    path: _91slug_93L3tThDqAIxMeta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_93L3tThDqAIxMeta || {},
    alias: _91slug_93L3tThDqAIxMeta?.alias || [],
    redirect: _91slug_93L3tThDqAIxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3tThDqAIxMeta?.name ?? "fleet-slug___nl",
    path: _91slug_93L3tThDqAIxMeta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_93L3tThDqAIxMeta || {},
    alias: _91slug_93L3tThDqAIxMeta?.alias || [],
    redirect: _91slug_93L3tThDqAIxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3tThDqAIxMeta?.name ?? "fleet-slug___uk",
    path: _91slug_93L3tThDqAIxMeta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_93L3tThDqAIxMeta || {},
    alias: _91slug_93L3tThDqAIxMeta?.alias || [],
    redirect: _91slug_93L3tThDqAIxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3tThDqAIxMeta?.name ?? "fleet-slug___de",
    path: _91slug_93L3tThDqAIxMeta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_93L3tThDqAIxMeta || {},
    alias: _91slug_93L3tThDqAIxMeta?.alias || [],
    redirect: _91slug_93L3tThDqAIxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3tThDqAIxMeta?.name ?? "fleet-slug___mx",
    path: _91slug_93L3tThDqAIxMeta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_93L3tThDqAIxMeta || {},
    alias: _91slug_93L3tThDqAIxMeta?.alias || [],
    redirect: _91slug_93L3tThDqAIxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3tThDqAIxMeta?.name ?? "fleet-slug___fr",
    path: _91slug_93L3tThDqAIxMeta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_93L3tThDqAIxMeta || {},
    alias: _91slug_93L3tThDqAIxMeta?.alias || [],
    redirect: _91slug_93L3tThDqAIxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L3tThDqAIxMeta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_93L3tThDqAIxMeta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_93L3tThDqAIxMeta || {},
    alias: _91slug_93L3tThDqAIxMeta?.alias || [],
    redirect: _91slug_93L3tThDqAIxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pzqADtP4cvMeta?.name ?? "fleets-prefix-slug___en-US",
    path: _91slug_93pzqADtP4cvMeta?.path ?? "/fleets/:prefix()/:slug()",
    meta: _91slug_93pzqADtP4cvMeta || {},
    alias: _91slug_93pzqADtP4cvMeta?.alias || [],
    redirect: _91slug_93pzqADtP4cvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pzqADtP4cvMeta?.name ?? "fleets-prefix-slug___ca",
    path: _91slug_93pzqADtP4cvMeta?.path ?? "/ca/fleets/:prefix()/:slug()",
    meta: _91slug_93pzqADtP4cvMeta || {},
    alias: _91slug_93pzqADtP4cvMeta?.alias || [],
    redirect: _91slug_93pzqADtP4cvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pzqADtP4cvMeta?.name ?? "fleets-prefix-slug___nl",
    path: _91slug_93pzqADtP4cvMeta?.path ?? "/nl/fleets/:prefix()/:slug()",
    meta: _91slug_93pzqADtP4cvMeta || {},
    alias: _91slug_93pzqADtP4cvMeta?.alias || [],
    redirect: _91slug_93pzqADtP4cvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pzqADtP4cvMeta?.name ?? "fleets-prefix-slug___uk",
    path: _91slug_93pzqADtP4cvMeta?.path ?? "/uk/fleets/:prefix()/:slug()",
    meta: _91slug_93pzqADtP4cvMeta || {},
    alias: _91slug_93pzqADtP4cvMeta?.alias || [],
    redirect: _91slug_93pzqADtP4cvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pzqADtP4cvMeta?.name ?? "fleets-prefix-slug___de",
    path: _91slug_93pzqADtP4cvMeta?.path ?? "/de/fleets/:prefix()/:slug()",
    meta: _91slug_93pzqADtP4cvMeta || {},
    alias: _91slug_93pzqADtP4cvMeta?.alias || [],
    redirect: _91slug_93pzqADtP4cvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pzqADtP4cvMeta?.name ?? "fleets-prefix-slug___mx",
    path: _91slug_93pzqADtP4cvMeta?.path ?? "/mx/fleets/:prefix()/:slug()",
    meta: _91slug_93pzqADtP4cvMeta || {},
    alias: _91slug_93pzqADtP4cvMeta?.alias || [],
    redirect: _91slug_93pzqADtP4cvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pzqADtP4cvMeta?.name ?? "fleets-prefix-slug___fr",
    path: _91slug_93pzqADtP4cvMeta?.path ?? "/fr/fleets/:prefix()/:slug()",
    meta: _91slug_93pzqADtP4cvMeta || {},
    alias: _91slug_93pzqADtP4cvMeta?.alias || [],
    redirect: _91slug_93pzqADtP4cvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pzqADtP4cvMeta?.name ?? "fleets-prefix-slug___fr-ca",
    path: _91slug_93pzqADtP4cvMeta?.path ?? "/fr-ca/fleets/:prefix()/:slug()",
    meta: _91slug_93pzqADtP4cvMeta || {},
    alias: _91slug_93pzqADtP4cvMeta?.alias || [],
    redirect: _91slug_93pzqADtP4cvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0qi3DRxzKMeta?.name ?? "fleets-slug___en-US",
    path: _91slug_93V0qi3DRxzKMeta?.path ?? "/fleets/:slug()",
    meta: _91slug_93V0qi3DRxzKMeta || {},
    alias: _91slug_93V0qi3DRxzKMeta?.alias || [],
    redirect: _91slug_93V0qi3DRxzKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0qi3DRxzKMeta?.name ?? "fleets-slug___ca",
    path: _91slug_93V0qi3DRxzKMeta?.path ?? "/ca/fleets/:slug()",
    meta: _91slug_93V0qi3DRxzKMeta || {},
    alias: _91slug_93V0qi3DRxzKMeta?.alias || [],
    redirect: _91slug_93V0qi3DRxzKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0qi3DRxzKMeta?.name ?? "fleets-slug___nl",
    path: _91slug_93V0qi3DRxzKMeta?.path ?? "/nl/fleets/:slug()",
    meta: _91slug_93V0qi3DRxzKMeta || {},
    alias: _91slug_93V0qi3DRxzKMeta?.alias || [],
    redirect: _91slug_93V0qi3DRxzKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0qi3DRxzKMeta?.name ?? "fleets-slug___uk",
    path: _91slug_93V0qi3DRxzKMeta?.path ?? "/uk/fleets/:slug()",
    meta: _91slug_93V0qi3DRxzKMeta || {},
    alias: _91slug_93V0qi3DRxzKMeta?.alias || [],
    redirect: _91slug_93V0qi3DRxzKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0qi3DRxzKMeta?.name ?? "fleets-slug___de",
    path: _91slug_93V0qi3DRxzKMeta?.path ?? "/de/fleets/:slug()",
    meta: _91slug_93V0qi3DRxzKMeta || {},
    alias: _91slug_93V0qi3DRxzKMeta?.alias || [],
    redirect: _91slug_93V0qi3DRxzKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0qi3DRxzKMeta?.name ?? "fleets-slug___mx",
    path: _91slug_93V0qi3DRxzKMeta?.path ?? "/mx/fleets/:slug()",
    meta: _91slug_93V0qi3DRxzKMeta || {},
    alias: _91slug_93V0qi3DRxzKMeta?.alias || [],
    redirect: _91slug_93V0qi3DRxzKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0qi3DRxzKMeta?.name ?? "fleets-slug___fr",
    path: _91slug_93V0qi3DRxzKMeta?.path ?? "/fr/fleets/:slug()",
    meta: _91slug_93V0qi3DRxzKMeta || {},
    alias: _91slug_93V0qi3DRxzKMeta?.alias || [],
    redirect: _91slug_93V0qi3DRxzKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93V0qi3DRxzKMeta?.name ?? "fleets-slug___fr-ca",
    path: _91slug_93V0qi3DRxzKMeta?.path ?? "/fr-ca/fleets/:slug()",
    meta: _91slug_93V0qi3DRxzKMeta || {},
    alias: _91slug_93V0qi3DRxzKMeta?.alias || [],
    redirect: _91slug_93V0qi3DRxzKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/fleets/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938e0IVmYR1nMeta?.name ?? "guides-slug___en-US",
    path: _91slug_938e0IVmYR1nMeta?.path ?? "/guides/:slug()",
    meta: _91slug_938e0IVmYR1nMeta || {},
    alias: _91slug_938e0IVmYR1nMeta?.alias || [],
    redirect: _91slug_938e0IVmYR1nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938e0IVmYR1nMeta?.name ?? "guides-slug___ca",
    path: _91slug_938e0IVmYR1nMeta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_938e0IVmYR1nMeta || {},
    alias: _91slug_938e0IVmYR1nMeta?.alias || [],
    redirect: _91slug_938e0IVmYR1nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938e0IVmYR1nMeta?.name ?? "guides-slug___nl",
    path: _91slug_938e0IVmYR1nMeta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_938e0IVmYR1nMeta || {},
    alias: _91slug_938e0IVmYR1nMeta?.alias || [],
    redirect: _91slug_938e0IVmYR1nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938e0IVmYR1nMeta?.name ?? "guides-slug___uk",
    path: _91slug_938e0IVmYR1nMeta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_938e0IVmYR1nMeta || {},
    alias: _91slug_938e0IVmYR1nMeta?.alias || [],
    redirect: _91slug_938e0IVmYR1nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938e0IVmYR1nMeta?.name ?? "guides-slug___de",
    path: _91slug_938e0IVmYR1nMeta?.path ?? "/de/guides/:slug()",
    meta: _91slug_938e0IVmYR1nMeta || {},
    alias: _91slug_938e0IVmYR1nMeta?.alias || [],
    redirect: _91slug_938e0IVmYR1nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938e0IVmYR1nMeta?.name ?? "guides-slug___mx",
    path: _91slug_938e0IVmYR1nMeta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_938e0IVmYR1nMeta || {},
    alias: _91slug_938e0IVmYR1nMeta?.alias || [],
    redirect: _91slug_938e0IVmYR1nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938e0IVmYR1nMeta?.name ?? "guides-slug___fr",
    path: _91slug_938e0IVmYR1nMeta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_938e0IVmYR1nMeta || {},
    alias: _91slug_938e0IVmYR1nMeta?.alias || [],
    redirect: _91slug_938e0IVmYR1nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_938e0IVmYR1nMeta?.name ?? "guides-slug___fr-ca",
    path: _91slug_938e0IVmYR1nMeta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_938e0IVmYR1nMeta || {},
    alias: _91slug_938e0IVmYR1nMeta?.alias || [],
    redirect: _91slug_938e0IVmYR1nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentTBCczFCE0hMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talentTBCczFCE0hMeta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentTBCczFCE0hMeta || {},
    alias: attract_45retain_45talentTBCczFCE0hMeta?.alias || [],
    redirect: attract_45retain_45talentTBCczFCE0hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentTBCczFCE0hMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talentTBCczFCE0hMeta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentTBCczFCE0hMeta || {},
    alias: attract_45retain_45talentTBCczFCE0hMeta?.alias || [],
    redirect: attract_45retain_45talentTBCczFCE0hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentTBCczFCE0hMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talentTBCczFCE0hMeta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentTBCczFCE0hMeta || {},
    alias: attract_45retain_45talentTBCczFCE0hMeta?.alias || [],
    redirect: attract_45retain_45talentTBCczFCE0hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentTBCczFCE0hMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talentTBCczFCE0hMeta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentTBCczFCE0hMeta || {},
    alias: attract_45retain_45talentTBCczFCE0hMeta?.alias || [],
    redirect: attract_45retain_45talentTBCczFCE0hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentTBCczFCE0hMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talentTBCczFCE0hMeta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentTBCczFCE0hMeta || {},
    alias: attract_45retain_45talentTBCczFCE0hMeta?.alias || [],
    redirect: attract_45retain_45talentTBCczFCE0hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentTBCczFCE0hMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talentTBCczFCE0hMeta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentTBCczFCE0hMeta || {},
    alias: attract_45retain_45talentTBCczFCE0hMeta?.alias || [],
    redirect: attract_45retain_45talentTBCczFCE0hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentTBCczFCE0hMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talentTBCczFCE0hMeta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentTBCczFCE0hMeta || {},
    alias: attract_45retain_45talentTBCczFCE0hMeta?.alias || [],
    redirect: attract_45retain_45talentTBCczFCE0hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentTBCczFCE0hMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talentTBCczFCE0hMeta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentTBCczFCE0hMeta || {},
    alias: attract_45retain_45talentTBCczFCE0hMeta?.alias || [],
    redirect: attract_45retain_45talentTBCczFCE0hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta || {},
    alias: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta || {},
    alias: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta || {},
    alias: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta || {},
    alias: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta || {},
    alias: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta || {},
    alias: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta || {},
    alias: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta || {},
    alias: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitslgKMk6dToaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indexVpBof3OsXmMeta?.name ?? "guides-build-business-resilience___en-US",
    path: indexVpBof3OsXmMeta?.path ?? "/guides/build-business-resilience",
    meta: indexVpBof3OsXmMeta || {},
    alias: indexVpBof3OsXmMeta?.alias || [],
    redirect: indexVpBof3OsXmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexVpBof3OsXmMeta?.name ?? "guides-build-business-resilience___ca",
    path: indexVpBof3OsXmMeta?.path ?? "/ca/guides/build-business-resilience",
    meta: indexVpBof3OsXmMeta || {},
    alias: indexVpBof3OsXmMeta?.alias || [],
    redirect: indexVpBof3OsXmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexVpBof3OsXmMeta?.name ?? "guides-build-business-resilience___nl",
    path: indexVpBof3OsXmMeta?.path ?? "/nl/guides/build-business-resilience",
    meta: indexVpBof3OsXmMeta || {},
    alias: indexVpBof3OsXmMeta?.alias || [],
    redirect: indexVpBof3OsXmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexVpBof3OsXmMeta?.name ?? "guides-build-business-resilience___uk",
    path: indexVpBof3OsXmMeta?.path ?? "/uk/guides/build-business-resilience",
    meta: indexVpBof3OsXmMeta || {},
    alias: indexVpBof3OsXmMeta?.alias || [],
    redirect: indexVpBof3OsXmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexVpBof3OsXmMeta?.name ?? "guides-build-business-resilience___de",
    path: indexVpBof3OsXmMeta?.path ?? "/de/guides/build-business-resilience",
    meta: indexVpBof3OsXmMeta || {},
    alias: indexVpBof3OsXmMeta?.alias || [],
    redirect: indexVpBof3OsXmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexVpBof3OsXmMeta?.name ?? "guides-build-business-resilience___mx",
    path: indexVpBof3OsXmMeta?.path ?? "/mx/guides/build-business-resilience",
    meta: indexVpBof3OsXmMeta || {},
    alias: indexVpBof3OsXmMeta?.alias || [],
    redirect: indexVpBof3OsXmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexVpBof3OsXmMeta?.name ?? "guides-build-business-resilience___fr",
    path: indexVpBof3OsXmMeta?.path ?? "/fr/guides/build-business-resilience",
    meta: indexVpBof3OsXmMeta || {},
    alias: indexVpBof3OsXmMeta?.alias || [],
    redirect: indexVpBof3OsXmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexVpBof3OsXmMeta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indexVpBof3OsXmMeta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indexVpBof3OsXmMeta || {},
    alias: indexVpBof3OsXmMeta?.alias || [],
    redirect: indexVpBof3OsXmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets6Os95zlXV8Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assets6Os95zlXV8Meta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets6Os95zlXV8Meta || {},
    alias: protect_45optimize_45assets6Os95zlXV8Meta?.alias || [],
    redirect: protect_45optimize_45assets6Os95zlXV8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets6Os95zlXV8Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assets6Os95zlXV8Meta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets6Os95zlXV8Meta || {},
    alias: protect_45optimize_45assets6Os95zlXV8Meta?.alias || [],
    redirect: protect_45optimize_45assets6Os95zlXV8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets6Os95zlXV8Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assets6Os95zlXV8Meta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets6Os95zlXV8Meta || {},
    alias: protect_45optimize_45assets6Os95zlXV8Meta?.alias || [],
    redirect: protect_45optimize_45assets6Os95zlXV8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets6Os95zlXV8Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assets6Os95zlXV8Meta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets6Os95zlXV8Meta || {},
    alias: protect_45optimize_45assets6Os95zlXV8Meta?.alias || [],
    redirect: protect_45optimize_45assets6Os95zlXV8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets6Os95zlXV8Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assets6Os95zlXV8Meta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets6Os95zlXV8Meta || {},
    alias: protect_45optimize_45assets6Os95zlXV8Meta?.alias || [],
    redirect: protect_45optimize_45assets6Os95zlXV8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets6Os95zlXV8Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assets6Os95zlXV8Meta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets6Os95zlXV8Meta || {},
    alias: protect_45optimize_45assets6Os95zlXV8Meta?.alias || [],
    redirect: protect_45optimize_45assets6Os95zlXV8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets6Os95zlXV8Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assets6Os95zlXV8Meta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets6Os95zlXV8Meta || {},
    alias: protect_45optimize_45assets6Os95zlXV8Meta?.alias || [],
    redirect: protect_45optimize_45assets6Os95zlXV8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assets6Os95zlXV8Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assets6Os95zlXV8Meta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assets6Os95zlXV8Meta || {},
    alias: protect_45optimize_45assets6Os95zlXV8Meta?.alias || [],
    redirect: protect_45optimize_45assets6Os95zlXV8Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsAZgWIpyndfMeta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45camsAZgWIpyndfMeta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsAZgWIpyndfMeta || {},
    alias: best_45dash_45camsAZgWIpyndfMeta?.alias || [],
    redirect: best_45dash_45camsAZgWIpyndfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsAZgWIpyndfMeta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45camsAZgWIpyndfMeta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsAZgWIpyndfMeta || {},
    alias: best_45dash_45camsAZgWIpyndfMeta?.alias || [],
    redirect: best_45dash_45camsAZgWIpyndfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsAZgWIpyndfMeta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45camsAZgWIpyndfMeta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsAZgWIpyndfMeta || {},
    alias: best_45dash_45camsAZgWIpyndfMeta?.alias || [],
    redirect: best_45dash_45camsAZgWIpyndfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsAZgWIpyndfMeta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45camsAZgWIpyndfMeta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsAZgWIpyndfMeta || {},
    alias: best_45dash_45camsAZgWIpyndfMeta?.alias || [],
    redirect: best_45dash_45camsAZgWIpyndfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsAZgWIpyndfMeta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45camsAZgWIpyndfMeta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsAZgWIpyndfMeta || {},
    alias: best_45dash_45camsAZgWIpyndfMeta?.alias || [],
    redirect: best_45dash_45camsAZgWIpyndfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsAZgWIpyndfMeta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45camsAZgWIpyndfMeta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsAZgWIpyndfMeta || {},
    alias: best_45dash_45camsAZgWIpyndfMeta?.alias || [],
    redirect: best_45dash_45camsAZgWIpyndfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsAZgWIpyndfMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45camsAZgWIpyndfMeta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsAZgWIpyndfMeta || {},
    alias: best_45dash_45camsAZgWIpyndfMeta?.alias || [],
    redirect: best_45dash_45camsAZgWIpyndfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsAZgWIpyndfMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45camsAZgWIpyndfMeta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsAZgWIpyndfMeta || {},
    alias: best_45dash_45camsAZgWIpyndfMeta?.alias || [],
    redirect: best_45dash_45camsAZgWIpyndfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: indexKTNueYckPgMeta?.name ?? "guides-fleet-safety___en-US",
    path: indexKTNueYckPgMeta?.path ?? "/guides/fleet-safety",
    meta: indexKTNueYckPgMeta || {},
    alias: indexKTNueYckPgMeta?.alias || [],
    redirect: indexKTNueYckPgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexKTNueYckPgMeta?.name ?? "guides-fleet-safety___ca",
    path: indexKTNueYckPgMeta?.path ?? "/ca/guides/fleet-safety",
    meta: indexKTNueYckPgMeta || {},
    alias: indexKTNueYckPgMeta?.alias || [],
    redirect: indexKTNueYckPgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexKTNueYckPgMeta?.name ?? "guides-fleet-safety___nl",
    path: indexKTNueYckPgMeta?.path ?? "/nl/guides/fleet-safety",
    meta: indexKTNueYckPgMeta || {},
    alias: indexKTNueYckPgMeta?.alias || [],
    redirect: indexKTNueYckPgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexKTNueYckPgMeta?.name ?? "guides-fleet-safety___uk",
    path: indexKTNueYckPgMeta?.path ?? "/uk/guides/fleet-safety",
    meta: indexKTNueYckPgMeta || {},
    alias: indexKTNueYckPgMeta?.alias || [],
    redirect: indexKTNueYckPgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexKTNueYckPgMeta?.name ?? "guides-fleet-safety___de",
    path: indexKTNueYckPgMeta?.path ?? "/de/guides/fleet-safety",
    meta: indexKTNueYckPgMeta || {},
    alias: indexKTNueYckPgMeta?.alias || [],
    redirect: indexKTNueYckPgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexKTNueYckPgMeta?.name ?? "guides-fleet-safety___mx",
    path: indexKTNueYckPgMeta?.path ?? "/mx/guias/seguridad-flotas",
    meta: indexKTNueYckPgMeta || {},
    alias: indexKTNueYckPgMeta?.alias || [],
    redirect: indexKTNueYckPgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexKTNueYckPgMeta?.name ?? "guides-fleet-safety___fr",
    path: indexKTNueYckPgMeta?.path ?? "/fr/guides/fleet-safety",
    meta: indexKTNueYckPgMeta || {},
    alias: indexKTNueYckPgMeta?.alias || [],
    redirect: indexKTNueYckPgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexKTNueYckPgMeta?.name ?? "guides-fleet-safety___fr-ca",
    path: indexKTNueYckPgMeta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: indexKTNueYckPgMeta || {},
    alias: indexKTNueYckPgMeta?.alias || [],
    redirect: indexKTNueYckPgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOklFRGIYT5Meta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45programOklFRGIYT5Meta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45programOklFRGIYT5Meta || {},
    alias: safety_45programOklFRGIYT5Meta?.alias || [],
    redirect: safety_45programOklFRGIYT5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOklFRGIYT5Meta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45programOklFRGIYT5Meta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45programOklFRGIYT5Meta || {},
    alias: safety_45programOklFRGIYT5Meta?.alias || [],
    redirect: safety_45programOklFRGIYT5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOklFRGIYT5Meta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45programOklFRGIYT5Meta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45programOklFRGIYT5Meta || {},
    alias: safety_45programOklFRGIYT5Meta?.alias || [],
    redirect: safety_45programOklFRGIYT5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOklFRGIYT5Meta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45programOklFRGIYT5Meta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45programOklFRGIYT5Meta || {},
    alias: safety_45programOklFRGIYT5Meta?.alias || [],
    redirect: safety_45programOklFRGIYT5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOklFRGIYT5Meta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45programOklFRGIYT5Meta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45programOklFRGIYT5Meta || {},
    alias: safety_45programOklFRGIYT5Meta?.alias || [],
    redirect: safety_45programOklFRGIYT5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOklFRGIYT5Meta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45programOklFRGIYT5Meta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45programOklFRGIYT5Meta || {},
    alias: safety_45programOklFRGIYT5Meta?.alias || [],
    redirect: safety_45programOklFRGIYT5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOklFRGIYT5Meta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45programOklFRGIYT5Meta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45programOklFRGIYT5Meta || {},
    alias: safety_45programOklFRGIYT5Meta?.alias || [],
    redirect: safety_45programOklFRGIYT5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programOklFRGIYT5Meta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45programOklFRGIYT5Meta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45programOklFRGIYT5Meta || {},
    alias: safety_45programOklFRGIYT5Meta?.alias || [],
    redirect: safety_45programOklFRGIYT5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiSJcAVFvR2dMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roiSJcAVFvR2dMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roiSJcAVFvR2dMeta || {},
    alias: safety_45roiSJcAVFvR2dMeta?.alias || [],
    redirect: safety_45roiSJcAVFvR2dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiSJcAVFvR2dMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roiSJcAVFvR2dMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiSJcAVFvR2dMeta || {},
    alias: safety_45roiSJcAVFvR2dMeta?.alias || [],
    redirect: safety_45roiSJcAVFvR2dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiSJcAVFvR2dMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roiSJcAVFvR2dMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roiSJcAVFvR2dMeta || {},
    alias: safety_45roiSJcAVFvR2dMeta?.alias || [],
    redirect: safety_45roiSJcAVFvR2dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiSJcAVFvR2dMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roiSJcAVFvR2dMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roiSJcAVFvR2dMeta || {},
    alias: safety_45roiSJcAVFvR2dMeta?.alias || [],
    redirect: safety_45roiSJcAVFvR2dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiSJcAVFvR2dMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roiSJcAVFvR2dMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roiSJcAVFvR2dMeta || {},
    alias: safety_45roiSJcAVFvR2dMeta?.alias || [],
    redirect: safety_45roiSJcAVFvR2dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiSJcAVFvR2dMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roiSJcAVFvR2dMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roiSJcAVFvR2dMeta || {},
    alias: safety_45roiSJcAVFvR2dMeta?.alias || [],
    redirect: safety_45roiSJcAVFvR2dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiSJcAVFvR2dMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roiSJcAVFvR2dMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roiSJcAVFvR2dMeta || {},
    alias: safety_45roiSJcAVFvR2dMeta?.alias || [],
    redirect: safety_45roiSJcAVFvR2dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiSJcAVFvR2dMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roiSJcAVFvR2dMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiSJcAVFvR2dMeta || {},
    alias: safety_45roiSJcAVFvR2dMeta?.alias || [],
    redirect: safety_45roiSJcAVFvR2dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: indexGd13FBnpmjMeta?.name ?? "guides___en-US",
    path: indexGd13FBnpmjMeta?.path ?? "/guides",
    meta: indexGd13FBnpmjMeta || {},
    alias: indexGd13FBnpmjMeta?.alias || [],
    redirect: indexGd13FBnpmjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexGd13FBnpmjMeta?.name ?? "guides___ca",
    path: indexGd13FBnpmjMeta?.path ?? "/ca/guides",
    meta: indexGd13FBnpmjMeta || {},
    alias: indexGd13FBnpmjMeta?.alias || [],
    redirect: indexGd13FBnpmjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexGd13FBnpmjMeta?.name ?? "guides___nl",
    path: indexGd13FBnpmjMeta?.path ?? "/nl/guides",
    meta: indexGd13FBnpmjMeta || {},
    alias: indexGd13FBnpmjMeta?.alias || [],
    redirect: indexGd13FBnpmjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexGd13FBnpmjMeta?.name ?? "guides___uk",
    path: indexGd13FBnpmjMeta?.path ?? "/uk/guides",
    meta: indexGd13FBnpmjMeta || {},
    alias: indexGd13FBnpmjMeta?.alias || [],
    redirect: indexGd13FBnpmjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexGd13FBnpmjMeta?.name ?? "guides___de",
    path: indexGd13FBnpmjMeta?.path ?? "/de/guides",
    meta: indexGd13FBnpmjMeta || {},
    alias: indexGd13FBnpmjMeta?.alias || [],
    redirect: indexGd13FBnpmjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexGd13FBnpmjMeta?.name ?? "guides___mx",
    path: indexGd13FBnpmjMeta?.path ?? "/mx/guides",
    meta: indexGd13FBnpmjMeta || {},
    alias: indexGd13FBnpmjMeta?.alias || [],
    redirect: indexGd13FBnpmjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexGd13FBnpmjMeta?.name ?? "guides___fr",
    path: indexGd13FBnpmjMeta?.path ?? "/fr/guides",
    meta: indexGd13FBnpmjMeta || {},
    alias: indexGd13FBnpmjMeta?.alias || [],
    redirect: indexGd13FBnpmjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexGd13FBnpmjMeta?.name ?? "guides___fr-ca",
    path: indexGd13FBnpmjMeta?.path ?? "/fr-ca/guides",
    meta: indexGd13FBnpmjMeta || {},
    alias: indexGd13FBnpmjMeta?.alias || [],
    redirect: indexGd13FBnpmjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureVC7lkcdvCEMeta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45futureVC7lkcdvCEMeta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureVC7lkcdvCEMeta || {},
    alias: build_45for_45the_45futureVC7lkcdvCEMeta?.alias || [],
    redirect: build_45for_45the_45futureVC7lkcdvCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureVC7lkcdvCEMeta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45futureVC7lkcdvCEMeta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureVC7lkcdvCEMeta || {},
    alias: build_45for_45the_45futureVC7lkcdvCEMeta?.alias || [],
    redirect: build_45for_45the_45futureVC7lkcdvCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureVC7lkcdvCEMeta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45futureVC7lkcdvCEMeta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureVC7lkcdvCEMeta || {},
    alias: build_45for_45the_45futureVC7lkcdvCEMeta?.alias || [],
    redirect: build_45for_45the_45futureVC7lkcdvCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureVC7lkcdvCEMeta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45futureVC7lkcdvCEMeta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureVC7lkcdvCEMeta || {},
    alias: build_45for_45the_45futureVC7lkcdvCEMeta?.alias || [],
    redirect: build_45for_45the_45futureVC7lkcdvCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureVC7lkcdvCEMeta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45futureVC7lkcdvCEMeta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureVC7lkcdvCEMeta || {},
    alias: build_45for_45the_45futureVC7lkcdvCEMeta?.alias || [],
    redirect: build_45for_45the_45futureVC7lkcdvCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureVC7lkcdvCEMeta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45futureVC7lkcdvCEMeta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureVC7lkcdvCEMeta || {},
    alias: build_45for_45the_45futureVC7lkcdvCEMeta?.alias || [],
    redirect: build_45for_45the_45futureVC7lkcdvCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureVC7lkcdvCEMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45futureVC7lkcdvCEMeta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureVC7lkcdvCEMeta || {},
    alias: build_45for_45the_45futureVC7lkcdvCEMeta?.alias || [],
    redirect: build_45for_45the_45futureVC7lkcdvCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futureVC7lkcdvCEMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45futureVC7lkcdvCEMeta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futureVC7lkcdvCEMeta || {},
    alias: build_45for_45the_45futureVC7lkcdvCEMeta?.alias || [],
    redirect: build_45for_45the_45futureVC7lkcdvCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsU5gwcoUSIVMeta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teamsU5gwcoUSIVMeta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsU5gwcoUSIVMeta || {},
    alias: empower_45your_45teamsU5gwcoUSIVMeta?.alias || [],
    redirect: empower_45your_45teamsU5gwcoUSIVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsU5gwcoUSIVMeta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teamsU5gwcoUSIVMeta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsU5gwcoUSIVMeta || {},
    alias: empower_45your_45teamsU5gwcoUSIVMeta?.alias || [],
    redirect: empower_45your_45teamsU5gwcoUSIVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsU5gwcoUSIVMeta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teamsU5gwcoUSIVMeta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsU5gwcoUSIVMeta || {},
    alias: empower_45your_45teamsU5gwcoUSIVMeta?.alias || [],
    redirect: empower_45your_45teamsU5gwcoUSIVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsU5gwcoUSIVMeta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teamsU5gwcoUSIVMeta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsU5gwcoUSIVMeta || {},
    alias: empower_45your_45teamsU5gwcoUSIVMeta?.alias || [],
    redirect: empower_45your_45teamsU5gwcoUSIVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsU5gwcoUSIVMeta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teamsU5gwcoUSIVMeta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsU5gwcoUSIVMeta || {},
    alias: empower_45your_45teamsU5gwcoUSIVMeta?.alias || [],
    redirect: empower_45your_45teamsU5gwcoUSIVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsU5gwcoUSIVMeta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teamsU5gwcoUSIVMeta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsU5gwcoUSIVMeta || {},
    alias: empower_45your_45teamsU5gwcoUSIVMeta?.alias || [],
    redirect: empower_45your_45teamsU5gwcoUSIVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsU5gwcoUSIVMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teamsU5gwcoUSIVMeta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsU5gwcoUSIVMeta || {},
    alias: empower_45your_45teamsU5gwcoUSIVMeta?.alias || [],
    redirect: empower_45your_45teamsU5gwcoUSIVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teamsU5gwcoUSIVMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teamsU5gwcoUSIVMeta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teamsU5gwcoUSIVMeta || {},
    alias: empower_45your_45teamsU5gwcoUSIVMeta?.alias || [],
    redirect: empower_45your_45teamsU5gwcoUSIVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: index1nf5P7ltKqMeta?.name ?? "guides-make-more-possible___en-US",
    path: index1nf5P7ltKqMeta?.path ?? "/guides/make-more-possible",
    meta: index1nf5P7ltKqMeta || {},
    alias: index1nf5P7ltKqMeta?.alias || [],
    redirect: index1nf5P7ltKqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: index1nf5P7ltKqMeta?.name ?? "guides-make-more-possible___ca",
    path: index1nf5P7ltKqMeta?.path ?? "/ca/guides/make-more-possible",
    meta: index1nf5P7ltKqMeta || {},
    alias: index1nf5P7ltKqMeta?.alias || [],
    redirect: index1nf5P7ltKqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: index1nf5P7ltKqMeta?.name ?? "guides-make-more-possible___nl",
    path: index1nf5P7ltKqMeta?.path ?? "/nl/guides/make-more-possible",
    meta: index1nf5P7ltKqMeta || {},
    alias: index1nf5P7ltKqMeta?.alias || [],
    redirect: index1nf5P7ltKqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: index1nf5P7ltKqMeta?.name ?? "guides-make-more-possible___uk",
    path: index1nf5P7ltKqMeta?.path ?? "/uk/guides/make-more-possible",
    meta: index1nf5P7ltKqMeta || {},
    alias: index1nf5P7ltKqMeta?.alias || [],
    redirect: index1nf5P7ltKqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: index1nf5P7ltKqMeta?.name ?? "guides-make-more-possible___de",
    path: index1nf5P7ltKqMeta?.path ?? "/de/guides/make-more-possible",
    meta: index1nf5P7ltKqMeta || {},
    alias: index1nf5P7ltKqMeta?.alias || [],
    redirect: index1nf5P7ltKqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: index1nf5P7ltKqMeta?.name ?? "guides-make-more-possible___mx",
    path: index1nf5P7ltKqMeta?.path ?? "/mx/guides/make-more-possible",
    meta: index1nf5P7ltKqMeta || {},
    alias: index1nf5P7ltKqMeta?.alias || [],
    redirect: index1nf5P7ltKqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: index1nf5P7ltKqMeta?.name ?? "guides-make-more-possible___fr",
    path: index1nf5P7ltKqMeta?.path ?? "/fr/guides/make-more-possible",
    meta: index1nf5P7ltKqMeta || {},
    alias: index1nf5P7ltKqMeta?.alias || [],
    redirect: index1nf5P7ltKqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: index1nf5P7ltKqMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: index1nf5P7ltKqMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: index1nf5P7ltKqMeta || {},
    alias: index1nf5P7ltKqMeta?.alias || [],
    redirect: index1nf5P7ltKqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataGaTlEOCQDhMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45dataGaTlEOCQDhMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataGaTlEOCQDhMeta || {},
    alias: take_45action_45with_45dataGaTlEOCQDhMeta?.alias || [],
    redirect: take_45action_45with_45dataGaTlEOCQDhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataGaTlEOCQDhMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45dataGaTlEOCQDhMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataGaTlEOCQDhMeta || {},
    alias: take_45action_45with_45dataGaTlEOCQDhMeta?.alias || [],
    redirect: take_45action_45with_45dataGaTlEOCQDhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataGaTlEOCQDhMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45dataGaTlEOCQDhMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataGaTlEOCQDhMeta || {},
    alias: take_45action_45with_45dataGaTlEOCQDhMeta?.alias || [],
    redirect: take_45action_45with_45dataGaTlEOCQDhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataGaTlEOCQDhMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45dataGaTlEOCQDhMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataGaTlEOCQDhMeta || {},
    alias: take_45action_45with_45dataGaTlEOCQDhMeta?.alias || [],
    redirect: take_45action_45with_45dataGaTlEOCQDhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataGaTlEOCQDhMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45dataGaTlEOCQDhMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataGaTlEOCQDhMeta || {},
    alias: take_45action_45with_45dataGaTlEOCQDhMeta?.alias || [],
    redirect: take_45action_45with_45dataGaTlEOCQDhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataGaTlEOCQDhMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45dataGaTlEOCQDhMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataGaTlEOCQDhMeta || {},
    alias: take_45action_45with_45dataGaTlEOCQDhMeta?.alias || [],
    redirect: take_45action_45with_45dataGaTlEOCQDhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataGaTlEOCQDhMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45dataGaTlEOCQDhMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataGaTlEOCQDhMeta || {},
    alias: take_45action_45with_45dataGaTlEOCQDhMeta?.alias || [],
    redirect: take_45action_45with_45dataGaTlEOCQDhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataGaTlEOCQDhMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45dataGaTlEOCQDhMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataGaTlEOCQDhMeta || {},
    alias: take_45action_45with_45dataGaTlEOCQDhMeta?.alias || [],
    redirect: take_45action_45with_45dataGaTlEOCQDhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lgm9eyhHhfMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93lgm9eyhHhfMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93lgm9eyhHhfMeta || {},
    alias: _91slug_93lgm9eyhHhfMeta?.alias || [],
    redirect: _91slug_93lgm9eyhHhfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lgm9eyhHhfMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93lgm9eyhHhfMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93lgm9eyhHhfMeta || {},
    alias: _91slug_93lgm9eyhHhfMeta?.alias || [],
    redirect: _91slug_93lgm9eyhHhfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lgm9eyhHhfMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93lgm9eyhHhfMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93lgm9eyhHhfMeta || {},
    alias: _91slug_93lgm9eyhHhfMeta?.alias || [],
    redirect: _91slug_93lgm9eyhHhfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lgm9eyhHhfMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93lgm9eyhHhfMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93lgm9eyhHhfMeta || {},
    alias: _91slug_93lgm9eyhHhfMeta?.alias || [],
    redirect: _91slug_93lgm9eyhHhfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lgm9eyhHhfMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93lgm9eyhHhfMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93lgm9eyhHhfMeta || {},
    alias: _91slug_93lgm9eyhHhfMeta?.alias || [],
    redirect: _91slug_93lgm9eyhHhfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lgm9eyhHhfMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93lgm9eyhHhfMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93lgm9eyhHhfMeta || {},
    alias: _91slug_93lgm9eyhHhfMeta?.alias || [],
    redirect: _91slug_93lgm9eyhHhfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lgm9eyhHhfMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93lgm9eyhHhfMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93lgm9eyhHhfMeta || {},
    alias: _91slug_93lgm9eyhHhfMeta?.alias || [],
    redirect: _91slug_93lgm9eyhHhfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lgm9eyhHhfMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93lgm9eyhHhfMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93lgm9eyhHhfMeta || {},
    alias: _91slug_93lgm9eyhHhfMeta?.alias || [],
    redirect: _91slug_93lgm9eyhHhfMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexe5R6dHYSRgMeta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: indexe5R6dHYSRgMeta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: indexe5R6dHYSRgMeta || {},
    alias: indexe5R6dHYSRgMeta?.alias || [],
    redirect: indexe5R6dHYSRgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexe5R6dHYSRgMeta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: indexe5R6dHYSRgMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: indexe5R6dHYSRgMeta || {},
    alias: indexe5R6dHYSRgMeta?.alias || [],
    redirect: indexe5R6dHYSRgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexe5R6dHYSRgMeta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: indexe5R6dHYSRgMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: indexe5R6dHYSRgMeta || {},
    alias: indexe5R6dHYSRgMeta?.alias || [],
    redirect: indexe5R6dHYSRgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexe5R6dHYSRgMeta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: indexe5R6dHYSRgMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: indexe5R6dHYSRgMeta || {},
    alias: indexe5R6dHYSRgMeta?.alias || [],
    redirect: indexe5R6dHYSRgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexe5R6dHYSRgMeta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: indexe5R6dHYSRgMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: indexe5R6dHYSRgMeta || {},
    alias: indexe5R6dHYSRgMeta?.alias || [],
    redirect: indexe5R6dHYSRgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexe5R6dHYSRgMeta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: indexe5R6dHYSRgMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: indexe5R6dHYSRgMeta || {},
    alias: indexe5R6dHYSRgMeta?.alias || [],
    redirect: indexe5R6dHYSRgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexe5R6dHYSRgMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: indexe5R6dHYSRgMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: indexe5R6dHYSRgMeta || {},
    alias: indexe5R6dHYSRgMeta?.alias || [],
    redirect: indexe5R6dHYSRgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexe5R6dHYSRgMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: indexe5R6dHYSRgMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: indexe5R6dHYSRgMeta || {},
    alias: indexe5R6dHYSRgMeta?.alias || [],
    redirect: indexe5R6dHYSRgMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexpaIotLc4P3Meta?.name ?? "index___en-US",
    path: indexpaIotLc4P3Meta?.path ?? "/",
    meta: indexpaIotLc4P3Meta || {},
    alias: indexpaIotLc4P3Meta?.alias || [],
    redirect: indexpaIotLc4P3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpaIotLc4P3Meta?.name ?? "index___ca",
    path: indexpaIotLc4P3Meta?.path ?? "/ca",
    meta: indexpaIotLc4P3Meta || {},
    alias: indexpaIotLc4P3Meta?.alias || [],
    redirect: indexpaIotLc4P3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpaIotLc4P3Meta?.name ?? "index___nl",
    path: indexpaIotLc4P3Meta?.path ?? "/nl",
    meta: indexpaIotLc4P3Meta || {},
    alias: indexpaIotLc4P3Meta?.alias || [],
    redirect: indexpaIotLc4P3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpaIotLc4P3Meta?.name ?? "index___uk",
    path: indexpaIotLc4P3Meta?.path ?? "/uk",
    meta: indexpaIotLc4P3Meta || {},
    alias: indexpaIotLc4P3Meta?.alias || [],
    redirect: indexpaIotLc4P3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpaIotLc4P3Meta?.name ?? "index___de",
    path: indexpaIotLc4P3Meta?.path ?? "/de",
    meta: indexpaIotLc4P3Meta || {},
    alias: indexpaIotLc4P3Meta?.alias || [],
    redirect: indexpaIotLc4P3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpaIotLc4P3Meta?.name ?? "index___mx",
    path: indexpaIotLc4P3Meta?.path ?? "/mx",
    meta: indexpaIotLc4P3Meta || {},
    alias: indexpaIotLc4P3Meta?.alias || [],
    redirect: indexpaIotLc4P3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpaIotLc4P3Meta?.name ?? "index___fr",
    path: indexpaIotLc4P3Meta?.path ?? "/fr",
    meta: indexpaIotLc4P3Meta || {},
    alias: indexpaIotLc4P3Meta?.alias || [],
    redirect: indexpaIotLc4P3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpaIotLc4P3Meta?.name ?? "index___fr-ca",
    path: indexpaIotLc4P3Meta?.path ?? "/fr-ca",
    meta: indexpaIotLc4P3Meta || {},
    alias: indexpaIotLc4P3Meta?.alias || [],
    redirect: indexpaIotLc4P3Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hCa8WA57lxMeta?.name ?? "industrial-slug___en-US",
    path: _91slug_93hCa8WA57lxMeta?.path ?? "/industrial/:slug()",
    meta: _91slug_93hCa8WA57lxMeta || {},
    alias: _91slug_93hCa8WA57lxMeta?.alias || [],
    redirect: _91slug_93hCa8WA57lxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hCa8WA57lxMeta?.name ?? "industrial-slug___ca",
    path: _91slug_93hCa8WA57lxMeta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_93hCa8WA57lxMeta || {},
    alias: _91slug_93hCa8WA57lxMeta?.alias || [],
    redirect: _91slug_93hCa8WA57lxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hCa8WA57lxMeta?.name ?? "industrial-slug___nl",
    path: _91slug_93hCa8WA57lxMeta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_93hCa8WA57lxMeta || {},
    alias: _91slug_93hCa8WA57lxMeta?.alias || [],
    redirect: _91slug_93hCa8WA57lxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hCa8WA57lxMeta?.name ?? "industrial-slug___uk",
    path: _91slug_93hCa8WA57lxMeta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_93hCa8WA57lxMeta || {},
    alias: _91slug_93hCa8WA57lxMeta?.alias || [],
    redirect: _91slug_93hCa8WA57lxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hCa8WA57lxMeta?.name ?? "industrial-slug___de",
    path: _91slug_93hCa8WA57lxMeta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_93hCa8WA57lxMeta || {},
    alias: _91slug_93hCa8WA57lxMeta?.alias || [],
    redirect: _91slug_93hCa8WA57lxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hCa8WA57lxMeta?.name ?? "industrial-slug___mx",
    path: _91slug_93hCa8WA57lxMeta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_93hCa8WA57lxMeta || {},
    alias: _91slug_93hCa8WA57lxMeta?.alias || [],
    redirect: _91slug_93hCa8WA57lxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hCa8WA57lxMeta?.name ?? "industrial-slug___fr",
    path: _91slug_93hCa8WA57lxMeta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_93hCa8WA57lxMeta || {},
    alias: _91slug_93hCa8WA57lxMeta?.alias || [],
    redirect: _91slug_93hCa8WA57lxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93hCa8WA57lxMeta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_93hCa8WA57lxMeta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_93hCa8WA57lxMeta || {},
    alias: _91slug_93hCa8WA57lxMeta?.alias || [],
    redirect: _91slug_93hCa8WA57lxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FNCcBmbNRxMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93FNCcBmbNRxMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93FNCcBmbNRxMeta || {},
    alias: _91slug_93FNCcBmbNRxMeta?.alias || [],
    redirect: _91slug_93FNCcBmbNRxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FNCcBmbNRxMeta?.name ?? "industries-slug___ca",
    path: _91slug_93FNCcBmbNRxMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93FNCcBmbNRxMeta || {},
    alias: _91slug_93FNCcBmbNRxMeta?.alias || [],
    redirect: _91slug_93FNCcBmbNRxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FNCcBmbNRxMeta?.name ?? "industries-slug___nl",
    path: _91slug_93FNCcBmbNRxMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93FNCcBmbNRxMeta || {},
    alias: _91slug_93FNCcBmbNRxMeta?.alias || [],
    redirect: _91slug_93FNCcBmbNRxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FNCcBmbNRxMeta?.name ?? "industries-slug___uk",
    path: _91slug_93FNCcBmbNRxMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93FNCcBmbNRxMeta || {},
    alias: _91slug_93FNCcBmbNRxMeta?.alias || [],
    redirect: _91slug_93FNCcBmbNRxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FNCcBmbNRxMeta?.name ?? "industries-slug___de",
    path: _91slug_93FNCcBmbNRxMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93FNCcBmbNRxMeta || {},
    alias: _91slug_93FNCcBmbNRxMeta?.alias || [],
    redirect: _91slug_93FNCcBmbNRxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FNCcBmbNRxMeta?.name ?? "industries-slug___mx",
    path: _91slug_93FNCcBmbNRxMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93FNCcBmbNRxMeta || {},
    alias: _91slug_93FNCcBmbNRxMeta?.alias || [],
    redirect: _91slug_93FNCcBmbNRxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FNCcBmbNRxMeta?.name ?? "industries-slug___fr",
    path: _91slug_93FNCcBmbNRxMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93FNCcBmbNRxMeta || {},
    alias: _91slug_93FNCcBmbNRxMeta?.alias || [],
    redirect: _91slug_93FNCcBmbNRxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FNCcBmbNRxMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93FNCcBmbNRxMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93FNCcBmbNRxMeta || {},
    alias: _91slug_93FNCcBmbNRxMeta?.alias || [],
    redirect: _91slug_93FNCcBmbNRxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexfC14CKilboMeta?.name ?? "industries___en-US",
    path: indexfC14CKilboMeta?.path ?? "/industries",
    meta: indexfC14CKilboMeta || {},
    alias: indexfC14CKilboMeta?.alias || [],
    redirect: indexfC14CKilboMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexfC14CKilboMeta?.name ?? "industries___ca",
    path: indexfC14CKilboMeta?.path ?? "/ca/industries",
    meta: indexfC14CKilboMeta || {},
    alias: indexfC14CKilboMeta?.alias || [],
    redirect: indexfC14CKilboMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexfC14CKilboMeta?.name ?? "industries___nl",
    path: indexfC14CKilboMeta?.path ?? "/nl/industries",
    meta: indexfC14CKilboMeta || {},
    alias: indexfC14CKilboMeta?.alias || [],
    redirect: indexfC14CKilboMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexfC14CKilboMeta?.name ?? "industries___uk",
    path: indexfC14CKilboMeta?.path ?? "/uk/industries",
    meta: indexfC14CKilboMeta || {},
    alias: indexfC14CKilboMeta?.alias || [],
    redirect: indexfC14CKilboMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexfC14CKilboMeta?.name ?? "industries___de",
    path: indexfC14CKilboMeta?.path ?? "/de/industries",
    meta: indexfC14CKilboMeta || {},
    alias: indexfC14CKilboMeta?.alias || [],
    redirect: indexfC14CKilboMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexfC14CKilboMeta?.name ?? "industries___mx",
    path: indexfC14CKilboMeta?.path ?? "/mx/industries",
    meta: indexfC14CKilboMeta || {},
    alias: indexfC14CKilboMeta?.alias || [],
    redirect: indexfC14CKilboMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexfC14CKilboMeta?.name ?? "industries___fr",
    path: indexfC14CKilboMeta?.path ?? "/fr/industries",
    meta: indexfC14CKilboMeta || {},
    alias: indexfC14CKilboMeta?.alias || [],
    redirect: indexfC14CKilboMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexfC14CKilboMeta?.name ?? "industries___fr-ca",
    path: indexfC14CKilboMeta?.path ?? "/fr-ca/industries",
    meta: indexfC14CKilboMeta || {},
    alias: indexfC14CKilboMeta?.alias || [],
    redirect: indexfC14CKilboMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: citiessbwXB45ZnuMeta?.name ?? "insights-cities___en-US",
    path: citiessbwXB45ZnuMeta?.path ?? "/insights/cities",
    meta: citiessbwXB45ZnuMeta || {},
    alias: citiessbwXB45ZnuMeta?.alias || [],
    redirect: citiessbwXB45ZnuMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiessbwXB45ZnuMeta?.name ?? "insights-cities___ca",
    path: citiessbwXB45ZnuMeta?.path ?? "/ca/insights/cities",
    meta: citiessbwXB45ZnuMeta || {},
    alias: citiessbwXB45ZnuMeta?.alias || [],
    redirect: citiessbwXB45ZnuMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiessbwXB45ZnuMeta?.name ?? "insights-cities___nl",
    path: citiessbwXB45ZnuMeta?.path ?? "/nl/insights/cities",
    meta: citiessbwXB45ZnuMeta || {},
    alias: citiessbwXB45ZnuMeta?.alias || [],
    redirect: citiessbwXB45ZnuMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiessbwXB45ZnuMeta?.name ?? "insights-cities___uk",
    path: citiessbwXB45ZnuMeta?.path ?? "/uk/insights/cities",
    meta: citiessbwXB45ZnuMeta || {},
    alias: citiessbwXB45ZnuMeta?.alias || [],
    redirect: citiessbwXB45ZnuMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiessbwXB45ZnuMeta?.name ?? "insights-cities___de",
    path: citiessbwXB45ZnuMeta?.path ?? "/de/insights/cities",
    meta: citiessbwXB45ZnuMeta || {},
    alias: citiessbwXB45ZnuMeta?.alias || [],
    redirect: citiessbwXB45ZnuMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiessbwXB45ZnuMeta?.name ?? "insights-cities___mx",
    path: citiessbwXB45ZnuMeta?.path ?? "/mx/insights/cities",
    meta: citiessbwXB45ZnuMeta || {},
    alias: citiessbwXB45ZnuMeta?.alias || [],
    redirect: citiessbwXB45ZnuMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiessbwXB45ZnuMeta?.name ?? "insights-cities___fr",
    path: citiessbwXB45ZnuMeta?.path ?? "/fr/insights/cities",
    meta: citiessbwXB45ZnuMeta || {},
    alias: citiessbwXB45ZnuMeta?.alias || [],
    redirect: citiessbwXB45ZnuMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiessbwXB45ZnuMeta?.name ?? "insights-cities___fr-ca",
    path: citiessbwXB45ZnuMeta?.path ?? "/fr-ca/insights/cities",
    meta: citiessbwXB45ZnuMeta || {},
    alias: citiessbwXB45ZnuMeta?.alias || [],
    redirect: citiessbwXB45ZnuMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: indexCABvDyXRLZMeta?.name ?? "integrations___en-US",
    path: indexCABvDyXRLZMeta?.path ?? "/integrations",
    meta: indexCABvDyXRLZMeta || {},
    alias: indexCABvDyXRLZMeta?.alias || [],
    redirect: indexCABvDyXRLZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexCABvDyXRLZMeta?.name ?? "integrations___ca",
    path: indexCABvDyXRLZMeta?.path ?? "/ca/integrations",
    meta: indexCABvDyXRLZMeta || {},
    alias: indexCABvDyXRLZMeta?.alias || [],
    redirect: indexCABvDyXRLZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexCABvDyXRLZMeta?.name ?? "integrations___nl",
    path: indexCABvDyXRLZMeta?.path ?? "/nl/integrations",
    meta: indexCABvDyXRLZMeta || {},
    alias: indexCABvDyXRLZMeta?.alias || [],
    redirect: indexCABvDyXRLZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexCABvDyXRLZMeta?.name ?? "integrations___uk",
    path: indexCABvDyXRLZMeta?.path ?? "/uk/integrations",
    meta: indexCABvDyXRLZMeta || {},
    alias: indexCABvDyXRLZMeta?.alias || [],
    redirect: indexCABvDyXRLZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexCABvDyXRLZMeta?.name ?? "integrations___de",
    path: indexCABvDyXRLZMeta?.path ?? "/de/integrations",
    meta: indexCABvDyXRLZMeta || {},
    alias: indexCABvDyXRLZMeta?.alias || [],
    redirect: indexCABvDyXRLZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexCABvDyXRLZMeta?.name ?? "integrations___mx",
    path: indexCABvDyXRLZMeta?.path ?? "/mx/integrations",
    meta: indexCABvDyXRLZMeta || {},
    alias: indexCABvDyXRLZMeta?.alias || [],
    redirect: indexCABvDyXRLZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexCABvDyXRLZMeta?.name ?? "integrations___fr",
    path: indexCABvDyXRLZMeta?.path ?? "/fr/integrations",
    meta: indexCABvDyXRLZMeta || {},
    alias: indexCABvDyXRLZMeta?.alias || [],
    redirect: indexCABvDyXRLZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexCABvDyXRLZMeta?.name ?? "integrations___fr-ca",
    path: indexCABvDyXRLZMeta?.path ?? "/fr-ca/integrations",
    meta: indexCABvDyXRLZMeta || {},
    alias: indexCABvDyXRLZMeta?.alias || [],
    redirect: indexCABvDyXRLZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: indexFhR6aylbtVMeta?.name ?? "invite___en-US",
    path: indexFhR6aylbtVMeta?.path ?? "/invite",
    meta: indexFhR6aylbtVMeta || {},
    alias: indexFhR6aylbtVMeta?.alias || [],
    redirect: indexFhR6aylbtVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexFhR6aylbtVMeta?.name ?? "invite___ca",
    path: indexFhR6aylbtVMeta?.path ?? "/ca/invite",
    meta: indexFhR6aylbtVMeta || {},
    alias: indexFhR6aylbtVMeta?.alias || [],
    redirect: indexFhR6aylbtVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexFhR6aylbtVMeta?.name ?? "invite___nl",
    path: indexFhR6aylbtVMeta?.path ?? "/nl/invite",
    meta: indexFhR6aylbtVMeta || {},
    alias: indexFhR6aylbtVMeta?.alias || [],
    redirect: indexFhR6aylbtVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexFhR6aylbtVMeta?.name ?? "invite___uk",
    path: indexFhR6aylbtVMeta?.path ?? "/uk/invite",
    meta: indexFhR6aylbtVMeta || {},
    alias: indexFhR6aylbtVMeta?.alias || [],
    redirect: indexFhR6aylbtVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexFhR6aylbtVMeta?.name ?? "invite___de",
    path: indexFhR6aylbtVMeta?.path ?? "/de/invite",
    meta: indexFhR6aylbtVMeta || {},
    alias: indexFhR6aylbtVMeta?.alias || [],
    redirect: indexFhR6aylbtVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexFhR6aylbtVMeta?.name ?? "invite___mx",
    path: indexFhR6aylbtVMeta?.path ?? "/mx/invite",
    meta: indexFhR6aylbtVMeta || {},
    alias: indexFhR6aylbtVMeta?.alias || [],
    redirect: indexFhR6aylbtVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexFhR6aylbtVMeta?.name ?? "invite___fr",
    path: indexFhR6aylbtVMeta?.path ?? "/fr/invite",
    meta: indexFhR6aylbtVMeta || {},
    alias: indexFhR6aylbtVMeta?.alias || [],
    redirect: indexFhR6aylbtVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: indexFhR6aylbtVMeta?.name ?? "invite___fr-ca",
    path: indexFhR6aylbtVMeta?.path ?? "/fr-ca/invite",
    meta: indexFhR6aylbtVMeta || {},
    alias: indexFhR6aylbtVMeta?.alias || [],
    redirect: indexFhR6aylbtVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticecZ4rqKACP6Meta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45noticecZ4rqKACP6Meta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticecZ4rqKACP6Meta || {},
    alias: candidate_45privacy_45noticecZ4rqKACP6Meta?.alias || [],
    redirect: candidate_45privacy_45noticecZ4rqKACP6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticecZ4rqKACP6Meta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45noticecZ4rqKACP6Meta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticecZ4rqKACP6Meta || {},
    alias: candidate_45privacy_45noticecZ4rqKACP6Meta?.alias || [],
    redirect: candidate_45privacy_45noticecZ4rqKACP6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticecZ4rqKACP6Meta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45noticecZ4rqKACP6Meta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticecZ4rqKACP6Meta || {},
    alias: candidate_45privacy_45noticecZ4rqKACP6Meta?.alias || [],
    redirect: candidate_45privacy_45noticecZ4rqKACP6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticecZ4rqKACP6Meta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45noticecZ4rqKACP6Meta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticecZ4rqKACP6Meta || {},
    alias: candidate_45privacy_45noticecZ4rqKACP6Meta?.alias || [],
    redirect: candidate_45privacy_45noticecZ4rqKACP6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticecZ4rqKACP6Meta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45noticecZ4rqKACP6Meta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticecZ4rqKACP6Meta || {},
    alias: candidate_45privacy_45noticecZ4rqKACP6Meta?.alias || [],
    redirect: candidate_45privacy_45noticecZ4rqKACP6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticecZ4rqKACP6Meta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45noticecZ4rqKACP6Meta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticecZ4rqKACP6Meta || {},
    alias: candidate_45privacy_45noticecZ4rqKACP6Meta?.alias || [],
    redirect: candidate_45privacy_45noticecZ4rqKACP6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticecZ4rqKACP6Meta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45noticecZ4rqKACP6Meta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticecZ4rqKACP6Meta || {},
    alias: candidate_45privacy_45noticecZ4rqKACP6Meta?.alias || [],
    redirect: candidate_45privacy_45noticecZ4rqKACP6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticecZ4rqKACP6Meta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45noticecZ4rqKACP6Meta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticecZ4rqKACP6Meta || {},
    alias: candidate_45privacy_45noticecZ4rqKACP6Meta?.alias || [],
    redirect: candidate_45privacy_45noticecZ4rqKACP6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentg3B76sWBqQMeta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consentg3B76sWBqQMeta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consentg3B76sWBqQMeta || {},
    alias: cookie_45consentg3B76sWBqQMeta?.alias || [],
    redirect: cookie_45consentg3B76sWBqQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentg3B76sWBqQMeta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consentg3B76sWBqQMeta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consentg3B76sWBqQMeta || {},
    alias: cookie_45consentg3B76sWBqQMeta?.alias || [],
    redirect: cookie_45consentg3B76sWBqQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentg3B76sWBqQMeta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consentg3B76sWBqQMeta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consentg3B76sWBqQMeta || {},
    alias: cookie_45consentg3B76sWBqQMeta?.alias || [],
    redirect: cookie_45consentg3B76sWBqQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentg3B76sWBqQMeta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consentg3B76sWBqQMeta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consentg3B76sWBqQMeta || {},
    alias: cookie_45consentg3B76sWBqQMeta?.alias || [],
    redirect: cookie_45consentg3B76sWBqQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentg3B76sWBqQMeta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consentg3B76sWBqQMeta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consentg3B76sWBqQMeta || {},
    alias: cookie_45consentg3B76sWBqQMeta?.alias || [],
    redirect: cookie_45consentg3B76sWBqQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentg3B76sWBqQMeta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consentg3B76sWBqQMeta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consentg3B76sWBqQMeta || {},
    alias: cookie_45consentg3B76sWBqQMeta?.alias || [],
    redirect: cookie_45consentg3B76sWBqQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentg3B76sWBqQMeta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consentg3B76sWBqQMeta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consentg3B76sWBqQMeta || {},
    alias: cookie_45consentg3B76sWBqQMeta?.alias || [],
    redirect: cookie_45consentg3B76sWBqQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentg3B76sWBqQMeta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consentg3B76sWBqQMeta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consentg3B76sWBqQMeta || {},
    alias: cookie_45consentg3B76sWBqQMeta?.alias || [],
    redirect: cookie_45consentg3B76sWBqQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKLdBKHMcnKMeta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policyKLdBKHMcnKMeta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policyKLdBKHMcnKMeta || {},
    alias: cookie_45policyKLdBKHMcnKMeta?.alias || [],
    redirect: cookie_45policyKLdBKHMcnKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKLdBKHMcnKMeta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policyKLdBKHMcnKMeta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policyKLdBKHMcnKMeta || {},
    alias: cookie_45policyKLdBKHMcnKMeta?.alias || [],
    redirect: cookie_45policyKLdBKHMcnKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKLdBKHMcnKMeta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policyKLdBKHMcnKMeta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policyKLdBKHMcnKMeta || {},
    alias: cookie_45policyKLdBKHMcnKMeta?.alias || [],
    redirect: cookie_45policyKLdBKHMcnKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKLdBKHMcnKMeta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policyKLdBKHMcnKMeta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policyKLdBKHMcnKMeta || {},
    alias: cookie_45policyKLdBKHMcnKMeta?.alias || [],
    redirect: cookie_45policyKLdBKHMcnKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKLdBKHMcnKMeta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policyKLdBKHMcnKMeta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policyKLdBKHMcnKMeta || {},
    alias: cookie_45policyKLdBKHMcnKMeta?.alias || [],
    redirect: cookie_45policyKLdBKHMcnKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKLdBKHMcnKMeta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policyKLdBKHMcnKMeta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policyKLdBKHMcnKMeta || {},
    alias: cookie_45policyKLdBKHMcnKMeta?.alias || [],
    redirect: cookie_45policyKLdBKHMcnKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKLdBKHMcnKMeta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policyKLdBKHMcnKMeta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policyKLdBKHMcnKMeta || {},
    alias: cookie_45policyKLdBKHMcnKMeta?.alias || [],
    redirect: cookie_45policyKLdBKHMcnKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyKLdBKHMcnKMeta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policyKLdBKHMcnKMeta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policyKLdBKHMcnKMeta || {},
    alias: cookie_45policyKLdBKHMcnKMeta?.alias || [],
    redirect: cookie_45policyKLdBKHMcnKMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumokjRXGKIP5Meta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addendumokjRXGKIP5Meta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addendumokjRXGKIP5Meta || {},
    alias: data_45protection_45addendumokjRXGKIP5Meta?.alias || [],
    redirect: data_45protection_45addendumokjRXGKIP5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumokjRXGKIP5Meta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addendumokjRXGKIP5Meta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumokjRXGKIP5Meta || {},
    alias: data_45protection_45addendumokjRXGKIP5Meta?.alias || [],
    redirect: data_45protection_45addendumokjRXGKIP5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumokjRXGKIP5Meta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addendumokjRXGKIP5Meta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addendumokjRXGKIP5Meta || {},
    alias: data_45protection_45addendumokjRXGKIP5Meta?.alias || [],
    redirect: data_45protection_45addendumokjRXGKIP5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumokjRXGKIP5Meta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addendumokjRXGKIP5Meta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addendumokjRXGKIP5Meta || {},
    alias: data_45protection_45addendumokjRXGKIP5Meta?.alias || [],
    redirect: data_45protection_45addendumokjRXGKIP5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumokjRXGKIP5Meta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addendumokjRXGKIP5Meta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addendumokjRXGKIP5Meta || {},
    alias: data_45protection_45addendumokjRXGKIP5Meta?.alias || [],
    redirect: data_45protection_45addendumokjRXGKIP5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumokjRXGKIP5Meta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addendumokjRXGKIP5Meta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addendumokjRXGKIP5Meta || {},
    alias: data_45protection_45addendumokjRXGKIP5Meta?.alias || [],
    redirect: data_45protection_45addendumokjRXGKIP5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumokjRXGKIP5Meta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addendumokjRXGKIP5Meta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addendumokjRXGKIP5Meta || {},
    alias: data_45protection_45addendumokjRXGKIP5Meta?.alias || [],
    redirect: data_45protection_45addendumokjRXGKIP5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumokjRXGKIP5Meta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addendumokjRXGKIP5Meta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumokjRXGKIP5Meta || {},
    alias: data_45protection_45addendumokjRXGKIP5Meta?.alias || [],
    redirect: data_45protection_45addendumokjRXGKIP5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45terms69tuJrR83SMeta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45terms69tuJrR83SMeta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45terms69tuJrR83SMeta || {},
    alias: express_45order_45terms69tuJrR83SMeta?.alias || [],
    redirect: express_45order_45terms69tuJrR83SMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45terms69tuJrR83SMeta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45terms69tuJrR83SMeta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45terms69tuJrR83SMeta || {},
    alias: express_45order_45terms69tuJrR83SMeta?.alias || [],
    redirect: express_45order_45terms69tuJrR83SMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45terms69tuJrR83SMeta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45terms69tuJrR83SMeta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45terms69tuJrR83SMeta || {},
    alias: express_45order_45terms69tuJrR83SMeta?.alias || [],
    redirect: express_45order_45terms69tuJrR83SMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45terms69tuJrR83SMeta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45terms69tuJrR83SMeta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45terms69tuJrR83SMeta || {},
    alias: express_45order_45terms69tuJrR83SMeta?.alias || [],
    redirect: express_45order_45terms69tuJrR83SMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45terms69tuJrR83SMeta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45terms69tuJrR83SMeta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45terms69tuJrR83SMeta || {},
    alias: express_45order_45terms69tuJrR83SMeta?.alias || [],
    redirect: express_45order_45terms69tuJrR83SMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45terms69tuJrR83SMeta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45terms69tuJrR83SMeta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45terms69tuJrR83SMeta || {},
    alias: express_45order_45terms69tuJrR83SMeta?.alias || [],
    redirect: express_45order_45terms69tuJrR83SMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45terms69tuJrR83SMeta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45terms69tuJrR83SMeta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45terms69tuJrR83SMeta || {},
    alias: express_45order_45terms69tuJrR83SMeta?.alias || [],
    redirect: express_45order_45terms69tuJrR83SMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45terms69tuJrR83SMeta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45terms69tuJrR83SMeta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45terms69tuJrR83SMeta || {},
    alias: express_45order_45terms69tuJrR83SMeta?.alias || [],
    redirect: express_45order_45terms69tuJrR83SMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyozBwD0bNRPMeta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warrantyozBwD0bNRPMeta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warrantyozBwD0bNRPMeta || {},
    alias: hardware_45warrantyozBwD0bNRPMeta?.alias || [],
    redirect: hardware_45warrantyozBwD0bNRPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyozBwD0bNRPMeta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warrantyozBwD0bNRPMeta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warrantyozBwD0bNRPMeta || {},
    alias: hardware_45warrantyozBwD0bNRPMeta?.alias || [],
    redirect: hardware_45warrantyozBwD0bNRPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyozBwD0bNRPMeta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warrantyozBwD0bNRPMeta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warrantyozBwD0bNRPMeta || {},
    alias: hardware_45warrantyozBwD0bNRPMeta?.alias || [],
    redirect: hardware_45warrantyozBwD0bNRPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyozBwD0bNRPMeta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warrantyozBwD0bNRPMeta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warrantyozBwD0bNRPMeta || {},
    alias: hardware_45warrantyozBwD0bNRPMeta?.alias || [],
    redirect: hardware_45warrantyozBwD0bNRPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyozBwD0bNRPMeta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warrantyozBwD0bNRPMeta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warrantyozBwD0bNRPMeta || {},
    alias: hardware_45warrantyozBwD0bNRPMeta?.alias || [],
    redirect: hardware_45warrantyozBwD0bNRPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyozBwD0bNRPMeta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warrantyozBwD0bNRPMeta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warrantyozBwD0bNRPMeta || {},
    alias: hardware_45warrantyozBwD0bNRPMeta?.alias || [],
    redirect: hardware_45warrantyozBwD0bNRPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyozBwD0bNRPMeta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warrantyozBwD0bNRPMeta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warrantyozBwD0bNRPMeta || {},
    alias: hardware_45warrantyozBwD0bNRPMeta?.alias || [],
    redirect: hardware_45warrantyozBwD0bNRPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyozBwD0bNRPMeta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warrantyozBwD0bNRPMeta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warrantyozBwD0bNRPMeta || {},
    alias: hardware_45warrantyozBwD0bNRPMeta?.alias || [],
    redirect: hardware_45warrantyozBwD0bNRPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla2QsoCVK2AzMeta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45sla2QsoCVK2AzMeta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45sla2QsoCVK2AzMeta || {},
    alias: hosted_45software_45sla2QsoCVK2AzMeta?.alias || [],
    redirect: hosted_45software_45sla2QsoCVK2AzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla2QsoCVK2AzMeta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45sla2QsoCVK2AzMeta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45sla2QsoCVK2AzMeta || {},
    alias: hosted_45software_45sla2QsoCVK2AzMeta?.alias || [],
    redirect: hosted_45software_45sla2QsoCVK2AzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla2QsoCVK2AzMeta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45sla2QsoCVK2AzMeta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45sla2QsoCVK2AzMeta || {},
    alias: hosted_45software_45sla2QsoCVK2AzMeta?.alias || [],
    redirect: hosted_45software_45sla2QsoCVK2AzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla2QsoCVK2AzMeta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45sla2QsoCVK2AzMeta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45sla2QsoCVK2AzMeta || {},
    alias: hosted_45software_45sla2QsoCVK2AzMeta?.alias || [],
    redirect: hosted_45software_45sla2QsoCVK2AzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla2QsoCVK2AzMeta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45sla2QsoCVK2AzMeta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45sla2QsoCVK2AzMeta || {},
    alias: hosted_45software_45sla2QsoCVK2AzMeta?.alias || [],
    redirect: hosted_45software_45sla2QsoCVK2AzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla2QsoCVK2AzMeta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45sla2QsoCVK2AzMeta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45sla2QsoCVK2AzMeta || {},
    alias: hosted_45software_45sla2QsoCVK2AzMeta?.alias || [],
    redirect: hosted_45software_45sla2QsoCVK2AzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla2QsoCVK2AzMeta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45sla2QsoCVK2AzMeta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45sla2QsoCVK2AzMeta || {},
    alias: hosted_45software_45sla2QsoCVK2AzMeta?.alias || [],
    redirect: hosted_45software_45sla2QsoCVK2AzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45sla2QsoCVK2AzMeta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45sla2QsoCVK2AzMeta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45sla2QsoCVK2AzMeta || {},
    alias: hosted_45software_45sla2QsoCVK2AzMeta?.alias || [],
    redirect: hosted_45software_45sla2QsoCVK2AzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductqaYX0hsu54Meta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conductqaYX0hsu54Meta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductqaYX0hsu54Meta || {},
    alias: partner_45code_45of_45conductqaYX0hsu54Meta?.alias || [],
    redirect: partner_45code_45of_45conductqaYX0hsu54Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductqaYX0hsu54Meta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conductqaYX0hsu54Meta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductqaYX0hsu54Meta || {},
    alias: partner_45code_45of_45conductqaYX0hsu54Meta?.alias || [],
    redirect: partner_45code_45of_45conductqaYX0hsu54Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductqaYX0hsu54Meta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conductqaYX0hsu54Meta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductqaYX0hsu54Meta || {},
    alias: partner_45code_45of_45conductqaYX0hsu54Meta?.alias || [],
    redirect: partner_45code_45of_45conductqaYX0hsu54Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductqaYX0hsu54Meta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conductqaYX0hsu54Meta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductqaYX0hsu54Meta || {},
    alias: partner_45code_45of_45conductqaYX0hsu54Meta?.alias || [],
    redirect: partner_45code_45of_45conductqaYX0hsu54Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductqaYX0hsu54Meta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conductqaYX0hsu54Meta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductqaYX0hsu54Meta || {},
    alias: partner_45code_45of_45conductqaYX0hsu54Meta?.alias || [],
    redirect: partner_45code_45of_45conductqaYX0hsu54Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductqaYX0hsu54Meta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conductqaYX0hsu54Meta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductqaYX0hsu54Meta || {},
    alias: partner_45code_45of_45conductqaYX0hsu54Meta?.alias || [],
    redirect: partner_45code_45of_45conductqaYX0hsu54Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductqaYX0hsu54Meta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conductqaYX0hsu54Meta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductqaYX0hsu54Meta || {},
    alias: partner_45code_45of_45conductqaYX0hsu54Meta?.alias || [],
    redirect: partner_45code_45of_45conductqaYX0hsu54Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductqaYX0hsu54Meta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conductqaYX0hsu54Meta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductqaYX0hsu54Meta || {},
    alias: partner_45code_45of_45conductqaYX0hsu54Meta?.alias || [],
    redirect: partner_45code_45of_45conductqaYX0hsu54Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEGnIs8EVcFMeta || {},
    alias: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEGnIs8EVcFMeta || {},
    alias: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEGnIs8EVcFMeta || {},
    alias: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEGnIs8EVcFMeta || {},
    alias: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEGnIs8EVcFMeta || {},
    alias: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEGnIs8EVcFMeta || {},
    alias: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEGnIs8EVcFMeta || {},
    alias: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45serviceEGnIs8EVcFMeta || {},
    alias: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.alias || [],
    redirect: platform_45terms_45of_45serviceEGnIs8EVcFMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacyF6KlK2iQLqMeta?.name ?? "legal-privacy___en-US",
    path: privacyF6KlK2iQLqMeta?.path ?? "/legal/privacy",
    meta: privacyF6KlK2iQLqMeta || {},
    alias: privacyF6KlK2iQLqMeta?.alias || [],
    redirect: privacyF6KlK2iQLqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyF6KlK2iQLqMeta?.name ?? "legal-privacy___ca",
    path: privacyF6KlK2iQLqMeta?.path ?? "/ca/legal/privacy",
    meta: privacyF6KlK2iQLqMeta || {},
    alias: privacyF6KlK2iQLqMeta?.alias || [],
    redirect: privacyF6KlK2iQLqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyF6KlK2iQLqMeta?.name ?? "legal-privacy___nl",
    path: privacyF6KlK2iQLqMeta?.path ?? "/nl/legal/privacy",
    meta: privacyF6KlK2iQLqMeta || {},
    alias: privacyF6KlK2iQLqMeta?.alias || [],
    redirect: privacyF6KlK2iQLqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyF6KlK2iQLqMeta?.name ?? "legal-privacy___uk",
    path: privacyF6KlK2iQLqMeta?.path ?? "/uk/legal/privacy",
    meta: privacyF6KlK2iQLqMeta || {},
    alias: privacyF6KlK2iQLqMeta?.alias || [],
    redirect: privacyF6KlK2iQLqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyF6KlK2iQLqMeta?.name ?? "legal-privacy___de",
    path: privacyF6KlK2iQLqMeta?.path ?? "/de/legal/privacy",
    meta: privacyF6KlK2iQLqMeta || {},
    alias: privacyF6KlK2iQLqMeta?.alias || [],
    redirect: privacyF6KlK2iQLqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyF6KlK2iQLqMeta?.name ?? "legal-privacy___mx",
    path: privacyF6KlK2iQLqMeta?.path ?? "/mx/legal/privacy",
    meta: privacyF6KlK2iQLqMeta || {},
    alias: privacyF6KlK2iQLqMeta?.alias || [],
    redirect: privacyF6KlK2iQLqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyF6KlK2iQLqMeta?.name ?? "legal-privacy___fr",
    path: privacyF6KlK2iQLqMeta?.path ?? "/fr/legal/privacy",
    meta: privacyF6KlK2iQLqMeta || {},
    alias: privacyF6KlK2iQLqMeta?.alias || [],
    redirect: privacyF6KlK2iQLqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyF6KlK2iQLqMeta?.name ?? "legal-privacy___fr-ca",
    path: privacyF6KlK2iQLqMeta?.path ?? "/fr-ca/legal/privacy",
    meta: privacyF6KlK2iQLqMeta || {},
    alias: privacyF6KlK2iQLqMeta?.alias || [],
    redirect: privacyF6KlK2iQLqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45service38je9N3BoBMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataIELvq6b1iDMeta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdataIELvq6b1iDMeta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdataIELvq6b1iDMeta || {},
    alias: requestingcustomerdataIELvq6b1iDMeta?.alias || [],
    redirect: requestingcustomerdataIELvq6b1iDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataIELvq6b1iDMeta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdataIELvq6b1iDMeta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataIELvq6b1iDMeta || {},
    alias: requestingcustomerdataIELvq6b1iDMeta?.alias || [],
    redirect: requestingcustomerdataIELvq6b1iDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataIELvq6b1iDMeta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdataIELvq6b1iDMeta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdataIELvq6b1iDMeta || {},
    alias: requestingcustomerdataIELvq6b1iDMeta?.alias || [],
    redirect: requestingcustomerdataIELvq6b1iDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataIELvq6b1iDMeta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdataIELvq6b1iDMeta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdataIELvq6b1iDMeta || {},
    alias: requestingcustomerdataIELvq6b1iDMeta?.alias || [],
    redirect: requestingcustomerdataIELvq6b1iDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataIELvq6b1iDMeta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdataIELvq6b1iDMeta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdataIELvq6b1iDMeta || {},
    alias: requestingcustomerdataIELvq6b1iDMeta?.alias || [],
    redirect: requestingcustomerdataIELvq6b1iDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataIELvq6b1iDMeta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdataIELvq6b1iDMeta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdataIELvq6b1iDMeta || {},
    alias: requestingcustomerdataIELvq6b1iDMeta?.alias || [],
    redirect: requestingcustomerdataIELvq6b1iDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataIELvq6b1iDMeta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdataIELvq6b1iDMeta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdataIELvq6b1iDMeta || {},
    alias: requestingcustomerdataIELvq6b1iDMeta?.alias || [],
    redirect: requestingcustomerdataIELvq6b1iDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataIELvq6b1iDMeta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdataIELvq6b1iDMeta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataIELvq6b1iDMeta || {},
    alias: requestingcustomerdataIELvq6b1iDMeta?.alias || [],
    redirect: requestingcustomerdataIELvq6b1iDMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: securityxiW78NN4VXMeta?.name ?? "legal-security___en-US",
    path: securityxiW78NN4VXMeta?.path ?? "/legal/security",
    meta: securityxiW78NN4VXMeta || {},
    alias: securityxiW78NN4VXMeta?.alias || [],
    redirect: securityxiW78NN4VXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityxiW78NN4VXMeta?.name ?? "legal-security___ca",
    path: securityxiW78NN4VXMeta?.path ?? "/ca/legal/security",
    meta: securityxiW78NN4VXMeta || {},
    alias: securityxiW78NN4VXMeta?.alias || [],
    redirect: securityxiW78NN4VXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityxiW78NN4VXMeta?.name ?? "legal-security___nl",
    path: securityxiW78NN4VXMeta?.path ?? "/nl/legal/security",
    meta: securityxiW78NN4VXMeta || {},
    alias: securityxiW78NN4VXMeta?.alias || [],
    redirect: securityxiW78NN4VXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityxiW78NN4VXMeta?.name ?? "legal-security___uk",
    path: securityxiW78NN4VXMeta?.path ?? "/uk/legal/security",
    meta: securityxiW78NN4VXMeta || {},
    alias: securityxiW78NN4VXMeta?.alias || [],
    redirect: securityxiW78NN4VXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityxiW78NN4VXMeta?.name ?? "legal-security___de",
    path: securityxiW78NN4VXMeta?.path ?? "/de/legal/security",
    meta: securityxiW78NN4VXMeta || {},
    alias: securityxiW78NN4VXMeta?.alias || [],
    redirect: securityxiW78NN4VXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityxiW78NN4VXMeta?.name ?? "legal-security___mx",
    path: securityxiW78NN4VXMeta?.path ?? "/mx/legal/security",
    meta: securityxiW78NN4VXMeta || {},
    alias: securityxiW78NN4VXMeta?.alias || [],
    redirect: securityxiW78NN4VXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityxiW78NN4VXMeta?.name ?? "legal-security___fr",
    path: securityxiW78NN4VXMeta?.path ?? "/fr/legal/security",
    meta: securityxiW78NN4VXMeta || {},
    alias: securityxiW78NN4VXMeta?.alias || [],
    redirect: securityxiW78NN4VXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityxiW78NN4VXMeta?.name ?? "legal-security___fr-ca",
    path: securityxiW78NN4VXMeta?.path ?? "/fr-ca/legal/security",
    meta: securityxiW78NN4VXMeta || {},
    alias: securityxiW78NN4VXMeta?.alias || [],
    redirect: securityxiW78NN4VXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporttQ7OgIAAf9Meta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreporttQ7OgIAAf9Meta?.path ?? "/legal/transparencyreport",
    meta: transparencyreporttQ7OgIAAf9Meta || {},
    alias: transparencyreporttQ7OgIAAf9Meta?.alias || [],
    redirect: transparencyreporttQ7OgIAAf9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporttQ7OgIAAf9Meta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreporttQ7OgIAAf9Meta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreporttQ7OgIAAf9Meta || {},
    alias: transparencyreporttQ7OgIAAf9Meta?.alias || [],
    redirect: transparencyreporttQ7OgIAAf9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporttQ7OgIAAf9Meta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreporttQ7OgIAAf9Meta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreporttQ7OgIAAf9Meta || {},
    alias: transparencyreporttQ7OgIAAf9Meta?.alias || [],
    redirect: transparencyreporttQ7OgIAAf9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporttQ7OgIAAf9Meta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreporttQ7OgIAAf9Meta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreporttQ7OgIAAf9Meta || {},
    alias: transparencyreporttQ7OgIAAf9Meta?.alias || [],
    redirect: transparencyreporttQ7OgIAAf9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporttQ7OgIAAf9Meta?.name ?? "legal-transparencyreport___de",
    path: transparencyreporttQ7OgIAAf9Meta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreporttQ7OgIAAf9Meta || {},
    alias: transparencyreporttQ7OgIAAf9Meta?.alias || [],
    redirect: transparencyreporttQ7OgIAAf9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporttQ7OgIAAf9Meta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreporttQ7OgIAAf9Meta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreporttQ7OgIAAf9Meta || {},
    alias: transparencyreporttQ7OgIAAf9Meta?.alias || [],
    redirect: transparencyreporttQ7OgIAAf9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporttQ7OgIAAf9Meta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreporttQ7OgIAAf9Meta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreporttQ7OgIAAf9Meta || {},
    alias: transparencyreporttQ7OgIAAf9Meta?.alias || [],
    redirect: transparencyreporttQ7OgIAAf9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporttQ7OgIAAf9Meta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreporttQ7OgIAAf9Meta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreporttQ7OgIAAf9Meta || {},
    alias: transparencyreporttQ7OgIAAf9Meta?.alias || [],
    redirect: transparencyreporttQ7OgIAAf9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicep1IWmcUxwcMeta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45servicep1IWmcUxwcMeta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicep1IWmcUxwcMeta || {},
    alias: website_45terms_45of_45servicep1IWmcUxwcMeta?.alias || [],
    redirect: website_45terms_45of_45servicep1IWmcUxwcMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicep1IWmcUxwcMeta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45servicep1IWmcUxwcMeta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicep1IWmcUxwcMeta || {},
    alias: website_45terms_45of_45servicep1IWmcUxwcMeta?.alias || [],
    redirect: website_45terms_45of_45servicep1IWmcUxwcMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicep1IWmcUxwcMeta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45servicep1IWmcUxwcMeta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicep1IWmcUxwcMeta || {},
    alias: website_45terms_45of_45servicep1IWmcUxwcMeta?.alias || [],
    redirect: website_45terms_45of_45servicep1IWmcUxwcMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicep1IWmcUxwcMeta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45servicep1IWmcUxwcMeta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicep1IWmcUxwcMeta || {},
    alias: website_45terms_45of_45servicep1IWmcUxwcMeta?.alias || [],
    redirect: website_45terms_45of_45servicep1IWmcUxwcMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicep1IWmcUxwcMeta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45servicep1IWmcUxwcMeta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicep1IWmcUxwcMeta || {},
    alias: website_45terms_45of_45servicep1IWmcUxwcMeta?.alias || [],
    redirect: website_45terms_45of_45servicep1IWmcUxwcMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicep1IWmcUxwcMeta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45servicep1IWmcUxwcMeta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicep1IWmcUxwcMeta || {},
    alias: website_45terms_45of_45servicep1IWmcUxwcMeta?.alias || [],
    redirect: website_45terms_45of_45servicep1IWmcUxwcMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicep1IWmcUxwcMeta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45servicep1IWmcUxwcMeta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicep1IWmcUxwcMeta || {},
    alias: website_45terms_45of_45servicep1IWmcUxwcMeta?.alias || [],
    redirect: website_45terms_45of_45servicep1IWmcUxwcMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicep1IWmcUxwcMeta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45servicep1IWmcUxwcMeta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicep1IWmcUxwcMeta || {},
    alias: website_45terms_45of_45servicep1IWmcUxwcMeta?.alias || [],
    redirect: website_45terms_45of_45servicep1IWmcUxwcMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesDgA6UECJ5ZMeta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choicesDgA6UECJ5ZMeta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choicesDgA6UECJ5ZMeta || {},
    alias: your_45privacy_45choicesDgA6UECJ5ZMeta?.alias || [],
    redirect: your_45privacy_45choicesDgA6UECJ5ZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesDgA6UECJ5ZMeta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choicesDgA6UECJ5ZMeta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesDgA6UECJ5ZMeta || {},
    alias: your_45privacy_45choicesDgA6UECJ5ZMeta?.alias || [],
    redirect: your_45privacy_45choicesDgA6UECJ5ZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesDgA6UECJ5ZMeta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choicesDgA6UECJ5ZMeta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choicesDgA6UECJ5ZMeta || {},
    alias: your_45privacy_45choicesDgA6UECJ5ZMeta?.alias || [],
    redirect: your_45privacy_45choicesDgA6UECJ5ZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesDgA6UECJ5ZMeta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choicesDgA6UECJ5ZMeta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choicesDgA6UECJ5ZMeta || {},
    alias: your_45privacy_45choicesDgA6UECJ5ZMeta?.alias || [],
    redirect: your_45privacy_45choicesDgA6UECJ5ZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesDgA6UECJ5ZMeta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choicesDgA6UECJ5ZMeta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choicesDgA6UECJ5ZMeta || {},
    alias: your_45privacy_45choicesDgA6UECJ5ZMeta?.alias || [],
    redirect: your_45privacy_45choicesDgA6UECJ5ZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesDgA6UECJ5ZMeta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choicesDgA6UECJ5ZMeta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choicesDgA6UECJ5ZMeta || {},
    alias: your_45privacy_45choicesDgA6UECJ5ZMeta?.alias || [],
    redirect: your_45privacy_45choicesDgA6UECJ5ZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesDgA6UECJ5ZMeta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choicesDgA6UECJ5ZMeta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choicesDgA6UECJ5ZMeta || {},
    alias: your_45privacy_45choicesDgA6UECJ5ZMeta?.alias || [],
    redirect: your_45privacy_45choicesDgA6UECJ5ZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicesDgA6UECJ5ZMeta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choicesDgA6UECJ5ZMeta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicesDgA6UECJ5ZMeta || {},
    alias: your_45privacy_45choicesDgA6UECJ5ZMeta?.alias || [],
    redirect: your_45privacy_45choicesDgA6UECJ5ZMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: pricingmWztgs5K0xMeta?.name ?? "page-pricing___en-US",
    path: pricingmWztgs5K0xMeta?.path ?? "/page/pricing",
    meta: pricingmWztgs5K0xMeta || {},
    alias: pricingmWztgs5K0xMeta?.alias || [],
    redirect: pricingmWztgs5K0xMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingmWztgs5K0xMeta?.name ?? "page-pricing___ca",
    path: pricingmWztgs5K0xMeta?.path ?? "/ca/page/pricing",
    meta: pricingmWztgs5K0xMeta || {},
    alias: pricingmWztgs5K0xMeta?.alias || [],
    redirect: pricingmWztgs5K0xMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingmWztgs5K0xMeta?.name ?? "page-pricing___nl",
    path: pricingmWztgs5K0xMeta?.path ?? "/nl/page/pricing",
    meta: pricingmWztgs5K0xMeta || {},
    alias: pricingmWztgs5K0xMeta?.alias || [],
    redirect: pricingmWztgs5K0xMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingmWztgs5K0xMeta?.name ?? "page-pricing___uk",
    path: pricingmWztgs5K0xMeta?.path ?? "/uk/page/pricing",
    meta: pricingmWztgs5K0xMeta || {},
    alias: pricingmWztgs5K0xMeta?.alias || [],
    redirect: pricingmWztgs5K0xMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingmWztgs5K0xMeta?.name ?? "page-pricing___de",
    path: pricingmWztgs5K0xMeta?.path ?? "/de/page/pricing",
    meta: pricingmWztgs5K0xMeta || {},
    alias: pricingmWztgs5K0xMeta?.alias || [],
    redirect: pricingmWztgs5K0xMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingmWztgs5K0xMeta?.name ?? "page-pricing___mx",
    path: pricingmWztgs5K0xMeta?.path ?? "/mx/page/pricing",
    meta: pricingmWztgs5K0xMeta || {},
    alias: pricingmWztgs5K0xMeta?.alias || [],
    redirect: pricingmWztgs5K0xMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingmWztgs5K0xMeta?.name ?? "page-pricing___fr",
    path: pricingmWztgs5K0xMeta?.path ?? "/fr/page/pricing",
    meta: pricingmWztgs5K0xMeta || {},
    alias: pricingmWztgs5K0xMeta?.alias || [],
    redirect: pricingmWztgs5K0xMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingmWztgs5K0xMeta?.name ?? "page-pricing___fr-ca",
    path: pricingmWztgs5K0xMeta?.path ?? "/fr-ca/page/pricing",
    meta: pricingmWztgs5K0xMeta || {},
    alias: pricingmWztgs5K0xMeta?.alias || [],
    redirect: pricingmWztgs5K0xMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EjPW2TKQttMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_93EjPW2TKQttMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_93EjPW2TKQttMeta || {},
    alias: _91slug_93EjPW2TKQttMeta?.alias || [],
    redirect: _91slug_93EjPW2TKQttMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EjPW2TKQttMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_93EjPW2TKQttMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_93EjPW2TKQttMeta || {},
    alias: _91slug_93EjPW2TKQttMeta?.alias || [],
    redirect: _91slug_93EjPW2TKQttMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EjPW2TKQttMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_93EjPW2TKQttMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_93EjPW2TKQttMeta || {},
    alias: _91slug_93EjPW2TKQttMeta?.alias || [],
    redirect: _91slug_93EjPW2TKQttMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EjPW2TKQttMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_93EjPW2TKQttMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_93EjPW2TKQttMeta || {},
    alias: _91slug_93EjPW2TKQttMeta?.alias || [],
    redirect: _91slug_93EjPW2TKQttMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EjPW2TKQttMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_93EjPW2TKQttMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_93EjPW2TKQttMeta || {},
    alias: _91slug_93EjPW2TKQttMeta?.alias || [],
    redirect: _91slug_93EjPW2TKQttMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EjPW2TKQttMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_93EjPW2TKQttMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_93EjPW2TKQttMeta || {},
    alias: _91slug_93EjPW2TKQttMeta?.alias || [],
    redirect: _91slug_93EjPW2TKQttMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EjPW2TKQttMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_93EjPW2TKQttMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_93EjPW2TKQttMeta || {},
    alias: _91slug_93EjPW2TKQttMeta?.alias || [],
    redirect: _91slug_93EjPW2TKQttMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EjPW2TKQttMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_93EjPW2TKQttMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_93EjPW2TKQttMeta || {},
    alias: _91slug_93EjPW2TKQttMeta?.alias || [],
    redirect: _91slug_93EjPW2TKQttMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kw9vumItN7Meta?.name ?? "pages-slug___en-US",
    path: _91slug_93Kw9vumItN7Meta?.path ?? "/pages/:slug()",
    meta: _91slug_93Kw9vumItN7Meta || {},
    alias: _91slug_93Kw9vumItN7Meta?.alias || [],
    redirect: _91slug_93Kw9vumItN7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kw9vumItN7Meta?.name ?? "pages-slug___ca",
    path: _91slug_93Kw9vumItN7Meta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_93Kw9vumItN7Meta || {},
    alias: _91slug_93Kw9vumItN7Meta?.alias || [],
    redirect: _91slug_93Kw9vumItN7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kw9vumItN7Meta?.name ?? "pages-slug___nl",
    path: _91slug_93Kw9vumItN7Meta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_93Kw9vumItN7Meta || {},
    alias: _91slug_93Kw9vumItN7Meta?.alias || [],
    redirect: _91slug_93Kw9vumItN7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kw9vumItN7Meta?.name ?? "pages-slug___uk",
    path: _91slug_93Kw9vumItN7Meta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_93Kw9vumItN7Meta || {},
    alias: _91slug_93Kw9vumItN7Meta?.alias || [],
    redirect: _91slug_93Kw9vumItN7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kw9vumItN7Meta?.name ?? "pages-slug___de",
    path: _91slug_93Kw9vumItN7Meta?.path ?? "/de/pages/:slug()",
    meta: _91slug_93Kw9vumItN7Meta || {},
    alias: _91slug_93Kw9vumItN7Meta?.alias || [],
    redirect: _91slug_93Kw9vumItN7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kw9vumItN7Meta?.name ?? "pages-slug___mx",
    path: _91slug_93Kw9vumItN7Meta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_93Kw9vumItN7Meta || {},
    alias: _91slug_93Kw9vumItN7Meta?.alias || [],
    redirect: _91slug_93Kw9vumItN7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kw9vumItN7Meta?.name ?? "pages-slug___fr",
    path: _91slug_93Kw9vumItN7Meta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_93Kw9vumItN7Meta || {},
    alias: _91slug_93Kw9vumItN7Meta?.alias || [],
    redirect: _91slug_93Kw9vumItN7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Kw9vumItN7Meta?.name ?? "pages-slug___fr-ca",
    path: _91slug_93Kw9vumItN7Meta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_93Kw9vumItN7Meta || {},
    alias: _91slug_93Kw9vumItN7Meta?.alias || [],
    redirect: _91slug_93Kw9vumItN7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingeU68b0yPgLMeta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45trackingeU68b0yPgLMeta?.path ?? "/pages/asset-tracking",
    meta: asset_45trackingeU68b0yPgLMeta || {},
    alias: asset_45trackingeU68b0yPgLMeta?.alias || [],
    redirect: asset_45trackingeU68b0yPgLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingeU68b0yPgLMeta?.name ?? "pages-asset-tracking___ca",
    path: asset_45trackingeU68b0yPgLMeta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45trackingeU68b0yPgLMeta || {},
    alias: asset_45trackingeU68b0yPgLMeta?.alias || [],
    redirect: asset_45trackingeU68b0yPgLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingeU68b0yPgLMeta?.name ?? "pages-asset-tracking___nl",
    path: asset_45trackingeU68b0yPgLMeta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45trackingeU68b0yPgLMeta || {},
    alias: asset_45trackingeU68b0yPgLMeta?.alias || [],
    redirect: asset_45trackingeU68b0yPgLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingeU68b0yPgLMeta?.name ?? "pages-asset-tracking___uk",
    path: asset_45trackingeU68b0yPgLMeta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45trackingeU68b0yPgLMeta || {},
    alias: asset_45trackingeU68b0yPgLMeta?.alias || [],
    redirect: asset_45trackingeU68b0yPgLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingeU68b0yPgLMeta?.name ?? "pages-asset-tracking___de",
    path: asset_45trackingeU68b0yPgLMeta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45trackingeU68b0yPgLMeta || {},
    alias: asset_45trackingeU68b0yPgLMeta?.alias || [],
    redirect: asset_45trackingeU68b0yPgLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingeU68b0yPgLMeta?.name ?? "pages-asset-tracking___mx",
    path: asset_45trackingeU68b0yPgLMeta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45trackingeU68b0yPgLMeta || {},
    alias: asset_45trackingeU68b0yPgLMeta?.alias || [],
    redirect: asset_45trackingeU68b0yPgLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingeU68b0yPgLMeta?.name ?? "pages-asset-tracking___fr",
    path: asset_45trackingeU68b0yPgLMeta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45trackingeU68b0yPgLMeta || {},
    alias: asset_45trackingeU68b0yPgLMeta?.alias || [],
    redirect: asset_45trackingeU68b0yPgLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingeU68b0yPgLMeta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45trackingeU68b0yPgLMeta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45trackingeU68b0yPgLMeta || {},
    alias: asset_45trackingeU68b0yPgLMeta?.alias || [],
    redirect: asset_45trackingeU68b0yPgLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsvREDIv6beQMeta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45camsvREDIv6beQMeta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45camsvREDIv6beQMeta || {},
    alias: best_45dash_45camsvREDIv6beQMeta?.alias || [],
    redirect: best_45dash_45camsvREDIv6beQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsvREDIv6beQMeta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45camsvREDIv6beQMeta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45camsvREDIv6beQMeta || {},
    alias: best_45dash_45camsvREDIv6beQMeta?.alias || [],
    redirect: best_45dash_45camsvREDIv6beQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsvREDIv6beQMeta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45camsvREDIv6beQMeta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45camsvREDIv6beQMeta || {},
    alias: best_45dash_45camsvREDIv6beQMeta?.alias || [],
    redirect: best_45dash_45camsvREDIv6beQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsvREDIv6beQMeta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45camsvREDIv6beQMeta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45camsvREDIv6beQMeta || {},
    alias: best_45dash_45camsvREDIv6beQMeta?.alias || [],
    redirect: best_45dash_45camsvREDIv6beQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsvREDIv6beQMeta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45camsvREDIv6beQMeta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45camsvREDIv6beQMeta || {},
    alias: best_45dash_45camsvREDIv6beQMeta?.alias || [],
    redirect: best_45dash_45camsvREDIv6beQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsvREDIv6beQMeta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45camsvREDIv6beQMeta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45camsvREDIv6beQMeta || {},
    alias: best_45dash_45camsvREDIv6beQMeta?.alias || [],
    redirect: best_45dash_45camsvREDIv6beQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsvREDIv6beQMeta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45camsvREDIv6beQMeta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45camsvREDIv6beQMeta || {},
    alias: best_45dash_45camsvREDIv6beQMeta?.alias || [],
    redirect: best_45dash_45camsvREDIv6beQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsvREDIv6beQMeta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45camsvREDIv6beQMeta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45camsvREDIv6beQMeta || {},
    alias: best_45dash_45camsvREDIv6beQMeta?.alias || [],
    redirect: best_45dash_45camsvREDIv6beQMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: constructionynyqYFuyaNMeta?.name ?? "pages-construction___en-US",
    path: constructionynyqYFuyaNMeta?.path ?? "/pages/construction",
    meta: constructionynyqYFuyaNMeta || {},
    alias: constructionynyqYFuyaNMeta?.alias || [],
    redirect: constructionynyqYFuyaNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionynyqYFuyaNMeta?.name ?? "pages-construction___ca",
    path: constructionynyqYFuyaNMeta?.path ?? "/ca/pages/construction",
    meta: constructionynyqYFuyaNMeta || {},
    alias: constructionynyqYFuyaNMeta?.alias || [],
    redirect: constructionynyqYFuyaNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionynyqYFuyaNMeta?.name ?? "pages-construction___nl",
    path: constructionynyqYFuyaNMeta?.path ?? "/nl/pages/construction",
    meta: constructionynyqYFuyaNMeta || {},
    alias: constructionynyqYFuyaNMeta?.alias || [],
    redirect: constructionynyqYFuyaNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionynyqYFuyaNMeta?.name ?? "pages-construction___uk",
    path: constructionynyqYFuyaNMeta?.path ?? "/uk/pages/construction",
    meta: constructionynyqYFuyaNMeta || {},
    alias: constructionynyqYFuyaNMeta?.alias || [],
    redirect: constructionynyqYFuyaNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionynyqYFuyaNMeta?.name ?? "pages-construction___de",
    path: constructionynyqYFuyaNMeta?.path ?? "/de/pages/construction",
    meta: constructionynyqYFuyaNMeta || {},
    alias: constructionynyqYFuyaNMeta?.alias || [],
    redirect: constructionynyqYFuyaNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionynyqYFuyaNMeta?.name ?? "pages-construction___mx",
    path: constructionynyqYFuyaNMeta?.path ?? "/mx/pages/construction",
    meta: constructionynyqYFuyaNMeta || {},
    alias: constructionynyqYFuyaNMeta?.alias || [],
    redirect: constructionynyqYFuyaNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionynyqYFuyaNMeta?.name ?? "pages-construction___fr",
    path: constructionynyqYFuyaNMeta?.path ?? "/fr/pages/construction",
    meta: constructionynyqYFuyaNMeta || {},
    alias: constructionynyqYFuyaNMeta?.alias || [],
    redirect: constructionynyqYFuyaNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionynyqYFuyaNMeta?.name ?? "pages-construction___fr-ca",
    path: constructionynyqYFuyaNMeta?.path ?? "/fr-ca/pages/construction",
    meta: constructionynyqYFuyaNMeta || {},
    alias: constructionynyqYFuyaNMeta?.alias || [],
    redirect: constructionynyqYFuyaNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: eversourceT4a0aZvfLHMeta?.name ?? "pages-eversource___en-US",
    path: eversourceT4a0aZvfLHMeta?.path ?? "/pages/eversource",
    meta: eversourceT4a0aZvfLHMeta || {},
    alias: eversourceT4a0aZvfLHMeta?.alias || [],
    redirect: eversourceT4a0aZvfLHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourceT4a0aZvfLHMeta?.name ?? "pages-eversource___ca",
    path: eversourceT4a0aZvfLHMeta?.path ?? "/ca/pages/eversource",
    meta: eversourceT4a0aZvfLHMeta || {},
    alias: eversourceT4a0aZvfLHMeta?.alias || [],
    redirect: eversourceT4a0aZvfLHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourceT4a0aZvfLHMeta?.name ?? "pages-eversource___nl",
    path: eversourceT4a0aZvfLHMeta?.path ?? "/nl/pages/eversource",
    meta: eversourceT4a0aZvfLHMeta || {},
    alias: eversourceT4a0aZvfLHMeta?.alias || [],
    redirect: eversourceT4a0aZvfLHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourceT4a0aZvfLHMeta?.name ?? "pages-eversource___uk",
    path: eversourceT4a0aZvfLHMeta?.path ?? "/uk/pages/eversource",
    meta: eversourceT4a0aZvfLHMeta || {},
    alias: eversourceT4a0aZvfLHMeta?.alias || [],
    redirect: eversourceT4a0aZvfLHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourceT4a0aZvfLHMeta?.name ?? "pages-eversource___de",
    path: eversourceT4a0aZvfLHMeta?.path ?? "/de/pages/eversource",
    meta: eversourceT4a0aZvfLHMeta || {},
    alias: eversourceT4a0aZvfLHMeta?.alias || [],
    redirect: eversourceT4a0aZvfLHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourceT4a0aZvfLHMeta?.name ?? "pages-eversource___mx",
    path: eversourceT4a0aZvfLHMeta?.path ?? "/mx/pages/eversource",
    meta: eversourceT4a0aZvfLHMeta || {},
    alias: eversourceT4a0aZvfLHMeta?.alias || [],
    redirect: eversourceT4a0aZvfLHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourceT4a0aZvfLHMeta?.name ?? "pages-eversource___fr",
    path: eversourceT4a0aZvfLHMeta?.path ?? "/fr/pages/eversource",
    meta: eversourceT4a0aZvfLHMeta || {},
    alias: eversourceT4a0aZvfLHMeta?.alias || [],
    redirect: eversourceT4a0aZvfLHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: eversourceT4a0aZvfLHMeta?.name ?? "pages-eversource___fr-ca",
    path: eversourceT4a0aZvfLHMeta?.path ?? "/fr-ca/pages/eversource",
    meta: eversourceT4a0aZvfLHMeta || {},
    alias: eversourceT4a0aZvfLHMeta?.alias || [],
    redirect: eversourceT4a0aZvfLHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/eversource.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementjYXKOoW9xvMeta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45managementjYXKOoW9xvMeta?.path ?? "/pages/fleet-management",
    meta: fleet_45managementjYXKOoW9xvMeta || {},
    alias: fleet_45managementjYXKOoW9xvMeta?.alias || [],
    redirect: fleet_45managementjYXKOoW9xvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementjYXKOoW9xvMeta?.name ?? "pages-fleet-management___ca",
    path: fleet_45managementjYXKOoW9xvMeta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45managementjYXKOoW9xvMeta || {},
    alias: fleet_45managementjYXKOoW9xvMeta?.alias || [],
    redirect: fleet_45managementjYXKOoW9xvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementjYXKOoW9xvMeta?.name ?? "pages-fleet-management___nl",
    path: fleet_45managementjYXKOoW9xvMeta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45managementjYXKOoW9xvMeta || {},
    alias: fleet_45managementjYXKOoW9xvMeta?.alias || [],
    redirect: fleet_45managementjYXKOoW9xvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementjYXKOoW9xvMeta?.name ?? "pages-fleet-management___uk",
    path: fleet_45managementjYXKOoW9xvMeta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45managementjYXKOoW9xvMeta || {},
    alias: fleet_45managementjYXKOoW9xvMeta?.alias || [],
    redirect: fleet_45managementjYXKOoW9xvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementjYXKOoW9xvMeta?.name ?? "pages-fleet-management___de",
    path: fleet_45managementjYXKOoW9xvMeta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45managementjYXKOoW9xvMeta || {},
    alias: fleet_45managementjYXKOoW9xvMeta?.alias || [],
    redirect: fleet_45managementjYXKOoW9xvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementjYXKOoW9xvMeta?.name ?? "pages-fleet-management___mx",
    path: fleet_45managementjYXKOoW9xvMeta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45managementjYXKOoW9xvMeta || {},
    alias: fleet_45managementjYXKOoW9xvMeta?.alias || [],
    redirect: fleet_45managementjYXKOoW9xvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementjYXKOoW9xvMeta?.name ?? "pages-fleet-management___fr",
    path: fleet_45managementjYXKOoW9xvMeta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45managementjYXKOoW9xvMeta || {},
    alias: fleet_45managementjYXKOoW9xvMeta?.alias || [],
    redirect: fleet_45managementjYXKOoW9xvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementjYXKOoW9xvMeta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45managementjYXKOoW9xvMeta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45managementjYXKOoW9xvMeta || {},
    alias: fleet_45managementjYXKOoW9xvMeta?.alias || [],
    redirect: fleet_45managementjYXKOoW9xvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: groundworkseRf6whAVbVMeta?.name ?? "pages-groundworks___en-US",
    path: groundworkseRf6whAVbVMeta?.path ?? "/pages/groundworks",
    meta: groundworkseRf6whAVbVMeta || {},
    alias: groundworkseRf6whAVbVMeta?.alias || [],
    redirect: groundworkseRf6whAVbVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworkseRf6whAVbVMeta?.name ?? "pages-groundworks___ca",
    path: groundworkseRf6whAVbVMeta?.path ?? "/ca/pages/groundworks",
    meta: groundworkseRf6whAVbVMeta || {},
    alias: groundworkseRf6whAVbVMeta?.alias || [],
    redirect: groundworkseRf6whAVbVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworkseRf6whAVbVMeta?.name ?? "pages-groundworks___nl",
    path: groundworkseRf6whAVbVMeta?.path ?? "/nl/pages/groundworks",
    meta: groundworkseRf6whAVbVMeta || {},
    alias: groundworkseRf6whAVbVMeta?.alias || [],
    redirect: groundworkseRf6whAVbVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworkseRf6whAVbVMeta?.name ?? "pages-groundworks___uk",
    path: groundworkseRf6whAVbVMeta?.path ?? "/uk/pages/groundworks",
    meta: groundworkseRf6whAVbVMeta || {},
    alias: groundworkseRf6whAVbVMeta?.alias || [],
    redirect: groundworkseRf6whAVbVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworkseRf6whAVbVMeta?.name ?? "pages-groundworks___de",
    path: groundworkseRf6whAVbVMeta?.path ?? "/de/pages/groundworks",
    meta: groundworkseRf6whAVbVMeta || {},
    alias: groundworkseRf6whAVbVMeta?.alias || [],
    redirect: groundworkseRf6whAVbVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworkseRf6whAVbVMeta?.name ?? "pages-groundworks___mx",
    path: groundworkseRf6whAVbVMeta?.path ?? "/mx/pages/groundworks",
    meta: groundworkseRf6whAVbVMeta || {},
    alias: groundworkseRf6whAVbVMeta?.alias || [],
    redirect: groundworkseRf6whAVbVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworkseRf6whAVbVMeta?.name ?? "pages-groundworks___fr",
    path: groundworkseRf6whAVbVMeta?.path ?? "/fr/pages/groundworks",
    meta: groundworkseRf6whAVbVMeta || {},
    alias: groundworkseRf6whAVbVMeta?.alias || [],
    redirect: groundworkseRf6whAVbVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: groundworkseRf6whAVbVMeta?.name ?? "pages-groundworks___fr-ca",
    path: groundworkseRf6whAVbVMeta?.path ?? "/fr-ca/pages/groundworks",
    meta: groundworkseRf6whAVbVMeta || {},
    alias: groundworkseRf6whAVbVMeta?.alias || [],
    redirect: groundworkseRf6whAVbVMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/groundworks.vue").then(m => m.default || m)
  },
  {
    name: nationwidejxSFzxQPNlMeta?.name ?? "pages-nationwide___en-US",
    path: nationwidejxSFzxQPNlMeta?.path ?? "/pages/nationwide",
    meta: nationwidejxSFzxQPNlMeta || {},
    alias: nationwidejxSFzxQPNlMeta?.alias || [],
    redirect: nationwidejxSFzxQPNlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidejxSFzxQPNlMeta?.name ?? "pages-nationwide___ca",
    path: nationwidejxSFzxQPNlMeta?.path ?? "/ca/pages/nationwide",
    meta: nationwidejxSFzxQPNlMeta || {},
    alias: nationwidejxSFzxQPNlMeta?.alias || [],
    redirect: nationwidejxSFzxQPNlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidejxSFzxQPNlMeta?.name ?? "pages-nationwide___nl",
    path: nationwidejxSFzxQPNlMeta?.path ?? "/nl/pages/nationwide",
    meta: nationwidejxSFzxQPNlMeta || {},
    alias: nationwidejxSFzxQPNlMeta?.alias || [],
    redirect: nationwidejxSFzxQPNlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidejxSFzxQPNlMeta?.name ?? "pages-nationwide___uk",
    path: nationwidejxSFzxQPNlMeta?.path ?? "/uk/pages/nationwide",
    meta: nationwidejxSFzxQPNlMeta || {},
    alias: nationwidejxSFzxQPNlMeta?.alias || [],
    redirect: nationwidejxSFzxQPNlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidejxSFzxQPNlMeta?.name ?? "pages-nationwide___de",
    path: nationwidejxSFzxQPNlMeta?.path ?? "/de/pages/nationwide",
    meta: nationwidejxSFzxQPNlMeta || {},
    alias: nationwidejxSFzxQPNlMeta?.alias || [],
    redirect: nationwidejxSFzxQPNlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidejxSFzxQPNlMeta?.name ?? "pages-nationwide___mx",
    path: nationwidejxSFzxQPNlMeta?.path ?? "/mx/pages/nationwide",
    meta: nationwidejxSFzxQPNlMeta || {},
    alias: nationwidejxSFzxQPNlMeta?.alias || [],
    redirect: nationwidejxSFzxQPNlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidejxSFzxQPNlMeta?.name ?? "pages-nationwide___fr",
    path: nationwidejxSFzxQPNlMeta?.path ?? "/fr/pages/nationwide",
    meta: nationwidejxSFzxQPNlMeta || {},
    alias: nationwidejxSFzxQPNlMeta?.alias || [],
    redirect: nationwidejxSFzxQPNlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwidejxSFzxQPNlMeta?.name ?? "pages-nationwide___fr-ca",
    path: nationwidejxSFzxQPNlMeta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwidejxSFzxQPNlMeta || {},
    alias: nationwidejxSFzxQPNlMeta?.alias || [],
    redirect: nationwidejxSFzxQPNlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingWbecDGcH8fMeta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coachingWbecDGcH8fMeta?.path ?? "/pages/safety-coaching",
    meta: safety_45coachingWbecDGcH8fMeta || {},
    alias: safety_45coachingWbecDGcH8fMeta?.alias || [],
    redirect: safety_45coachingWbecDGcH8fMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingWbecDGcH8fMeta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coachingWbecDGcH8fMeta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coachingWbecDGcH8fMeta || {},
    alias: safety_45coachingWbecDGcH8fMeta?.alias || [],
    redirect: safety_45coachingWbecDGcH8fMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingWbecDGcH8fMeta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coachingWbecDGcH8fMeta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coachingWbecDGcH8fMeta || {},
    alias: safety_45coachingWbecDGcH8fMeta?.alias || [],
    redirect: safety_45coachingWbecDGcH8fMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingWbecDGcH8fMeta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coachingWbecDGcH8fMeta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coachingWbecDGcH8fMeta || {},
    alias: safety_45coachingWbecDGcH8fMeta?.alias || [],
    redirect: safety_45coachingWbecDGcH8fMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingWbecDGcH8fMeta?.name ?? "pages-safety-coaching___de",
    path: safety_45coachingWbecDGcH8fMeta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coachingWbecDGcH8fMeta || {},
    alias: safety_45coachingWbecDGcH8fMeta?.alias || [],
    redirect: safety_45coachingWbecDGcH8fMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingWbecDGcH8fMeta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coachingWbecDGcH8fMeta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coachingWbecDGcH8fMeta || {},
    alias: safety_45coachingWbecDGcH8fMeta?.alias || [],
    redirect: safety_45coachingWbecDGcH8fMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingWbecDGcH8fMeta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coachingWbecDGcH8fMeta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coachingWbecDGcH8fMeta || {},
    alias: safety_45coachingWbecDGcH8fMeta?.alias || [],
    redirect: safety_45coachingWbecDGcH8fMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coachingWbecDGcH8fMeta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coachingWbecDGcH8fMeta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coachingWbecDGcH8fMeta || {},
    alias: safety_45coachingWbecDGcH8fMeta?.alias || [],
    redirect: safety_45coachingWbecDGcH8fMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: unifirstavN06l0AoqMeta?.name ?? "pages-unifirst___en-US",
    path: unifirstavN06l0AoqMeta?.path ?? "/pages/unifirst",
    meta: unifirstavN06l0AoqMeta || {},
    alias: unifirstavN06l0AoqMeta?.alias || [],
    redirect: unifirstavN06l0AoqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstavN06l0AoqMeta?.name ?? "pages-unifirst___ca",
    path: unifirstavN06l0AoqMeta?.path ?? "/ca/pages/unifirst",
    meta: unifirstavN06l0AoqMeta || {},
    alias: unifirstavN06l0AoqMeta?.alias || [],
    redirect: unifirstavN06l0AoqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstavN06l0AoqMeta?.name ?? "pages-unifirst___nl",
    path: unifirstavN06l0AoqMeta?.path ?? "/nl/pages/unifirst",
    meta: unifirstavN06l0AoqMeta || {},
    alias: unifirstavN06l0AoqMeta?.alias || [],
    redirect: unifirstavN06l0AoqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstavN06l0AoqMeta?.name ?? "pages-unifirst___uk",
    path: unifirstavN06l0AoqMeta?.path ?? "/uk/pages/unifirst",
    meta: unifirstavN06l0AoqMeta || {},
    alias: unifirstavN06l0AoqMeta?.alias || [],
    redirect: unifirstavN06l0AoqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstavN06l0AoqMeta?.name ?? "pages-unifirst___de",
    path: unifirstavN06l0AoqMeta?.path ?? "/de/pages/unifirst",
    meta: unifirstavN06l0AoqMeta || {},
    alias: unifirstavN06l0AoqMeta?.alias || [],
    redirect: unifirstavN06l0AoqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstavN06l0AoqMeta?.name ?? "pages-unifirst___mx",
    path: unifirstavN06l0AoqMeta?.path ?? "/mx/pages/unifirst",
    meta: unifirstavN06l0AoqMeta || {},
    alias: unifirstavN06l0AoqMeta?.alias || [],
    redirect: unifirstavN06l0AoqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstavN06l0AoqMeta?.name ?? "pages-unifirst___fr",
    path: unifirstavN06l0AoqMeta?.path ?? "/fr/pages/unifirst",
    meta: unifirstavN06l0AoqMeta || {},
    alias: unifirstavN06l0AoqMeta?.alias || [],
    redirect: unifirstavN06l0AoqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: unifirstavN06l0AoqMeta?.name ?? "pages-unifirst___fr-ca",
    path: unifirstavN06l0AoqMeta?.path ?? "/fr-ca/pages/unifirst",
    meta: unifirstavN06l0AoqMeta || {},
    alias: unifirstavN06l0AoqMeta?.alias || [],
    redirect: unifirstavN06l0AoqMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pages/unifirst.vue").then(m => m.default || m)
  },
  {
    name: pricingdbygTTfXDiMeta?.name ?? "pricing___en-US",
    path: pricingdbygTTfXDiMeta?.path ?? "/pricing",
    meta: pricingdbygTTfXDiMeta || {},
    alias: pricingdbygTTfXDiMeta?.alias || [],
    redirect: pricingdbygTTfXDiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingdbygTTfXDiMeta?.name ?? "pricing___ca",
    path: pricingdbygTTfXDiMeta?.path ?? "/ca/pricing",
    meta: pricingdbygTTfXDiMeta || {},
    alias: pricingdbygTTfXDiMeta?.alias || [],
    redirect: pricingdbygTTfXDiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingdbygTTfXDiMeta?.name ?? "pricing___nl",
    path: pricingdbygTTfXDiMeta?.path ?? "/nl/pricing",
    meta: pricingdbygTTfXDiMeta || {},
    alias: pricingdbygTTfXDiMeta?.alias || [],
    redirect: pricingdbygTTfXDiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingdbygTTfXDiMeta?.name ?? "pricing___uk",
    path: pricingdbygTTfXDiMeta?.path ?? "/uk/pricing",
    meta: pricingdbygTTfXDiMeta || {},
    alias: pricingdbygTTfXDiMeta?.alias || [],
    redirect: pricingdbygTTfXDiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingdbygTTfXDiMeta?.name ?? "pricing___de",
    path: pricingdbygTTfXDiMeta?.path ?? "/de/pricing",
    meta: pricingdbygTTfXDiMeta || {},
    alias: pricingdbygTTfXDiMeta?.alias || [],
    redirect: pricingdbygTTfXDiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingdbygTTfXDiMeta?.name ?? "pricing___mx",
    path: pricingdbygTTfXDiMeta?.path ?? "/mx/prueba-gratis",
    meta: pricingdbygTTfXDiMeta || {},
    alias: pricingdbygTTfXDiMeta?.alias || [],
    redirect: pricingdbygTTfXDiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingdbygTTfXDiMeta?.name ?? "pricing___fr",
    path: pricingdbygTTfXDiMeta?.path ?? "/fr/pricing",
    meta: pricingdbygTTfXDiMeta || {},
    alias: pricingdbygTTfXDiMeta?.alias || [],
    redirect: pricingdbygTTfXDiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingdbygTTfXDiMeta?.name ?? "pricing___fr-ca",
    path: pricingdbygTTfXDiMeta?.path ?? "/fr-ca/pricing",
    meta: pricingdbygTTfXDiMeta || {},
    alias: pricingdbygTTfXDiMeta?.alias || [],
    redirect: pricingdbygTTfXDiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jBQyDDUivPMeta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_93jBQyDDUivPMeta?.path ?? "/products/:prefix()/:slug()",
    meta: { ...(_91slug_93jBQyDDUivPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93jBQyDDUivPMeta?.alias || [],
    redirect: _91slug_93jBQyDDUivPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jBQyDDUivPMeta?.name ?? "products-prefix-slug___ca",
    path: _91slug_93jBQyDDUivPMeta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: { ...(_91slug_93jBQyDDUivPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93jBQyDDUivPMeta?.alias || [],
    redirect: _91slug_93jBQyDDUivPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jBQyDDUivPMeta?.name ?? "products-prefix-slug___nl",
    path: _91slug_93jBQyDDUivPMeta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: { ...(_91slug_93jBQyDDUivPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93jBQyDDUivPMeta?.alias || [],
    redirect: _91slug_93jBQyDDUivPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jBQyDDUivPMeta?.name ?? "products-prefix-slug___uk",
    path: _91slug_93jBQyDDUivPMeta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: { ...(_91slug_93jBQyDDUivPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93jBQyDDUivPMeta?.alias || [],
    redirect: _91slug_93jBQyDDUivPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jBQyDDUivPMeta?.name ?? "products-prefix-slug___de",
    path: _91slug_93jBQyDDUivPMeta?.path ?? "/de/products/:prefix()/:slug()",
    meta: { ...(_91slug_93jBQyDDUivPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93jBQyDDUivPMeta?.alias || [],
    redirect: _91slug_93jBQyDDUivPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jBQyDDUivPMeta?.name ?? "products-prefix-slug___mx",
    path: _91slug_93jBQyDDUivPMeta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: { ...(_91slug_93jBQyDDUivPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93jBQyDDUivPMeta?.alias || [],
    redirect: _91slug_93jBQyDDUivPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jBQyDDUivPMeta?.name ?? "products-prefix-slug___fr",
    path: _91slug_93jBQyDDUivPMeta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: { ...(_91slug_93jBQyDDUivPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93jBQyDDUivPMeta?.alias || [],
    redirect: _91slug_93jBQyDDUivPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jBQyDDUivPMeta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_93jBQyDDUivPMeta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: { ...(_91slug_93jBQyDDUivPMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93jBQyDDUivPMeta?.alias || [],
    redirect: _91slug_93jBQyDDUivPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NGDHIEp0IxMeta?.name ?? "products-slug___en-US",
    path: _91slug_93NGDHIEp0IxMeta?.path ?? "/products/:slug()",
    meta: { ...(_91slug_93NGDHIEp0IxMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93NGDHIEp0IxMeta?.alias || [],
    redirect: _91slug_93NGDHIEp0IxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NGDHIEp0IxMeta?.name ?? "products-slug___ca",
    path: _91slug_93NGDHIEp0IxMeta?.path ?? "/ca/products/:slug()",
    meta: { ...(_91slug_93NGDHIEp0IxMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93NGDHIEp0IxMeta?.alias || [],
    redirect: _91slug_93NGDHIEp0IxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NGDHIEp0IxMeta?.name ?? "products-slug___nl",
    path: _91slug_93NGDHIEp0IxMeta?.path ?? "/nl/products/:slug()",
    meta: { ...(_91slug_93NGDHIEp0IxMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93NGDHIEp0IxMeta?.alias || [],
    redirect: _91slug_93NGDHIEp0IxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NGDHIEp0IxMeta?.name ?? "products-slug___uk",
    path: _91slug_93NGDHIEp0IxMeta?.path ?? "/uk/products/:slug()",
    meta: { ...(_91slug_93NGDHIEp0IxMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93NGDHIEp0IxMeta?.alias || [],
    redirect: _91slug_93NGDHIEp0IxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NGDHIEp0IxMeta?.name ?? "products-slug___de",
    path: _91slug_93NGDHIEp0IxMeta?.path ?? "/de/products/:slug()",
    meta: { ...(_91slug_93NGDHIEp0IxMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93NGDHIEp0IxMeta?.alias || [],
    redirect: _91slug_93NGDHIEp0IxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NGDHIEp0IxMeta?.name ?? "products-slug___mx",
    path: _91slug_93NGDHIEp0IxMeta?.path ?? "/mx/products/:slug()",
    meta: { ...(_91slug_93NGDHIEp0IxMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93NGDHIEp0IxMeta?.alias || [],
    redirect: _91slug_93NGDHIEp0IxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NGDHIEp0IxMeta?.name ?? "products-slug___fr",
    path: _91slug_93NGDHIEp0IxMeta?.path ?? "/fr/products/:slug()",
    meta: { ...(_91slug_93NGDHIEp0IxMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93NGDHIEp0IxMeta?.alias || [],
    redirect: _91slug_93NGDHIEp0IxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NGDHIEp0IxMeta?.name ?? "products-slug___fr-ca",
    path: _91slug_93NGDHIEp0IxMeta?.path ?? "/fr-ca/products/:slug()",
    meta: { ...(_91slug_93NGDHIEp0IxMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_93NGDHIEp0IxMeta?.alias || [],
    redirect: _91slug_93NGDHIEp0IxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingLhVRcRq4wWMeta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45trackingLhVRcRq4wWMeta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingLhVRcRq4wWMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingLhVRcRq4wWMeta?.alias || [],
    redirect: asset_45trackingLhVRcRq4wWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingLhVRcRq4wWMeta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45trackingLhVRcRq4wWMeta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingLhVRcRq4wWMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingLhVRcRq4wWMeta?.alias || [],
    redirect: asset_45trackingLhVRcRq4wWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingLhVRcRq4wWMeta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45trackingLhVRcRq4wWMeta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingLhVRcRq4wWMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingLhVRcRq4wWMeta?.alias || [],
    redirect: asset_45trackingLhVRcRq4wWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingLhVRcRq4wWMeta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45trackingLhVRcRq4wWMeta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingLhVRcRq4wWMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingLhVRcRq4wWMeta?.alias || [],
    redirect: asset_45trackingLhVRcRq4wWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingLhVRcRq4wWMeta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45trackingLhVRcRq4wWMeta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingLhVRcRq4wWMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingLhVRcRq4wWMeta?.alias || [],
    redirect: asset_45trackingLhVRcRq4wWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingLhVRcRq4wWMeta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45trackingLhVRcRq4wWMeta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingLhVRcRq4wWMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingLhVRcRq4wWMeta?.alias || [],
    redirect: asset_45trackingLhVRcRq4wWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingLhVRcRq4wWMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45trackingLhVRcRq4wWMeta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingLhVRcRq4wWMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingLhVRcRq4wWMeta?.alias || [],
    redirect: asset_45trackingLhVRcRq4wWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingLhVRcRq4wWMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45trackingLhVRcRq4wWMeta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: { ...(asset_45trackingLhVRcRq4wWMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: asset_45trackingLhVRcRq4wWMeta?.alias || [],
    redirect: asset_45trackingLhVRcRq4wWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingDk8nTdmxI6Meta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45trackingDk8nTdmxI6Meta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingDk8nTdmxI6Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingDk8nTdmxI6Meta?.alias || [],
    redirect: equipment_45trackingDk8nTdmxI6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingDk8nTdmxI6Meta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45trackingDk8nTdmxI6Meta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingDk8nTdmxI6Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingDk8nTdmxI6Meta?.alias || [],
    redirect: equipment_45trackingDk8nTdmxI6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingDk8nTdmxI6Meta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45trackingDk8nTdmxI6Meta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingDk8nTdmxI6Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingDk8nTdmxI6Meta?.alias || [],
    redirect: equipment_45trackingDk8nTdmxI6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingDk8nTdmxI6Meta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45trackingDk8nTdmxI6Meta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingDk8nTdmxI6Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingDk8nTdmxI6Meta?.alias || [],
    redirect: equipment_45trackingDk8nTdmxI6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingDk8nTdmxI6Meta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45trackingDk8nTdmxI6Meta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingDk8nTdmxI6Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingDk8nTdmxI6Meta?.alias || [],
    redirect: equipment_45trackingDk8nTdmxI6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingDk8nTdmxI6Meta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45trackingDk8nTdmxI6Meta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingDk8nTdmxI6Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingDk8nTdmxI6Meta?.alias || [],
    redirect: equipment_45trackingDk8nTdmxI6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingDk8nTdmxI6Meta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45trackingDk8nTdmxI6Meta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingDk8nTdmxI6Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingDk8nTdmxI6Meta?.alias || [],
    redirect: equipment_45trackingDk8nTdmxI6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingDk8nTdmxI6Meta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45trackingDk8nTdmxI6Meta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: { ...(equipment_45trackingDk8nTdmxI6Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: equipment_45trackingDk8nTdmxI6Meta?.alias || [],
    redirect: equipment_45trackingDk8nTdmxI6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringJJVRuUMTy4Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoringJJVRuUMTy4Meta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringJJVRuUMTy4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringJJVRuUMTy4Meta?.alias || [],
    redirect: reefer_45monitoringJJVRuUMTy4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringJJVRuUMTy4Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoringJJVRuUMTy4Meta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringJJVRuUMTy4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringJJVRuUMTy4Meta?.alias || [],
    redirect: reefer_45monitoringJJVRuUMTy4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringJJVRuUMTy4Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoringJJVRuUMTy4Meta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringJJVRuUMTy4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringJJVRuUMTy4Meta?.alias || [],
    redirect: reefer_45monitoringJJVRuUMTy4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringJJVRuUMTy4Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoringJJVRuUMTy4Meta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringJJVRuUMTy4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringJJVRuUMTy4Meta?.alias || [],
    redirect: reefer_45monitoringJJVRuUMTy4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringJJVRuUMTy4Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoringJJVRuUMTy4Meta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringJJVRuUMTy4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringJJVRuUMTy4Meta?.alias || [],
    redirect: reefer_45monitoringJJVRuUMTy4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringJJVRuUMTy4Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoringJJVRuUMTy4Meta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: { ...(reefer_45monitoringJJVRuUMTy4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringJJVRuUMTy4Meta?.alias || [],
    redirect: reefer_45monitoringJJVRuUMTy4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringJJVRuUMTy4Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoringJJVRuUMTy4Meta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringJJVRuUMTy4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringJJVRuUMTy4Meta?.alias || [],
    redirect: reefer_45monitoringJJVRuUMTy4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringJJVRuUMTy4Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoringJJVRuUMTy4Meta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: { ...(reefer_45monitoringJJVRuUMTy4Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: reefer_45monitoringJJVRuUMTy4Meta?.alias || [],
    redirect: reefer_45monitoringJJVRuUMTy4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersZ41Newpcw9Meta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailersZ41Newpcw9Meta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersZ41Newpcw9Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersZ41Newpcw9Meta?.alias || [],
    redirect: smart_45trailersZ41Newpcw9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersZ41Newpcw9Meta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailersZ41Newpcw9Meta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersZ41Newpcw9Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersZ41Newpcw9Meta?.alias || [],
    redirect: smart_45trailersZ41Newpcw9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersZ41Newpcw9Meta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailersZ41Newpcw9Meta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersZ41Newpcw9Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersZ41Newpcw9Meta?.alias || [],
    redirect: smart_45trailersZ41Newpcw9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersZ41Newpcw9Meta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailersZ41Newpcw9Meta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersZ41Newpcw9Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersZ41Newpcw9Meta?.alias || [],
    redirect: smart_45trailersZ41Newpcw9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersZ41Newpcw9Meta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailersZ41Newpcw9Meta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersZ41Newpcw9Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersZ41Newpcw9Meta?.alias || [],
    redirect: smart_45trailersZ41Newpcw9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersZ41Newpcw9Meta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailersZ41Newpcw9Meta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersZ41Newpcw9Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersZ41Newpcw9Meta?.alias || [],
    redirect: smart_45trailersZ41Newpcw9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersZ41Newpcw9Meta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailersZ41Newpcw9Meta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersZ41Newpcw9Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersZ41Newpcw9Meta?.alias || [],
    redirect: smart_45trailersZ41Newpcw9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersZ41Newpcw9Meta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailersZ41Newpcw9Meta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: { ...(smart_45trailersZ41Newpcw9Meta || {}), ...{"middleware":["6s-redirect"]} },
    alias: smart_45trailersZ41Newpcw9Meta?.alias || [],
    redirect: smart_45trailersZ41Newpcw9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingHmJSQ0It3nMeta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45trackingHmJSQ0It3nMeta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingHmJSQ0It3nMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingHmJSQ0It3nMeta?.alias || [],
    redirect: trailer_45trackingHmJSQ0It3nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingHmJSQ0It3nMeta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45trackingHmJSQ0It3nMeta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingHmJSQ0It3nMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingHmJSQ0It3nMeta?.alias || [],
    redirect: trailer_45trackingHmJSQ0It3nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingHmJSQ0It3nMeta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45trackingHmJSQ0It3nMeta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingHmJSQ0It3nMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingHmJSQ0It3nMeta?.alias || [],
    redirect: trailer_45trackingHmJSQ0It3nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingHmJSQ0It3nMeta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45trackingHmJSQ0It3nMeta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingHmJSQ0It3nMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingHmJSQ0It3nMeta?.alias || [],
    redirect: trailer_45trackingHmJSQ0It3nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingHmJSQ0It3nMeta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45trackingHmJSQ0It3nMeta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingHmJSQ0It3nMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingHmJSQ0It3nMeta?.alias || [],
    redirect: trailer_45trackingHmJSQ0It3nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingHmJSQ0It3nMeta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45trackingHmJSQ0It3nMeta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingHmJSQ0It3nMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingHmJSQ0It3nMeta?.alias || [],
    redirect: trailer_45trackingHmJSQ0It3nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingHmJSQ0It3nMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45trackingHmJSQ0It3nMeta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingHmJSQ0It3nMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingHmJSQ0It3nMeta?.alias || [],
    redirect: trailer_45trackingHmJSQ0It3nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45trackingHmJSQ0It3nMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45trackingHmJSQ0It3nMeta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: { ...(trailer_45trackingHmJSQ0It3nMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: trailer_45trackingHmJSQ0It3nMeta?.alias || [],
    redirect: trailer_45trackingHmJSQ0It3nMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexFHc6BUtMgvMeta?.name ?? "products___en-US",
    path: indexFHc6BUtMgvMeta?.path ?? "/products",
    meta: { ...(indexFHc6BUtMgvMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexFHc6BUtMgvMeta?.alias || [],
    redirect: indexFHc6BUtMgvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexFHc6BUtMgvMeta?.name ?? "products___ca",
    path: indexFHc6BUtMgvMeta?.path ?? "/ca/products",
    meta: { ...(indexFHc6BUtMgvMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexFHc6BUtMgvMeta?.alias || [],
    redirect: indexFHc6BUtMgvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexFHc6BUtMgvMeta?.name ?? "products___nl",
    path: indexFHc6BUtMgvMeta?.path ?? "/nl/products",
    meta: { ...(indexFHc6BUtMgvMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexFHc6BUtMgvMeta?.alias || [],
    redirect: indexFHc6BUtMgvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexFHc6BUtMgvMeta?.name ?? "products___uk",
    path: indexFHc6BUtMgvMeta?.path ?? "/uk/products",
    meta: { ...(indexFHc6BUtMgvMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexFHc6BUtMgvMeta?.alias || [],
    redirect: indexFHc6BUtMgvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexFHc6BUtMgvMeta?.name ?? "products___de",
    path: indexFHc6BUtMgvMeta?.path ?? "/de/products",
    meta: { ...(indexFHc6BUtMgvMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexFHc6BUtMgvMeta?.alias || [],
    redirect: indexFHc6BUtMgvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexFHc6BUtMgvMeta?.name ?? "products___mx",
    path: indexFHc6BUtMgvMeta?.path ?? "/mx/products",
    meta: { ...(indexFHc6BUtMgvMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexFHc6BUtMgvMeta?.alias || [],
    redirect: indexFHc6BUtMgvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexFHc6BUtMgvMeta?.name ?? "products___fr",
    path: indexFHc6BUtMgvMeta?.path ?? "/fr/products",
    meta: { ...(indexFHc6BUtMgvMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexFHc6BUtMgvMeta?.alias || [],
    redirect: indexFHc6BUtMgvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexFHc6BUtMgvMeta?.name ?? "products___fr-ca",
    path: indexFHc6BUtMgvMeta?.path ?? "/fr-ca/products",
    meta: { ...(indexFHc6BUtMgvMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexFHc6BUtMgvMeta?.alias || [],
    redirect: indexFHc6BUtMgvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Wp6HxR2NIMeta?.name ?? "products-models-slug___en-US",
    path: _91slug_932Wp6HxR2NIMeta?.path ?? "/products/models/:slug()",
    meta: { ...(_91slug_932Wp6HxR2NIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_932Wp6HxR2NIMeta?.alias || [],
    redirect: _91slug_932Wp6HxR2NIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Wp6HxR2NIMeta?.name ?? "products-models-slug___ca",
    path: _91slug_932Wp6HxR2NIMeta?.path ?? "/ca/products/models/:slug()",
    meta: { ...(_91slug_932Wp6HxR2NIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_932Wp6HxR2NIMeta?.alias || [],
    redirect: _91slug_932Wp6HxR2NIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Wp6HxR2NIMeta?.name ?? "products-models-slug___nl",
    path: _91slug_932Wp6HxR2NIMeta?.path ?? "/nl/products/models/:slug()",
    meta: { ...(_91slug_932Wp6HxR2NIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_932Wp6HxR2NIMeta?.alias || [],
    redirect: _91slug_932Wp6HxR2NIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Wp6HxR2NIMeta?.name ?? "products-models-slug___uk",
    path: _91slug_932Wp6HxR2NIMeta?.path ?? "/uk/products/models/:slug()",
    meta: { ...(_91slug_932Wp6HxR2NIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_932Wp6HxR2NIMeta?.alias || [],
    redirect: _91slug_932Wp6HxR2NIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Wp6HxR2NIMeta?.name ?? "products-models-slug___de",
    path: _91slug_932Wp6HxR2NIMeta?.path ?? "/de/products/models/:slug()",
    meta: { ...(_91slug_932Wp6HxR2NIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_932Wp6HxR2NIMeta?.alias || [],
    redirect: _91slug_932Wp6HxR2NIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Wp6HxR2NIMeta?.name ?? "products-models-slug___mx",
    path: _91slug_932Wp6HxR2NIMeta?.path ?? "/mx/products/models/:slug()",
    meta: { ...(_91slug_932Wp6HxR2NIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_932Wp6HxR2NIMeta?.alias || [],
    redirect: _91slug_932Wp6HxR2NIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Wp6HxR2NIMeta?.name ?? "products-models-slug___fr",
    path: _91slug_932Wp6HxR2NIMeta?.path ?? "/fr/products/models/:slug()",
    meta: { ...(_91slug_932Wp6HxR2NIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_932Wp6HxR2NIMeta?.alias || [],
    redirect: _91slug_932Wp6HxR2NIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932Wp6HxR2NIMeta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_932Wp6HxR2NIMeta?.path ?? "/fr-ca/products/models/:slug()",
    meta: { ...(_91slug_932Wp6HxR2NIMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: _91slug_932Wp6HxR2NIMeta?.alias || [],
    redirect: _91slug_932Wp6HxR2NIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexmBJfwxce0tMeta?.name ?? "products-models___en-US",
    path: indexmBJfwxce0tMeta?.path ?? "/products/models",
    meta: { ...(indexmBJfwxce0tMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexmBJfwxce0tMeta?.alias || [],
    redirect: indexmBJfwxce0tMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBJfwxce0tMeta?.name ?? "products-models___ca",
    path: indexmBJfwxce0tMeta?.path ?? "/ca/products/models",
    meta: { ...(indexmBJfwxce0tMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexmBJfwxce0tMeta?.alias || [],
    redirect: indexmBJfwxce0tMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBJfwxce0tMeta?.name ?? "products-models___nl",
    path: indexmBJfwxce0tMeta?.path ?? "/nl/products/models",
    meta: { ...(indexmBJfwxce0tMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexmBJfwxce0tMeta?.alias || [],
    redirect: indexmBJfwxce0tMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBJfwxce0tMeta?.name ?? "products-models___uk",
    path: indexmBJfwxce0tMeta?.path ?? "/uk/products/models",
    meta: { ...(indexmBJfwxce0tMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexmBJfwxce0tMeta?.alias || [],
    redirect: indexmBJfwxce0tMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBJfwxce0tMeta?.name ?? "products-models___de",
    path: indexmBJfwxce0tMeta?.path ?? "/de/products/models",
    meta: { ...(indexmBJfwxce0tMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexmBJfwxce0tMeta?.alias || [],
    redirect: indexmBJfwxce0tMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBJfwxce0tMeta?.name ?? "products-models___mx",
    path: indexmBJfwxce0tMeta?.path ?? "/mx/products/models",
    meta: { ...(indexmBJfwxce0tMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexmBJfwxce0tMeta?.alias || [],
    redirect: indexmBJfwxce0tMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBJfwxce0tMeta?.name ?? "products-models___fr",
    path: indexmBJfwxce0tMeta?.path ?? "/fr/products/models",
    meta: { ...(indexmBJfwxce0tMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexmBJfwxce0tMeta?.alias || [],
    redirect: indexmBJfwxce0tMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmBJfwxce0tMeta?.name ?? "products-models___fr-ca",
    path: indexmBJfwxce0tMeta?.path ?? "/fr-ca/products/models",
    meta: { ...(indexmBJfwxce0tMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexmBJfwxce0tMeta?.alias || [],
    redirect: indexmBJfwxce0tMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingZQtDZiZAlHMeta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45trackingZQtDZiZAlHMeta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingZQtDZiZAlHMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingZQtDZiZAlHMeta?.alias || [],
    redirect: gps_45fleet_45trackingZQtDZiZAlHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingZQtDZiZAlHMeta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45trackingZQtDZiZAlHMeta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingZQtDZiZAlHMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingZQtDZiZAlHMeta?.alias || [],
    redirect: gps_45fleet_45trackingZQtDZiZAlHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingZQtDZiZAlHMeta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45trackingZQtDZiZAlHMeta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingZQtDZiZAlHMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingZQtDZiZAlHMeta?.alias || [],
    redirect: gps_45fleet_45trackingZQtDZiZAlHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingZQtDZiZAlHMeta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45trackingZQtDZiZAlHMeta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingZQtDZiZAlHMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingZQtDZiZAlHMeta?.alias || [],
    redirect: gps_45fleet_45trackingZQtDZiZAlHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingZQtDZiZAlHMeta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45trackingZQtDZiZAlHMeta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingZQtDZiZAlHMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingZQtDZiZAlHMeta?.alias || [],
    redirect: gps_45fleet_45trackingZQtDZiZAlHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingZQtDZiZAlHMeta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45trackingZQtDZiZAlHMeta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: { ...(gps_45fleet_45trackingZQtDZiZAlHMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingZQtDZiZAlHMeta?.alias || [],
    redirect: gps_45fleet_45trackingZQtDZiZAlHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingZQtDZiZAlHMeta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45trackingZQtDZiZAlHMeta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingZQtDZiZAlHMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingZQtDZiZAlHMeta?.alias || [],
    redirect: gps_45fleet_45trackingZQtDZiZAlHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingZQtDZiZAlHMeta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45trackingZQtDZiZAlHMeta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: { ...(gps_45fleet_45trackingZQtDZiZAlHMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: gps_45fleet_45trackingZQtDZiZAlHMeta?.alias || [],
    redirect: gps_45fleet_45trackingZQtDZiZAlHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexoaZmV9mShiMeta?.name ?? "products-telematics___en-US",
    path: indexoaZmV9mShiMeta?.path ?? "/products/telematics",
    meta: { ...(indexoaZmV9mShiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexoaZmV9mShiMeta?.alias || [],
    redirect: indexoaZmV9mShiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexoaZmV9mShiMeta?.name ?? "products-telematics___ca",
    path: indexoaZmV9mShiMeta?.path ?? "/ca/products/telematics",
    meta: { ...(indexoaZmV9mShiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexoaZmV9mShiMeta?.alias || [],
    redirect: indexoaZmV9mShiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexoaZmV9mShiMeta?.name ?? "products-telematics___nl",
    path: indexoaZmV9mShiMeta?.path ?? "/nl/products/telematics",
    meta: { ...(indexoaZmV9mShiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexoaZmV9mShiMeta?.alias || [],
    redirect: indexoaZmV9mShiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexoaZmV9mShiMeta?.name ?? "products-telematics___uk",
    path: indexoaZmV9mShiMeta?.path ?? "/uk/products/telematics",
    meta: { ...(indexoaZmV9mShiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexoaZmV9mShiMeta?.alias || [],
    redirect: indexoaZmV9mShiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexoaZmV9mShiMeta?.name ?? "products-telematics___de",
    path: indexoaZmV9mShiMeta?.path ?? "/de/products/telematics",
    meta: { ...(indexoaZmV9mShiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexoaZmV9mShiMeta?.alias || [],
    redirect: indexoaZmV9mShiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexoaZmV9mShiMeta?.name ?? "products-telematics___mx",
    path: indexoaZmV9mShiMeta?.path ?? "/mx/products/flota",
    meta: { ...(indexoaZmV9mShiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexoaZmV9mShiMeta?.alias || [],
    redirect: indexoaZmV9mShiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexoaZmV9mShiMeta?.name ?? "products-telematics___fr",
    path: indexoaZmV9mShiMeta?.path ?? "/fr/products/telematics",
    meta: { ...(indexoaZmV9mShiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexoaZmV9mShiMeta?.alias || [],
    redirect: indexoaZmV9mShiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexoaZmV9mShiMeta?.name ?? "products-telematics___fr-ca",
    path: indexoaZmV9mShiMeta?.path ?? "/fr-ca/products/telematics",
    meta: { ...(indexoaZmV9mShiMeta || {}), ...{"middleware":["6s-redirect"]} },
    alias: indexoaZmV9mShiMeta?.alias || [],
    redirect: indexoaZmV9mShiMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: racingXTEYkG7JaxMeta?.name ?? "racing___en-US",
    path: racingXTEYkG7JaxMeta?.path ?? "/racing",
    meta: racingXTEYkG7JaxMeta || {},
    alias: racingXTEYkG7JaxMeta?.alias || [],
    redirect: racingXTEYkG7JaxMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/racing.vue").then(m => m.default || m)
  },
  {
    name: referralsTp5qxmqaQUMeta?.name ?? "referrals___en-US",
    path: referralsTp5qxmqaQUMeta?.path ?? "/referrals",
    meta: referralsTp5qxmqaQUMeta || {},
    alias: referralsTp5qxmqaQUMeta?.alias || [],
    redirect: referralsTp5qxmqaQUMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsTp5qxmqaQUMeta?.name ?? "referrals___ca",
    path: referralsTp5qxmqaQUMeta?.path ?? "/ca/referrals",
    meta: referralsTp5qxmqaQUMeta || {},
    alias: referralsTp5qxmqaQUMeta?.alias || [],
    redirect: referralsTp5qxmqaQUMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsTp5qxmqaQUMeta?.name ?? "referrals___nl",
    path: referralsTp5qxmqaQUMeta?.path ?? "/nl/referrals",
    meta: referralsTp5qxmqaQUMeta || {},
    alias: referralsTp5qxmqaQUMeta?.alias || [],
    redirect: referralsTp5qxmqaQUMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsTp5qxmqaQUMeta?.name ?? "referrals___uk",
    path: referralsTp5qxmqaQUMeta?.path ?? "/uk/referrals",
    meta: referralsTp5qxmqaQUMeta || {},
    alias: referralsTp5qxmqaQUMeta?.alias || [],
    redirect: referralsTp5qxmqaQUMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsTp5qxmqaQUMeta?.name ?? "referrals___de",
    path: referralsTp5qxmqaQUMeta?.path ?? "/de/referrals",
    meta: referralsTp5qxmqaQUMeta || {},
    alias: referralsTp5qxmqaQUMeta?.alias || [],
    redirect: referralsTp5qxmqaQUMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsTp5qxmqaQUMeta?.name ?? "referrals___mx",
    path: referralsTp5qxmqaQUMeta?.path ?? "/mx/referrals",
    meta: referralsTp5qxmqaQUMeta || {},
    alias: referralsTp5qxmqaQUMeta?.alias || [],
    redirect: referralsTp5qxmqaQUMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsTp5qxmqaQUMeta?.name ?? "referrals___fr",
    path: referralsTp5qxmqaQUMeta?.path ?? "/fr/referrals",
    meta: referralsTp5qxmqaQUMeta || {},
    alias: referralsTp5qxmqaQUMeta?.alias || [],
    redirect: referralsTp5qxmqaQUMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsTp5qxmqaQUMeta?.name ?? "referrals___fr-ca",
    path: referralsTp5qxmqaQUMeta?.path ?? "/fr-ca/referrals",
    meta: referralsTp5qxmqaQUMeta || {},
    alias: referralsTp5qxmqaQUMeta?.alias || [],
    redirect: referralsTp5qxmqaQUMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334oAVpEPDSMeta?.name ?? "resources-slug___en-US",
    path: _91slug_9334oAVpEPDSMeta?.path ?? "/resources/:slug()",
    meta: _91slug_9334oAVpEPDSMeta || {},
    alias: _91slug_9334oAVpEPDSMeta?.alias || [],
    redirect: _91slug_9334oAVpEPDSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334oAVpEPDSMeta?.name ?? "resources-slug___ca",
    path: _91slug_9334oAVpEPDSMeta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_9334oAVpEPDSMeta || {},
    alias: _91slug_9334oAVpEPDSMeta?.alias || [],
    redirect: _91slug_9334oAVpEPDSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334oAVpEPDSMeta?.name ?? "resources-slug___nl",
    path: _91slug_9334oAVpEPDSMeta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_9334oAVpEPDSMeta || {},
    alias: _91slug_9334oAVpEPDSMeta?.alias || [],
    redirect: _91slug_9334oAVpEPDSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334oAVpEPDSMeta?.name ?? "resources-slug___uk",
    path: _91slug_9334oAVpEPDSMeta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_9334oAVpEPDSMeta || {},
    alias: _91slug_9334oAVpEPDSMeta?.alias || [],
    redirect: _91slug_9334oAVpEPDSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334oAVpEPDSMeta?.name ?? "resources-slug___de",
    path: _91slug_9334oAVpEPDSMeta?.path ?? "/de/resources/:slug()",
    meta: _91slug_9334oAVpEPDSMeta || {},
    alias: _91slug_9334oAVpEPDSMeta?.alias || [],
    redirect: _91slug_9334oAVpEPDSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334oAVpEPDSMeta?.name ?? "resources-slug___mx",
    path: _91slug_9334oAVpEPDSMeta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_9334oAVpEPDSMeta || {},
    alias: _91slug_9334oAVpEPDSMeta?.alias || [],
    redirect: _91slug_9334oAVpEPDSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334oAVpEPDSMeta?.name ?? "resources-slug___fr",
    path: _91slug_9334oAVpEPDSMeta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_9334oAVpEPDSMeta || {},
    alias: _91slug_9334oAVpEPDSMeta?.alias || [],
    redirect: _91slug_9334oAVpEPDSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9334oAVpEPDSMeta?.name ?? "resources-slug___fr-ca",
    path: _91slug_9334oAVpEPDSMeta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_9334oAVpEPDSMeta || {},
    alias: _91slug_9334oAVpEPDSMeta?.alias || [],
    redirect: _91slug_9334oAVpEPDSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsArIxAeEcIjMeta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assetsArIxAeEcIjMeta?.path ?? "/resources/brand-assets",
    meta: brand_45assetsArIxAeEcIjMeta || {},
    alias: brand_45assetsArIxAeEcIjMeta?.alias || [],
    redirect: brand_45assetsArIxAeEcIjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsArIxAeEcIjMeta?.name ?? "resources-brand-assets___ca",
    path: brand_45assetsArIxAeEcIjMeta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assetsArIxAeEcIjMeta || {},
    alias: brand_45assetsArIxAeEcIjMeta?.alias || [],
    redirect: brand_45assetsArIxAeEcIjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsArIxAeEcIjMeta?.name ?? "resources-brand-assets___nl",
    path: brand_45assetsArIxAeEcIjMeta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assetsArIxAeEcIjMeta || {},
    alias: brand_45assetsArIxAeEcIjMeta?.alias || [],
    redirect: brand_45assetsArIxAeEcIjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsArIxAeEcIjMeta?.name ?? "resources-brand-assets___uk",
    path: brand_45assetsArIxAeEcIjMeta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assetsArIxAeEcIjMeta || {},
    alias: brand_45assetsArIxAeEcIjMeta?.alias || [],
    redirect: brand_45assetsArIxAeEcIjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsArIxAeEcIjMeta?.name ?? "resources-brand-assets___de",
    path: brand_45assetsArIxAeEcIjMeta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assetsArIxAeEcIjMeta || {},
    alias: brand_45assetsArIxAeEcIjMeta?.alias || [],
    redirect: brand_45assetsArIxAeEcIjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsArIxAeEcIjMeta?.name ?? "resources-brand-assets___mx",
    path: brand_45assetsArIxAeEcIjMeta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assetsArIxAeEcIjMeta || {},
    alias: brand_45assetsArIxAeEcIjMeta?.alias || [],
    redirect: brand_45assetsArIxAeEcIjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsArIxAeEcIjMeta?.name ?? "resources-brand-assets___fr",
    path: brand_45assetsArIxAeEcIjMeta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assetsArIxAeEcIjMeta || {},
    alias: brand_45assetsArIxAeEcIjMeta?.alias || [],
    redirect: brand_45assetsArIxAeEcIjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetsArIxAeEcIjMeta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assetsArIxAeEcIjMeta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assetsArIxAeEcIjMeta || {},
    alias: brand_45assetsArIxAeEcIjMeta?.alias || [],
    redirect: brand_45assetsArIxAeEcIjMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kNhM2dS05HMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_93kNhM2dS05HMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_93kNhM2dS05HMeta || {},
    alias: _91slug_93kNhM2dS05HMeta?.alias || [],
    redirect: _91slug_93kNhM2dS05HMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kNhM2dS05HMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_93kNhM2dS05HMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_93kNhM2dS05HMeta || {},
    alias: _91slug_93kNhM2dS05HMeta?.alias || [],
    redirect: _91slug_93kNhM2dS05HMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kNhM2dS05HMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_93kNhM2dS05HMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_93kNhM2dS05HMeta || {},
    alias: _91slug_93kNhM2dS05HMeta?.alias || [],
    redirect: _91slug_93kNhM2dS05HMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kNhM2dS05HMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_93kNhM2dS05HMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_93kNhM2dS05HMeta || {},
    alias: _91slug_93kNhM2dS05HMeta?.alias || [],
    redirect: _91slug_93kNhM2dS05HMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kNhM2dS05HMeta?.name ?? "resources-content-slug___de",
    path: _91slug_93kNhM2dS05HMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_93kNhM2dS05HMeta || {},
    alias: _91slug_93kNhM2dS05HMeta?.alias || [],
    redirect: _91slug_93kNhM2dS05HMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kNhM2dS05HMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_93kNhM2dS05HMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_93kNhM2dS05HMeta || {},
    alias: _91slug_93kNhM2dS05HMeta?.alias || [],
    redirect: _91slug_93kNhM2dS05HMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kNhM2dS05HMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_93kNhM2dS05HMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_93kNhM2dS05HMeta || {},
    alias: _91slug_93kNhM2dS05HMeta?.alias || [],
    redirect: _91slug_93kNhM2dS05HMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kNhM2dS05HMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_93kNhM2dS05HMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_93kNhM2dS05HMeta || {},
    alias: _91slug_93kNhM2dS05HMeta?.alias || [],
    redirect: _91slug_93kNhM2dS05HMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerNkhiHenB7KMeta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45centerNkhiHenB7KMeta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45centerNkhiHenB7KMeta || {},
    alias: email_45preferences_45centerNkhiHenB7KMeta?.alias || [],
    redirect: email_45preferences_45centerNkhiHenB7KMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerNkhiHenB7KMeta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45centerNkhiHenB7KMeta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerNkhiHenB7KMeta || {},
    alias: email_45preferences_45centerNkhiHenB7KMeta?.alias || [],
    redirect: email_45preferences_45centerNkhiHenB7KMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerNkhiHenB7KMeta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45centerNkhiHenB7KMeta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45centerNkhiHenB7KMeta || {},
    alias: email_45preferences_45centerNkhiHenB7KMeta?.alias || [],
    redirect: email_45preferences_45centerNkhiHenB7KMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerNkhiHenB7KMeta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45centerNkhiHenB7KMeta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45centerNkhiHenB7KMeta || {},
    alias: email_45preferences_45centerNkhiHenB7KMeta?.alias || [],
    redirect: email_45preferences_45centerNkhiHenB7KMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerNkhiHenB7KMeta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45centerNkhiHenB7KMeta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45centerNkhiHenB7KMeta || {},
    alias: email_45preferences_45centerNkhiHenB7KMeta?.alias || [],
    redirect: email_45preferences_45centerNkhiHenB7KMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerNkhiHenB7KMeta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45centerNkhiHenB7KMeta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45centerNkhiHenB7KMeta || {},
    alias: email_45preferences_45centerNkhiHenB7KMeta?.alias || [],
    redirect: email_45preferences_45centerNkhiHenB7KMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerNkhiHenB7KMeta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45centerNkhiHenB7KMeta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45centerNkhiHenB7KMeta || {},
    alias: email_45preferences_45centerNkhiHenB7KMeta?.alias || [],
    redirect: email_45preferences_45centerNkhiHenB7KMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerNkhiHenB7KMeta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45centerNkhiHenB7KMeta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerNkhiHenB7KMeta || {},
    alias: email_45preferences_45centerNkhiHenB7KMeta?.alias || [],
    redirect: email_45preferences_45centerNkhiHenB7KMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribesaRJD5huCEMeta?.name ?? "resources-content-one-click-unsubscribe___en-US",
    path: one_45click_45unsubscribesaRJD5huCEMeta?.path ?? "/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribesaRJD5huCEMeta || {},
    alias: one_45click_45unsubscribesaRJD5huCEMeta?.alias || [],
    redirect: one_45click_45unsubscribesaRJD5huCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribesaRJD5huCEMeta?.name ?? "resources-content-one-click-unsubscribe___ca",
    path: one_45click_45unsubscribesaRJD5huCEMeta?.path ?? "/ca/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribesaRJD5huCEMeta || {},
    alias: one_45click_45unsubscribesaRJD5huCEMeta?.alias || [],
    redirect: one_45click_45unsubscribesaRJD5huCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribesaRJD5huCEMeta?.name ?? "resources-content-one-click-unsubscribe___nl",
    path: one_45click_45unsubscribesaRJD5huCEMeta?.path ?? "/nl/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribesaRJD5huCEMeta || {},
    alias: one_45click_45unsubscribesaRJD5huCEMeta?.alias || [],
    redirect: one_45click_45unsubscribesaRJD5huCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribesaRJD5huCEMeta?.name ?? "resources-content-one-click-unsubscribe___uk",
    path: one_45click_45unsubscribesaRJD5huCEMeta?.path ?? "/uk/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribesaRJD5huCEMeta || {},
    alias: one_45click_45unsubscribesaRJD5huCEMeta?.alias || [],
    redirect: one_45click_45unsubscribesaRJD5huCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribesaRJD5huCEMeta?.name ?? "resources-content-one-click-unsubscribe___de",
    path: one_45click_45unsubscribesaRJD5huCEMeta?.path ?? "/de/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribesaRJD5huCEMeta || {},
    alias: one_45click_45unsubscribesaRJD5huCEMeta?.alias || [],
    redirect: one_45click_45unsubscribesaRJD5huCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribesaRJD5huCEMeta?.name ?? "resources-content-one-click-unsubscribe___mx",
    path: one_45click_45unsubscribesaRJD5huCEMeta?.path ?? "/mx/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribesaRJD5huCEMeta || {},
    alias: one_45click_45unsubscribesaRJD5huCEMeta?.alias || [],
    redirect: one_45click_45unsubscribesaRJD5huCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribesaRJD5huCEMeta?.name ?? "resources-content-one-click-unsubscribe___fr",
    path: one_45click_45unsubscribesaRJD5huCEMeta?.path ?? "/fr/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribesaRJD5huCEMeta || {},
    alias: one_45click_45unsubscribesaRJD5huCEMeta?.alias || [],
    redirect: one_45click_45unsubscribesaRJD5huCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribesaRJD5huCEMeta?.name ?? "resources-content-one-click-unsubscribe___fr-ca",
    path: one_45click_45unsubscribesaRJD5huCEMeta?.path ?? "/fr-ca/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribesaRJD5huCEMeta || {},
    alias: one_45click_45unsubscribesaRJD5huCEMeta?.alias || [],
    redirect: one_45click_45unsubscribesaRJD5huCEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yGpPGkLJ5oMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_93yGpPGkLJ5oMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_93yGpPGkLJ5oMeta || {},
    alias: _91slug_93yGpPGkLJ5oMeta?.alias || [],
    redirect: _91slug_93yGpPGkLJ5oMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yGpPGkLJ5oMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_93yGpPGkLJ5oMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_93yGpPGkLJ5oMeta || {},
    alias: _91slug_93yGpPGkLJ5oMeta?.alias || [],
    redirect: _91slug_93yGpPGkLJ5oMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yGpPGkLJ5oMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_93yGpPGkLJ5oMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_93yGpPGkLJ5oMeta || {},
    alias: _91slug_93yGpPGkLJ5oMeta?.alias || [],
    redirect: _91slug_93yGpPGkLJ5oMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yGpPGkLJ5oMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_93yGpPGkLJ5oMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_93yGpPGkLJ5oMeta || {},
    alias: _91slug_93yGpPGkLJ5oMeta?.alias || [],
    redirect: _91slug_93yGpPGkLJ5oMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yGpPGkLJ5oMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_93yGpPGkLJ5oMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_93yGpPGkLJ5oMeta || {},
    alias: _91slug_93yGpPGkLJ5oMeta?.alias || [],
    redirect: _91slug_93yGpPGkLJ5oMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yGpPGkLJ5oMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_93yGpPGkLJ5oMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_93yGpPGkLJ5oMeta || {},
    alias: _91slug_93yGpPGkLJ5oMeta?.alias || [],
    redirect: _91slug_93yGpPGkLJ5oMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yGpPGkLJ5oMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_93yGpPGkLJ5oMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_93yGpPGkLJ5oMeta || {},
    alias: _91slug_93yGpPGkLJ5oMeta?.alias || [],
    redirect: _91slug_93yGpPGkLJ5oMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yGpPGkLJ5oMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_93yGpPGkLJ5oMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_93yGpPGkLJ5oMeta || {},
    alias: _91slug_93yGpPGkLJ5oMeta?.alias || [],
    redirect: _91slug_93yGpPGkLJ5oMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jiNna9Q2ETMeta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_93jiNna9Q2ETMeta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_93jiNna9Q2ETMeta || {},
    alias: _91slug_93jiNna9Q2ETMeta?.alias || [],
    redirect: _91slug_93jiNna9Q2ETMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jiNna9Q2ETMeta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_93jiNna9Q2ETMeta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_93jiNna9Q2ETMeta || {},
    alias: _91slug_93jiNna9Q2ETMeta?.alias || [],
    redirect: _91slug_93jiNna9Q2ETMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jiNna9Q2ETMeta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_93jiNna9Q2ETMeta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_93jiNna9Q2ETMeta || {},
    alias: _91slug_93jiNna9Q2ETMeta?.alias || [],
    redirect: _91slug_93jiNna9Q2ETMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jiNna9Q2ETMeta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_93jiNna9Q2ETMeta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_93jiNna9Q2ETMeta || {},
    alias: _91slug_93jiNna9Q2ETMeta?.alias || [],
    redirect: _91slug_93jiNna9Q2ETMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jiNna9Q2ETMeta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_93jiNna9Q2ETMeta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_93jiNna9Q2ETMeta || {},
    alias: _91slug_93jiNna9Q2ETMeta?.alias || [],
    redirect: _91slug_93jiNna9Q2ETMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jiNna9Q2ETMeta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_93jiNna9Q2ETMeta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_93jiNna9Q2ETMeta || {},
    alias: _91slug_93jiNna9Q2ETMeta?.alias || [],
    redirect: _91slug_93jiNna9Q2ETMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jiNna9Q2ETMeta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_93jiNna9Q2ETMeta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_93jiNna9Q2ETMeta || {},
    alias: _91slug_93jiNna9Q2ETMeta?.alias || [],
    redirect: _91slug_93jiNna9Q2ETMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jiNna9Q2ETMeta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_93jiNna9Q2ETMeta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_93jiNna9Q2ETMeta || {},
    alias: _91slug_93jiNna9Q2ETMeta?.alias || [],
    redirect: _91slug_93jiNna9Q2ETMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexNHrgcTSdwIMeta?.name ?? "resources-experts___en-US",
    path: indexNHrgcTSdwIMeta?.path ?? "/resources/experts",
    meta: indexNHrgcTSdwIMeta || {},
    alias: indexNHrgcTSdwIMeta?.alias || [],
    redirect: indexNHrgcTSdwIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHrgcTSdwIMeta?.name ?? "resources-experts___ca",
    path: indexNHrgcTSdwIMeta?.path ?? "/ca/resources/experts",
    meta: indexNHrgcTSdwIMeta || {},
    alias: indexNHrgcTSdwIMeta?.alias || [],
    redirect: indexNHrgcTSdwIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHrgcTSdwIMeta?.name ?? "resources-experts___nl",
    path: indexNHrgcTSdwIMeta?.path ?? "/nl/resources/experts",
    meta: indexNHrgcTSdwIMeta || {},
    alias: indexNHrgcTSdwIMeta?.alias || [],
    redirect: indexNHrgcTSdwIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHrgcTSdwIMeta?.name ?? "resources-experts___uk",
    path: indexNHrgcTSdwIMeta?.path ?? "/uk/resources/experts",
    meta: indexNHrgcTSdwIMeta || {},
    alias: indexNHrgcTSdwIMeta?.alias || [],
    redirect: indexNHrgcTSdwIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHrgcTSdwIMeta?.name ?? "resources-experts___de",
    path: indexNHrgcTSdwIMeta?.path ?? "/de/resources/experts",
    meta: indexNHrgcTSdwIMeta || {},
    alias: indexNHrgcTSdwIMeta?.alias || [],
    redirect: indexNHrgcTSdwIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHrgcTSdwIMeta?.name ?? "resources-experts___mx",
    path: indexNHrgcTSdwIMeta?.path ?? "/mx/resources/experts",
    meta: indexNHrgcTSdwIMeta || {},
    alias: indexNHrgcTSdwIMeta?.alias || [],
    redirect: indexNHrgcTSdwIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHrgcTSdwIMeta?.name ?? "resources-experts___fr",
    path: indexNHrgcTSdwIMeta?.path ?? "/fr/resources/experts",
    meta: indexNHrgcTSdwIMeta || {},
    alias: indexNHrgcTSdwIMeta?.alias || [],
    redirect: indexNHrgcTSdwIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHrgcTSdwIMeta?.name ?? "resources-experts___fr-ca",
    path: indexNHrgcTSdwIMeta?.path ?? "/fr-ca/resources/experts",
    meta: indexNHrgcTSdwIMeta || {},
    alias: indexNHrgcTSdwIMeta?.alias || [],
    redirect: indexNHrgcTSdwIMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUyYJ35gmlMeta?.name ?? "resources___en-US",
    path: indexfUyYJ35gmlMeta?.path ?? "/resources",
    meta: indexfUyYJ35gmlMeta || {},
    alias: indexfUyYJ35gmlMeta?.alias || [],
    redirect: indexfUyYJ35gmlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUyYJ35gmlMeta?.name ?? "resources___ca",
    path: indexfUyYJ35gmlMeta?.path ?? "/ca/resources",
    meta: indexfUyYJ35gmlMeta || {},
    alias: indexfUyYJ35gmlMeta?.alias || [],
    redirect: indexfUyYJ35gmlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUyYJ35gmlMeta?.name ?? "resources___nl",
    path: indexfUyYJ35gmlMeta?.path ?? "/nl/resources",
    meta: indexfUyYJ35gmlMeta || {},
    alias: indexfUyYJ35gmlMeta?.alias || [],
    redirect: indexfUyYJ35gmlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUyYJ35gmlMeta?.name ?? "resources___uk",
    path: indexfUyYJ35gmlMeta?.path ?? "/uk/resources",
    meta: indexfUyYJ35gmlMeta || {},
    alias: indexfUyYJ35gmlMeta?.alias || [],
    redirect: indexfUyYJ35gmlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUyYJ35gmlMeta?.name ?? "resources___de",
    path: indexfUyYJ35gmlMeta?.path ?? "/de/resources",
    meta: indexfUyYJ35gmlMeta || {},
    alias: indexfUyYJ35gmlMeta?.alias || [],
    redirect: indexfUyYJ35gmlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUyYJ35gmlMeta?.name ?? "resources___mx",
    path: indexfUyYJ35gmlMeta?.path ?? "/mx/resources",
    meta: indexfUyYJ35gmlMeta || {},
    alias: indexfUyYJ35gmlMeta?.alias || [],
    redirect: indexfUyYJ35gmlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUyYJ35gmlMeta?.name ?? "resources___fr",
    path: indexfUyYJ35gmlMeta?.path ?? "/fr/resources",
    meta: indexfUyYJ35gmlMeta || {},
    alias: indexfUyYJ35gmlMeta?.alias || [],
    redirect: indexfUyYJ35gmlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: indexfUyYJ35gmlMeta?.name ?? "resources___fr-ca",
    path: indexfUyYJ35gmlMeta?.path ?? "/fr-ca/resources",
    meta: indexfUyYJ35gmlMeta || {},
    alias: indexfUyYJ35gmlMeta?.alias || [],
    redirect: indexfUyYJ35gmlMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934NfcnY1JzJMeta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_934NfcnY1JzJMeta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_934NfcnY1JzJMeta || {},
    alias: _91slug_934NfcnY1JzJMeta?.alias || [],
    redirect: _91slug_934NfcnY1JzJMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934NfcnY1JzJMeta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_934NfcnY1JzJMeta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_934NfcnY1JzJMeta || {},
    alias: _91slug_934NfcnY1JzJMeta?.alias || [],
    redirect: _91slug_934NfcnY1JzJMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934NfcnY1JzJMeta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_934NfcnY1JzJMeta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_934NfcnY1JzJMeta || {},
    alias: _91slug_934NfcnY1JzJMeta?.alias || [],
    redirect: _91slug_934NfcnY1JzJMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934NfcnY1JzJMeta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_934NfcnY1JzJMeta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_934NfcnY1JzJMeta || {},
    alias: _91slug_934NfcnY1JzJMeta?.alias || [],
    redirect: _91slug_934NfcnY1JzJMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934NfcnY1JzJMeta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_934NfcnY1JzJMeta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_934NfcnY1JzJMeta || {},
    alias: _91slug_934NfcnY1JzJMeta?.alias || [],
    redirect: _91slug_934NfcnY1JzJMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934NfcnY1JzJMeta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_934NfcnY1JzJMeta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_934NfcnY1JzJMeta || {},
    alias: _91slug_934NfcnY1JzJMeta?.alias || [],
    redirect: _91slug_934NfcnY1JzJMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934NfcnY1JzJMeta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_934NfcnY1JzJMeta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_934NfcnY1JzJMeta || {},
    alias: _91slug_934NfcnY1JzJMeta?.alias || [],
    redirect: _91slug_934NfcnY1JzJMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934NfcnY1JzJMeta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_934NfcnY1JzJMeta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_934NfcnY1JzJMeta || {},
    alias: _91slug_934NfcnY1JzJMeta?.alias || [],
    redirect: _91slug_934NfcnY1JzJMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sIIiOK2yTEMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_93sIIiOK2yTEMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_93sIIiOK2yTEMeta || {},
    alias: _91slug_93sIIiOK2yTEMeta?.alias || [],
    redirect: _91slug_93sIIiOK2yTEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sIIiOK2yTEMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_93sIIiOK2yTEMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_93sIIiOK2yTEMeta || {},
    alias: _91slug_93sIIiOK2yTEMeta?.alias || [],
    redirect: _91slug_93sIIiOK2yTEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sIIiOK2yTEMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_93sIIiOK2yTEMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_93sIIiOK2yTEMeta || {},
    alias: _91slug_93sIIiOK2yTEMeta?.alias || [],
    redirect: _91slug_93sIIiOK2yTEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sIIiOK2yTEMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_93sIIiOK2yTEMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_93sIIiOK2yTEMeta || {},
    alias: _91slug_93sIIiOK2yTEMeta?.alias || [],
    redirect: _91slug_93sIIiOK2yTEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sIIiOK2yTEMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_93sIIiOK2yTEMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_93sIIiOK2yTEMeta || {},
    alias: _91slug_93sIIiOK2yTEMeta?.alias || [],
    redirect: _91slug_93sIIiOK2yTEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sIIiOK2yTEMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_93sIIiOK2yTEMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_93sIIiOK2yTEMeta || {},
    alias: _91slug_93sIIiOK2yTEMeta?.alias || [],
    redirect: _91slug_93sIIiOK2yTEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sIIiOK2yTEMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_93sIIiOK2yTEMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_93sIIiOK2yTEMeta || {},
    alias: _91slug_93sIIiOK2yTEMeta?.alias || [],
    redirect: _91slug_93sIIiOK2yTEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sIIiOK2yTEMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_93sIIiOK2yTEMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_93sIIiOK2yTEMeta || {},
    alias: _91slug_93sIIiOK2yTEMeta?.alias || [],
    redirect: _91slug_93sIIiOK2yTEMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexiDJYkZIXYOMeta?.name ?? "resources-marketplace___en-US",
    path: indexiDJYkZIXYOMeta?.path ?? "/resources/marketplace",
    meta: indexiDJYkZIXYOMeta || {},
    alias: indexiDJYkZIXYOMeta?.alias || [],
    redirect: indexiDJYkZIXYOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexiDJYkZIXYOMeta?.name ?? "resources-marketplace___ca",
    path: indexiDJYkZIXYOMeta?.path ?? "/ca/resources/marketplace",
    meta: indexiDJYkZIXYOMeta || {},
    alias: indexiDJYkZIXYOMeta?.alias || [],
    redirect: indexiDJYkZIXYOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexiDJYkZIXYOMeta?.name ?? "resources-marketplace___nl",
    path: indexiDJYkZIXYOMeta?.path ?? "/nl/resources/marketplace",
    meta: indexiDJYkZIXYOMeta || {},
    alias: indexiDJYkZIXYOMeta?.alias || [],
    redirect: indexiDJYkZIXYOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexiDJYkZIXYOMeta?.name ?? "resources-marketplace___uk",
    path: indexiDJYkZIXYOMeta?.path ?? "/uk/resources/marketplace",
    meta: indexiDJYkZIXYOMeta || {},
    alias: indexiDJYkZIXYOMeta?.alias || [],
    redirect: indexiDJYkZIXYOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexiDJYkZIXYOMeta?.name ?? "resources-marketplace___de",
    path: indexiDJYkZIXYOMeta?.path ?? "/de/resources/marketplace",
    meta: indexiDJYkZIXYOMeta || {},
    alias: indexiDJYkZIXYOMeta?.alias || [],
    redirect: indexiDJYkZIXYOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexiDJYkZIXYOMeta?.name ?? "resources-marketplace___mx",
    path: indexiDJYkZIXYOMeta?.path ?? "/mx/resources/marketplace",
    meta: indexiDJYkZIXYOMeta || {},
    alias: indexiDJYkZIXYOMeta?.alias || [],
    redirect: indexiDJYkZIXYOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexiDJYkZIXYOMeta?.name ?? "resources-marketplace___fr",
    path: indexiDJYkZIXYOMeta?.path ?? "/fr/resources/marketplace",
    meta: indexiDJYkZIXYOMeta || {},
    alias: indexiDJYkZIXYOMeta?.alias || [],
    redirect: indexiDJYkZIXYOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: indexiDJYkZIXYOMeta?.name ?? "resources-marketplace___fr-ca",
    path: indexiDJYkZIXYOMeta?.path ?? "/fr-ca/resources/marketplace",
    meta: indexiDJYkZIXYOMeta || {},
    alias: indexiDJYkZIXYOMeta?.alias || [],
    redirect: indexiDJYkZIXYOMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaralHKuJMVYqPMeta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsaralHKuJMVYqPMeta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaralHKuJMVYqPMeta || {},
    alias: build_45with_45samsaralHKuJMVYqPMeta?.alias || [],
    redirect: build_45with_45samsaralHKuJMVYqPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaralHKuJMVYqPMeta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsaralHKuJMVYqPMeta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaralHKuJMVYqPMeta || {},
    alias: build_45with_45samsaralHKuJMVYqPMeta?.alias || [],
    redirect: build_45with_45samsaralHKuJMVYqPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaralHKuJMVYqPMeta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsaralHKuJMVYqPMeta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaralHKuJMVYqPMeta || {},
    alias: build_45with_45samsaralHKuJMVYqPMeta?.alias || [],
    redirect: build_45with_45samsaralHKuJMVYqPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaralHKuJMVYqPMeta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsaralHKuJMVYqPMeta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaralHKuJMVYqPMeta || {},
    alias: build_45with_45samsaralHKuJMVYqPMeta?.alias || [],
    redirect: build_45with_45samsaralHKuJMVYqPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaralHKuJMVYqPMeta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsaralHKuJMVYqPMeta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaralHKuJMVYqPMeta || {},
    alias: build_45with_45samsaralHKuJMVYqPMeta?.alias || [],
    redirect: build_45with_45samsaralHKuJMVYqPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaralHKuJMVYqPMeta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsaralHKuJMVYqPMeta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaralHKuJMVYqPMeta || {},
    alias: build_45with_45samsaralHKuJMVYqPMeta?.alias || [],
    redirect: build_45with_45samsaralHKuJMVYqPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaralHKuJMVYqPMeta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsaralHKuJMVYqPMeta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaralHKuJMVYqPMeta || {},
    alias: build_45with_45samsaralHKuJMVYqPMeta?.alias || [],
    redirect: build_45with_45samsaralHKuJMVYqPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaralHKuJMVYqPMeta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsaralHKuJMVYqPMeta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaralHKuJMVYqPMeta || {},
    alias: build_45with_45samsaralHKuJMVYqPMeta?.alias || [],
    redirect: build_45with_45samsaralHKuJMVYqPMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsFrrJ4aIlQzMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programsFrrJ4aIlQzMeta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsFrrJ4aIlQzMeta || {},
    alias: evolve_45insurance_45programsFrrJ4aIlQzMeta?.alias || [],
    redirect: evolve_45insurance_45programsFrrJ4aIlQzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsFrrJ4aIlQzMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programsFrrJ4aIlQzMeta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsFrrJ4aIlQzMeta || {},
    alias: evolve_45insurance_45programsFrrJ4aIlQzMeta?.alias || [],
    redirect: evolve_45insurance_45programsFrrJ4aIlQzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsFrrJ4aIlQzMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programsFrrJ4aIlQzMeta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsFrrJ4aIlQzMeta || {},
    alias: evolve_45insurance_45programsFrrJ4aIlQzMeta?.alias || [],
    redirect: evolve_45insurance_45programsFrrJ4aIlQzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsFrrJ4aIlQzMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programsFrrJ4aIlQzMeta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsFrrJ4aIlQzMeta || {},
    alias: evolve_45insurance_45programsFrrJ4aIlQzMeta?.alias || [],
    redirect: evolve_45insurance_45programsFrrJ4aIlQzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsFrrJ4aIlQzMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programsFrrJ4aIlQzMeta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsFrrJ4aIlQzMeta || {},
    alias: evolve_45insurance_45programsFrrJ4aIlQzMeta?.alias || [],
    redirect: evolve_45insurance_45programsFrrJ4aIlQzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsFrrJ4aIlQzMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programsFrrJ4aIlQzMeta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsFrrJ4aIlQzMeta || {},
    alias: evolve_45insurance_45programsFrrJ4aIlQzMeta?.alias || [],
    redirect: evolve_45insurance_45programsFrrJ4aIlQzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsFrrJ4aIlQzMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programsFrrJ4aIlQzMeta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsFrrJ4aIlQzMeta || {},
    alias: evolve_45insurance_45programsFrrJ4aIlQzMeta?.alias || [],
    redirect: evolve_45insurance_45programsFrrJ4aIlQzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programsFrrJ4aIlQzMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programsFrrJ4aIlQzMeta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programsFrrJ4aIlQzMeta || {},
    alias: evolve_45insurance_45programsFrrJ4aIlQzMeta?.alias || [],
    redirect: evolve_45insurance_45programsFrrJ4aIlQzMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: indexca3TY5VxaWMeta?.name ?? "resources-partner-programs___en-US",
    path: indexca3TY5VxaWMeta?.path ?? "/resources/partner-programs",
    meta: indexca3TY5VxaWMeta || {},
    alias: indexca3TY5VxaWMeta?.alias || [],
    redirect: indexca3TY5VxaWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexca3TY5VxaWMeta?.name ?? "resources-partner-programs___ca",
    path: indexca3TY5VxaWMeta?.path ?? "/ca/resources/partner-programs",
    meta: indexca3TY5VxaWMeta || {},
    alias: indexca3TY5VxaWMeta?.alias || [],
    redirect: indexca3TY5VxaWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexca3TY5VxaWMeta?.name ?? "resources-partner-programs___nl",
    path: indexca3TY5VxaWMeta?.path ?? "/nl/resources/partner-programs",
    meta: indexca3TY5VxaWMeta || {},
    alias: indexca3TY5VxaWMeta?.alias || [],
    redirect: indexca3TY5VxaWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexca3TY5VxaWMeta?.name ?? "resources-partner-programs___uk",
    path: indexca3TY5VxaWMeta?.path ?? "/uk/resources/partner-programs",
    meta: indexca3TY5VxaWMeta || {},
    alias: indexca3TY5VxaWMeta?.alias || [],
    redirect: indexca3TY5VxaWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexca3TY5VxaWMeta?.name ?? "resources-partner-programs___de",
    path: indexca3TY5VxaWMeta?.path ?? "/de/resources/partner-programs",
    meta: indexca3TY5VxaWMeta || {},
    alias: indexca3TY5VxaWMeta?.alias || [],
    redirect: indexca3TY5VxaWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexca3TY5VxaWMeta?.name ?? "resources-partner-programs___mx",
    path: indexca3TY5VxaWMeta?.path ?? "/mx/resources/partner-programs",
    meta: indexca3TY5VxaWMeta || {},
    alias: indexca3TY5VxaWMeta?.alias || [],
    redirect: indexca3TY5VxaWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexca3TY5VxaWMeta?.name ?? "resources-partner-programs___fr",
    path: indexca3TY5VxaWMeta?.path ?? "/fr/resources/partner-programs",
    meta: indexca3TY5VxaWMeta || {},
    alias: indexca3TY5VxaWMeta?.alias || [],
    redirect: indexca3TY5VxaWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexca3TY5VxaWMeta?.name ?? "resources-partner-programs___fr-ca",
    path: indexca3TY5VxaWMeta?.path ?? "/fr-ca/resources/partner-programs",
    meta: indexca3TY5VxaWMeta || {},
    alias: indexca3TY5VxaWMeta?.alias || [],
    redirect: indexca3TY5VxaWMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45services90LQOCu7wHMeta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45services90LQOCu7wHMeta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45services90LQOCu7wHMeta || {},
    alias: provide_45services90LQOCu7wHMeta?.alias || [],
    redirect: provide_45services90LQOCu7wHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services90LQOCu7wHMeta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45services90LQOCu7wHMeta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45services90LQOCu7wHMeta || {},
    alias: provide_45services90LQOCu7wHMeta?.alias || [],
    redirect: provide_45services90LQOCu7wHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services90LQOCu7wHMeta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45services90LQOCu7wHMeta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45services90LQOCu7wHMeta || {},
    alias: provide_45services90LQOCu7wHMeta?.alias || [],
    redirect: provide_45services90LQOCu7wHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services90LQOCu7wHMeta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45services90LQOCu7wHMeta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45services90LQOCu7wHMeta || {},
    alias: provide_45services90LQOCu7wHMeta?.alias || [],
    redirect: provide_45services90LQOCu7wHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services90LQOCu7wHMeta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45services90LQOCu7wHMeta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45services90LQOCu7wHMeta || {},
    alias: provide_45services90LQOCu7wHMeta?.alias || [],
    redirect: provide_45services90LQOCu7wHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services90LQOCu7wHMeta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45services90LQOCu7wHMeta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45services90LQOCu7wHMeta || {},
    alias: provide_45services90LQOCu7wHMeta?.alias || [],
    redirect: provide_45services90LQOCu7wHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services90LQOCu7wHMeta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45services90LQOCu7wHMeta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45services90LQOCu7wHMeta || {},
    alias: provide_45services90LQOCu7wHMeta?.alias || [],
    redirect: provide_45services90LQOCu7wHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45services90LQOCu7wHMeta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45services90LQOCu7wHMeta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45services90LQOCu7wHMeta || {},
    alias: provide_45services90LQOCu7wHMeta?.alias || [],
    redirect: provide_45services90LQOCu7wHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraS2VvV1Fyb2Meta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsaraS2VvV1Fyb2Meta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraS2VvV1Fyb2Meta || {},
    alias: sell_45samsaraS2VvV1Fyb2Meta?.alias || [],
    redirect: sell_45samsaraS2VvV1Fyb2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraS2VvV1Fyb2Meta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsaraS2VvV1Fyb2Meta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraS2VvV1Fyb2Meta || {},
    alias: sell_45samsaraS2VvV1Fyb2Meta?.alias || [],
    redirect: sell_45samsaraS2VvV1Fyb2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraS2VvV1Fyb2Meta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsaraS2VvV1Fyb2Meta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraS2VvV1Fyb2Meta || {},
    alias: sell_45samsaraS2VvV1Fyb2Meta?.alias || [],
    redirect: sell_45samsaraS2VvV1Fyb2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraS2VvV1Fyb2Meta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsaraS2VvV1Fyb2Meta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraS2VvV1Fyb2Meta || {},
    alias: sell_45samsaraS2VvV1Fyb2Meta?.alias || [],
    redirect: sell_45samsaraS2VvV1Fyb2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraS2VvV1Fyb2Meta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsaraS2VvV1Fyb2Meta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraS2VvV1Fyb2Meta || {},
    alias: sell_45samsaraS2VvV1Fyb2Meta?.alias || [],
    redirect: sell_45samsaraS2VvV1Fyb2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraS2VvV1Fyb2Meta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsaraS2VvV1Fyb2Meta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraS2VvV1Fyb2Meta || {},
    alias: sell_45samsaraS2VvV1Fyb2Meta?.alias || [],
    redirect: sell_45samsaraS2VvV1Fyb2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraS2VvV1Fyb2Meta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsaraS2VvV1Fyb2Meta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraS2VvV1Fyb2Meta || {},
    alias: sell_45samsaraS2VvV1Fyb2Meta?.alias || [],
    redirect: sell_45samsaraS2VvV1Fyb2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsaraS2VvV1Fyb2Meta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsaraS2VvV1Fyb2Meta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsaraS2VvV1Fyb2Meta || {},
    alias: sell_45samsaraS2VvV1Fyb2Meta?.alias || [],
    redirect: sell_45samsaraS2VvV1Fyb2Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: tourkESnyehwixMeta?.name ?? "resources-tour___en-US",
    path: tourkESnyehwixMeta?.path ?? "/resources/tour",
    meta: tourkESnyehwixMeta || {},
    alias: tourkESnyehwixMeta?.alias || [],
    redirect: tourkESnyehwixMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourkESnyehwixMeta?.name ?? "resources-tour___ca",
    path: tourkESnyehwixMeta?.path ?? "/ca/resources/tour",
    meta: tourkESnyehwixMeta || {},
    alias: tourkESnyehwixMeta?.alias || [],
    redirect: tourkESnyehwixMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourkESnyehwixMeta?.name ?? "resources-tour___nl",
    path: tourkESnyehwixMeta?.path ?? "/nl/resources/tour",
    meta: tourkESnyehwixMeta || {},
    alias: tourkESnyehwixMeta?.alias || [],
    redirect: tourkESnyehwixMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourkESnyehwixMeta?.name ?? "resources-tour___uk",
    path: tourkESnyehwixMeta?.path ?? "/uk/resources/tour",
    meta: tourkESnyehwixMeta || {},
    alias: tourkESnyehwixMeta?.alias || [],
    redirect: tourkESnyehwixMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourkESnyehwixMeta?.name ?? "resources-tour___de",
    path: tourkESnyehwixMeta?.path ?? "/de/resources/tour",
    meta: tourkESnyehwixMeta || {},
    alias: tourkESnyehwixMeta?.alias || [],
    redirect: tourkESnyehwixMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourkESnyehwixMeta?.name ?? "resources-tour___mx",
    path: tourkESnyehwixMeta?.path ?? "/mx/resources/tour",
    meta: tourkESnyehwixMeta || {},
    alias: tourkESnyehwixMeta?.alias || [],
    redirect: tourkESnyehwixMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourkESnyehwixMeta?.name ?? "resources-tour___fr",
    path: tourkESnyehwixMeta?.path ?? "/fr/resources/tour",
    meta: tourkESnyehwixMeta || {},
    alias: tourkESnyehwixMeta?.alias || [],
    redirect: tourkESnyehwixMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourkESnyehwixMeta?.name ?? "resources-tour___fr-ca",
    path: tourkESnyehwixMeta?.path ?? "/fr-ca/resources/tour",
    meta: tourkESnyehwixMeta || {},
    alias: tourkESnyehwixMeta?.alias || [],
    redirect: tourkESnyehwixMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videosJWIikqaR5wMeta?.name ?? "resources-videos___en-US",
    path: videosJWIikqaR5wMeta?.path ?? "/resources/videos",
    meta: videosJWIikqaR5wMeta || {},
    alias: videosJWIikqaR5wMeta?.alias || [],
    redirect: videosJWIikqaR5wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosJWIikqaR5wMeta?.name ?? "resources-videos___ca",
    path: videosJWIikqaR5wMeta?.path ?? "/ca/resources/videos",
    meta: videosJWIikqaR5wMeta || {},
    alias: videosJWIikqaR5wMeta?.alias || [],
    redirect: videosJWIikqaR5wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosJWIikqaR5wMeta?.name ?? "resources-videos___nl",
    path: videosJWIikqaR5wMeta?.path ?? "/nl/resources/videos",
    meta: videosJWIikqaR5wMeta || {},
    alias: videosJWIikqaR5wMeta?.alias || [],
    redirect: videosJWIikqaR5wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosJWIikqaR5wMeta?.name ?? "resources-videos___uk",
    path: videosJWIikqaR5wMeta?.path ?? "/uk/resources/videos",
    meta: videosJWIikqaR5wMeta || {},
    alias: videosJWIikqaR5wMeta?.alias || [],
    redirect: videosJWIikqaR5wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosJWIikqaR5wMeta?.name ?? "resources-videos___de",
    path: videosJWIikqaR5wMeta?.path ?? "/de/resources/videos",
    meta: videosJWIikqaR5wMeta || {},
    alias: videosJWIikqaR5wMeta?.alias || [],
    redirect: videosJWIikqaR5wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosJWIikqaR5wMeta?.name ?? "resources-videos___mx",
    path: videosJWIikqaR5wMeta?.path ?? "/mx/resources/videos",
    meta: videosJWIikqaR5wMeta || {},
    alias: videosJWIikqaR5wMeta?.alias || [],
    redirect: videosJWIikqaR5wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosJWIikqaR5wMeta?.name ?? "resources-videos___fr",
    path: videosJWIikqaR5wMeta?.path ?? "/fr/resources/videos",
    meta: videosJWIikqaR5wMeta || {},
    alias: videosJWIikqaR5wMeta?.alias || [],
    redirect: videosJWIikqaR5wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videosJWIikqaR5wMeta?.name ?? "resources-videos___fr-ca",
    path: videosJWIikqaR5wMeta?.path ?? "/fr-ca/resources/videos",
    meta: videosJWIikqaR5wMeta || {},
    alias: videosJWIikqaR5wMeta?.alias || [],
    redirect: videosJWIikqaR5wMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: index9URiEWL8zhMeta?.name ?? "roi-calculator___en-US",
    path: index9URiEWL8zhMeta?.path ?? "/roi-calculator",
    meta: index9URiEWL8zhMeta || {},
    alias: index9URiEWL8zhMeta?.alias || [],
    redirect: index9URiEWL8zhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index9URiEWL8zhMeta?.name ?? "roi-calculator___ca",
    path: index9URiEWL8zhMeta?.path ?? "/ca/roi-calculator",
    meta: index9URiEWL8zhMeta || {},
    alias: index9URiEWL8zhMeta?.alias || [],
    redirect: index9URiEWL8zhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index9URiEWL8zhMeta?.name ?? "roi-calculator___nl",
    path: index9URiEWL8zhMeta?.path ?? "/nl/roi-calculator",
    meta: index9URiEWL8zhMeta || {},
    alias: index9URiEWL8zhMeta?.alias || [],
    redirect: index9URiEWL8zhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index9URiEWL8zhMeta?.name ?? "roi-calculator___uk",
    path: index9URiEWL8zhMeta?.path ?? "/uk/roi-calculator",
    meta: index9URiEWL8zhMeta || {},
    alias: index9URiEWL8zhMeta?.alias || [],
    redirect: index9URiEWL8zhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index9URiEWL8zhMeta?.name ?? "roi-calculator___de",
    path: index9URiEWL8zhMeta?.path ?? "/de/roi-calculator",
    meta: index9URiEWL8zhMeta || {},
    alias: index9URiEWL8zhMeta?.alias || [],
    redirect: index9URiEWL8zhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index9URiEWL8zhMeta?.name ?? "roi-calculator___mx",
    path: index9URiEWL8zhMeta?.path ?? "/mx/roi-calculator",
    meta: index9URiEWL8zhMeta || {},
    alias: index9URiEWL8zhMeta?.alias || [],
    redirect: index9URiEWL8zhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index9URiEWL8zhMeta?.name ?? "roi-calculator___fr",
    path: index9URiEWL8zhMeta?.path ?? "/fr/roi-calculator",
    meta: index9URiEWL8zhMeta || {},
    alias: index9URiEWL8zhMeta?.alias || [],
    redirect: index9URiEWL8zhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: index9URiEWL8zhMeta?.name ?? "roi-calculator___fr-ca",
    path: index9URiEWL8zhMeta?.path ?? "/fr-ca/roi-calculator",
    meta: index9URiEWL8zhMeta || {},
    alias: index9URiEWL8zhMeta?.alias || [],
    redirect: index9URiEWL8zhMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturestCXtU9bcR9Meta?.name ?? "samsara-ventures___en-US",
    path: samsara_45venturestCXtU9bcR9Meta?.path ?? "/samsara-ventures",
    meta: samsara_45venturestCXtU9bcR9Meta || {},
    alias: samsara_45venturestCXtU9bcR9Meta?.alias || [],
    redirect: samsara_45venturestCXtU9bcR9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturestCXtU9bcR9Meta?.name ?? "samsara-ventures___ca",
    path: samsara_45venturestCXtU9bcR9Meta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45venturestCXtU9bcR9Meta || {},
    alias: samsara_45venturestCXtU9bcR9Meta?.alias || [],
    redirect: samsara_45venturestCXtU9bcR9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturestCXtU9bcR9Meta?.name ?? "samsara-ventures___nl",
    path: samsara_45venturestCXtU9bcR9Meta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45venturestCXtU9bcR9Meta || {},
    alias: samsara_45venturestCXtU9bcR9Meta?.alias || [],
    redirect: samsara_45venturestCXtU9bcR9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturestCXtU9bcR9Meta?.name ?? "samsara-ventures___uk",
    path: samsara_45venturestCXtU9bcR9Meta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45venturestCXtU9bcR9Meta || {},
    alias: samsara_45venturestCXtU9bcR9Meta?.alias || [],
    redirect: samsara_45venturestCXtU9bcR9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturestCXtU9bcR9Meta?.name ?? "samsara-ventures___de",
    path: samsara_45venturestCXtU9bcR9Meta?.path ?? "/de/samsara-ventures",
    meta: samsara_45venturestCXtU9bcR9Meta || {},
    alias: samsara_45venturestCXtU9bcR9Meta?.alias || [],
    redirect: samsara_45venturestCXtU9bcR9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturestCXtU9bcR9Meta?.name ?? "samsara-ventures___mx",
    path: samsara_45venturestCXtU9bcR9Meta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45venturestCXtU9bcR9Meta || {},
    alias: samsara_45venturestCXtU9bcR9Meta?.alias || [],
    redirect: samsara_45venturestCXtU9bcR9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturestCXtU9bcR9Meta?.name ?? "samsara-ventures___fr",
    path: samsara_45venturestCXtU9bcR9Meta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45venturestCXtU9bcR9Meta || {},
    alias: samsara_45venturestCXtU9bcR9Meta?.alias || [],
    redirect: samsara_45venturestCXtU9bcR9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturestCXtU9bcR9Meta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45venturestCXtU9bcR9Meta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45venturestCXtU9bcR9Meta || {},
    alias: samsara_45venturestCXtU9bcR9Meta?.alias || [],
    redirect: samsara_45venturestCXtU9bcR9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summitrNtqf48VBSMeta?.name ?? "security-summit___en-US",
    path: security_45summitrNtqf48VBSMeta?.path ?? "/security-summit",
    meta: security_45summitrNtqf48VBSMeta || {},
    alias: security_45summitrNtqf48VBSMeta?.alias || [],
    redirect: security_45summitrNtqf48VBSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitrNtqf48VBSMeta?.name ?? "security-summit___ca",
    path: security_45summitrNtqf48VBSMeta?.path ?? "/ca/security-summit",
    meta: security_45summitrNtqf48VBSMeta || {},
    alias: security_45summitrNtqf48VBSMeta?.alias || [],
    redirect: security_45summitrNtqf48VBSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitrNtqf48VBSMeta?.name ?? "security-summit___nl",
    path: security_45summitrNtqf48VBSMeta?.path ?? "/nl/security-summit",
    meta: security_45summitrNtqf48VBSMeta || {},
    alias: security_45summitrNtqf48VBSMeta?.alias || [],
    redirect: security_45summitrNtqf48VBSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitrNtqf48VBSMeta?.name ?? "security-summit___uk",
    path: security_45summitrNtqf48VBSMeta?.path ?? "/uk/security-summit",
    meta: security_45summitrNtqf48VBSMeta || {},
    alias: security_45summitrNtqf48VBSMeta?.alias || [],
    redirect: security_45summitrNtqf48VBSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitrNtqf48VBSMeta?.name ?? "security-summit___de",
    path: security_45summitrNtqf48VBSMeta?.path ?? "/de/security-summit",
    meta: security_45summitrNtqf48VBSMeta || {},
    alias: security_45summitrNtqf48VBSMeta?.alias || [],
    redirect: security_45summitrNtqf48VBSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitrNtqf48VBSMeta?.name ?? "security-summit___mx",
    path: security_45summitrNtqf48VBSMeta?.path ?? "/mx/security-summit",
    meta: security_45summitrNtqf48VBSMeta || {},
    alias: security_45summitrNtqf48VBSMeta?.alias || [],
    redirect: security_45summitrNtqf48VBSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitrNtqf48VBSMeta?.name ?? "security-summit___fr",
    path: security_45summitrNtqf48VBSMeta?.path ?? "/fr/security-summit",
    meta: security_45summitrNtqf48VBSMeta || {},
    alias: security_45summitrNtqf48VBSMeta?.alias || [],
    redirect: security_45summitrNtqf48VBSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitrNtqf48VBSMeta?.name ?? "security-summit___fr-ca",
    path: security_45summitrNtqf48VBSMeta?.path ?? "/fr-ca/security-summit",
    meta: security_45summitrNtqf48VBSMeta || {},
    alias: security_45summitrNtqf48VBSMeta?.alias || [],
    redirect: security_45summitrNtqf48VBSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qS2DwTA9mkMeta?.name ?? "solutions-prefix-slug___en-US",
    path: _91slug_93qS2DwTA9mkMeta?.path ?? "/solutions/:prefix()/:slug()",
    meta: _91slug_93qS2DwTA9mkMeta || {},
    alias: _91slug_93qS2DwTA9mkMeta?.alias || [],
    redirect: _91slug_93qS2DwTA9mkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qS2DwTA9mkMeta?.name ?? "solutions-prefix-slug___ca",
    path: _91slug_93qS2DwTA9mkMeta?.path ?? "/ca/solutions/:prefix()/:slug()",
    meta: _91slug_93qS2DwTA9mkMeta || {},
    alias: _91slug_93qS2DwTA9mkMeta?.alias || [],
    redirect: _91slug_93qS2DwTA9mkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qS2DwTA9mkMeta?.name ?? "solutions-prefix-slug___nl",
    path: _91slug_93qS2DwTA9mkMeta?.path ?? "/nl/solutions/:prefix()/:slug()",
    meta: _91slug_93qS2DwTA9mkMeta || {},
    alias: _91slug_93qS2DwTA9mkMeta?.alias || [],
    redirect: _91slug_93qS2DwTA9mkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qS2DwTA9mkMeta?.name ?? "solutions-prefix-slug___uk",
    path: _91slug_93qS2DwTA9mkMeta?.path ?? "/uk/solutions/:prefix()/:slug()",
    meta: _91slug_93qS2DwTA9mkMeta || {},
    alias: _91slug_93qS2DwTA9mkMeta?.alias || [],
    redirect: _91slug_93qS2DwTA9mkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qS2DwTA9mkMeta?.name ?? "solutions-prefix-slug___de",
    path: _91slug_93qS2DwTA9mkMeta?.path ?? "/de/solutions/:prefix()/:slug()",
    meta: _91slug_93qS2DwTA9mkMeta || {},
    alias: _91slug_93qS2DwTA9mkMeta?.alias || [],
    redirect: _91slug_93qS2DwTA9mkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qS2DwTA9mkMeta?.name ?? "solutions-prefix-slug___mx",
    path: _91slug_93qS2DwTA9mkMeta?.path ?? "/mx/solutions/:prefix()/:slug()",
    meta: _91slug_93qS2DwTA9mkMeta || {},
    alias: _91slug_93qS2DwTA9mkMeta?.alias || [],
    redirect: _91slug_93qS2DwTA9mkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qS2DwTA9mkMeta?.name ?? "solutions-prefix-slug___fr",
    path: _91slug_93qS2DwTA9mkMeta?.path ?? "/fr/solutions/:prefix()/:slug()",
    meta: _91slug_93qS2DwTA9mkMeta || {},
    alias: _91slug_93qS2DwTA9mkMeta?.alias || [],
    redirect: _91slug_93qS2DwTA9mkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qS2DwTA9mkMeta?.name ?? "solutions-prefix-slug___fr-ca",
    path: _91slug_93qS2DwTA9mkMeta?.path ?? "/fr-ca/solutions/:prefix()/:slug()",
    meta: _91slug_93qS2DwTA9mkMeta || {},
    alias: _91slug_93qS2DwTA9mkMeta?.alias || [],
    redirect: _91slug_93qS2DwTA9mkMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934lfuVQmOE6Meta?.name ?? "solutions-slug___en-US",
    path: _91slug_934lfuVQmOE6Meta?.path ?? "/solutions/:slug()",
    meta: _91slug_934lfuVQmOE6Meta || {},
    alias: _91slug_934lfuVQmOE6Meta?.alias || [],
    redirect: _91slug_934lfuVQmOE6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934lfuVQmOE6Meta?.name ?? "solutions-slug___ca",
    path: _91slug_934lfuVQmOE6Meta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_934lfuVQmOE6Meta || {},
    alias: _91slug_934lfuVQmOE6Meta?.alias || [],
    redirect: _91slug_934lfuVQmOE6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934lfuVQmOE6Meta?.name ?? "solutions-slug___nl",
    path: _91slug_934lfuVQmOE6Meta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_934lfuVQmOE6Meta || {},
    alias: _91slug_934lfuVQmOE6Meta?.alias || [],
    redirect: _91slug_934lfuVQmOE6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934lfuVQmOE6Meta?.name ?? "solutions-slug___uk",
    path: _91slug_934lfuVQmOE6Meta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_934lfuVQmOE6Meta || {},
    alias: _91slug_934lfuVQmOE6Meta?.alias || [],
    redirect: _91slug_934lfuVQmOE6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934lfuVQmOE6Meta?.name ?? "solutions-slug___de",
    path: _91slug_934lfuVQmOE6Meta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_934lfuVQmOE6Meta || {},
    alias: _91slug_934lfuVQmOE6Meta?.alias || [],
    redirect: _91slug_934lfuVQmOE6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934lfuVQmOE6Meta?.name ?? "solutions-slug___mx",
    path: _91slug_934lfuVQmOE6Meta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_934lfuVQmOE6Meta || {},
    alias: _91slug_934lfuVQmOE6Meta?.alias || [],
    redirect: _91slug_934lfuVQmOE6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934lfuVQmOE6Meta?.name ?? "solutions-slug___fr",
    path: _91slug_934lfuVQmOE6Meta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_934lfuVQmOE6Meta || {},
    alias: _91slug_934lfuVQmOE6Meta?.alias || [],
    redirect: _91slug_934lfuVQmOE6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_934lfuVQmOE6Meta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_934lfuVQmOE6Meta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_934lfuVQmOE6Meta || {},
    alias: _91slug_934lfuVQmOE6Meta?.alias || [],
    redirect: _91slug_934lfuVQmOE6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexqxh7Nmn38OMeta?.name ?? "solutions___en-US",
    path: indexqxh7Nmn38OMeta?.path ?? "/solutions",
    meta: indexqxh7Nmn38OMeta || {},
    alias: indexqxh7Nmn38OMeta?.alias || [],
    redirect: indexqxh7Nmn38OMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexqxh7Nmn38OMeta?.name ?? "solutions___ca",
    path: indexqxh7Nmn38OMeta?.path ?? "/ca/solutions",
    meta: indexqxh7Nmn38OMeta || {},
    alias: indexqxh7Nmn38OMeta?.alias || [],
    redirect: indexqxh7Nmn38OMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexqxh7Nmn38OMeta?.name ?? "solutions___nl",
    path: indexqxh7Nmn38OMeta?.path ?? "/nl/solutions",
    meta: indexqxh7Nmn38OMeta || {},
    alias: indexqxh7Nmn38OMeta?.alias || [],
    redirect: indexqxh7Nmn38OMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexqxh7Nmn38OMeta?.name ?? "solutions___uk",
    path: indexqxh7Nmn38OMeta?.path ?? "/uk/solutions",
    meta: indexqxh7Nmn38OMeta || {},
    alias: indexqxh7Nmn38OMeta?.alias || [],
    redirect: indexqxh7Nmn38OMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexqxh7Nmn38OMeta?.name ?? "solutions___de",
    path: indexqxh7Nmn38OMeta?.path ?? "/de/solutions",
    meta: indexqxh7Nmn38OMeta || {},
    alias: indexqxh7Nmn38OMeta?.alias || [],
    redirect: indexqxh7Nmn38OMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexqxh7Nmn38OMeta?.name ?? "solutions___mx",
    path: indexqxh7Nmn38OMeta?.path ?? "/mx/solutions",
    meta: indexqxh7Nmn38OMeta || {},
    alias: indexqxh7Nmn38OMeta?.alias || [],
    redirect: indexqxh7Nmn38OMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexqxh7Nmn38OMeta?.name ?? "solutions___fr",
    path: indexqxh7Nmn38OMeta?.path ?? "/fr/solutions",
    meta: indexqxh7Nmn38OMeta || {},
    alias: indexqxh7Nmn38OMeta?.alias || [],
    redirect: indexqxh7Nmn38OMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexqxh7Nmn38OMeta?.name ?? "solutions___fr-ca",
    path: indexqxh7Nmn38OMeta?.path ?? "/fr-ca/solutions",
    meta: indexqxh7Nmn38OMeta || {},
    alias: indexqxh7Nmn38OMeta?.alias || [],
    redirect: indexqxh7Nmn38OMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: securityus976xk6FLMeta?.name ?? "solutions-security___en-US",
    path: securityus976xk6FLMeta?.path ?? "/solutions/security",
    meta: securityus976xk6FLMeta || {},
    alias: securityus976xk6FLMeta?.alias || [],
    redirect: securityus976xk6FLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityus976xk6FLMeta?.name ?? "solutions-security___ca",
    path: securityus976xk6FLMeta?.path ?? "/ca/solutions/security",
    meta: securityus976xk6FLMeta || {},
    alias: securityus976xk6FLMeta?.alias || [],
    redirect: securityus976xk6FLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityus976xk6FLMeta?.name ?? "solutions-security___nl",
    path: securityus976xk6FLMeta?.path ?? "/nl/solutions/security",
    meta: securityus976xk6FLMeta || {},
    alias: securityus976xk6FLMeta?.alias || [],
    redirect: securityus976xk6FLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityus976xk6FLMeta?.name ?? "solutions-security___uk",
    path: securityus976xk6FLMeta?.path ?? "/uk/solutions/security",
    meta: securityus976xk6FLMeta || {},
    alias: securityus976xk6FLMeta?.alias || [],
    redirect: securityus976xk6FLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityus976xk6FLMeta?.name ?? "solutions-security___de",
    path: securityus976xk6FLMeta?.path ?? "/de/solutions/security",
    meta: securityus976xk6FLMeta || {},
    alias: securityus976xk6FLMeta?.alias || [],
    redirect: securityus976xk6FLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityus976xk6FLMeta?.name ?? "solutions-security___mx",
    path: securityus976xk6FLMeta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: securityus976xk6FLMeta || {},
    alias: securityus976xk6FLMeta?.alias || [],
    redirect: securityus976xk6FLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityus976xk6FLMeta?.name ?? "solutions-security___fr",
    path: securityus976xk6FLMeta?.path ?? "/fr/solutions/security",
    meta: securityus976xk6FLMeta || {},
    alias: securityus976xk6FLMeta?.alias || [],
    redirect: securityus976xk6FLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityus976xk6FLMeta?.name ?? "solutions-security___fr-ca",
    path: securityus976xk6FLMeta?.path ?? "/fr-ca/solutions/security",
    meta: securityus976xk6FLMeta || {},
    alias: securityus976xk6FLMeta?.alias || [],
    redirect: securityus976xk6FLMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: spacecd19qZV4NYMeta?.name ?? "space___en-US",
    path: spacecd19qZV4NYMeta?.path ?? "/space",
    meta: spacecd19qZV4NYMeta || {},
    alias: spacecd19qZV4NYMeta?.alias || [],
    redirect: spacecd19qZV4NYMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacecd19qZV4NYMeta?.name ?? "space___ca",
    path: spacecd19qZV4NYMeta?.path ?? "/ca/space",
    meta: spacecd19qZV4NYMeta || {},
    alias: spacecd19qZV4NYMeta?.alias || [],
    redirect: spacecd19qZV4NYMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacecd19qZV4NYMeta?.name ?? "space___nl",
    path: spacecd19qZV4NYMeta?.path ?? "/nl/space",
    meta: spacecd19qZV4NYMeta || {},
    alias: spacecd19qZV4NYMeta?.alias || [],
    redirect: spacecd19qZV4NYMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacecd19qZV4NYMeta?.name ?? "space___uk",
    path: spacecd19qZV4NYMeta?.path ?? "/uk/space",
    meta: spacecd19qZV4NYMeta || {},
    alias: spacecd19qZV4NYMeta?.alias || [],
    redirect: spacecd19qZV4NYMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacecd19qZV4NYMeta?.name ?? "space___de",
    path: spacecd19qZV4NYMeta?.path ?? "/de/space",
    meta: spacecd19qZV4NYMeta || {},
    alias: spacecd19qZV4NYMeta?.alias || [],
    redirect: spacecd19qZV4NYMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacecd19qZV4NYMeta?.name ?? "space___mx",
    path: spacecd19qZV4NYMeta?.path ?? "/mx/space",
    meta: spacecd19qZV4NYMeta || {},
    alias: spacecd19qZV4NYMeta?.alias || [],
    redirect: spacecd19qZV4NYMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacecd19qZV4NYMeta?.name ?? "space___fr",
    path: spacecd19qZV4NYMeta?.path ?? "/fr/space",
    meta: spacecd19qZV4NYMeta || {},
    alias: spacecd19qZV4NYMeta?.alias || [],
    redirect: spacecd19qZV4NYMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacecd19qZV4NYMeta?.name ?? "space___fr-ca",
    path: spacecd19qZV4NYMeta?.path ?? "/fr-ca/space",
    meta: spacecd19qZV4NYMeta || {},
    alias: spacecd19qZV4NYMeta?.alias || [],
    redirect: spacecd19qZV4NYMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: sparkvZKWohaP0gMeta?.name ?? "spark___en-US",
    path: sparkvZKWohaP0gMeta?.path ?? "/spark",
    meta: sparkvZKWohaP0gMeta || {},
    alias: sparkvZKWohaP0gMeta?.alias || [],
    redirect: sparkvZKWohaP0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkvZKWohaP0gMeta?.name ?? "spark___ca",
    path: sparkvZKWohaP0gMeta?.path ?? "/ca/spark",
    meta: sparkvZKWohaP0gMeta || {},
    alias: sparkvZKWohaP0gMeta?.alias || [],
    redirect: sparkvZKWohaP0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkvZKWohaP0gMeta?.name ?? "spark___nl",
    path: sparkvZKWohaP0gMeta?.path ?? "/nl/spark",
    meta: sparkvZKWohaP0gMeta || {},
    alias: sparkvZKWohaP0gMeta?.alias || [],
    redirect: sparkvZKWohaP0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkvZKWohaP0gMeta?.name ?? "spark___uk",
    path: sparkvZKWohaP0gMeta?.path ?? "/uk/spark",
    meta: sparkvZKWohaP0gMeta || {},
    alias: sparkvZKWohaP0gMeta?.alias || [],
    redirect: sparkvZKWohaP0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkvZKWohaP0gMeta?.name ?? "spark___de",
    path: sparkvZKWohaP0gMeta?.path ?? "/de/spark",
    meta: sparkvZKWohaP0gMeta || {},
    alias: sparkvZKWohaP0gMeta?.alias || [],
    redirect: sparkvZKWohaP0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkvZKWohaP0gMeta?.name ?? "spark___mx",
    path: sparkvZKWohaP0gMeta?.path ?? "/mx/spark",
    meta: sparkvZKWohaP0gMeta || {},
    alias: sparkvZKWohaP0gMeta?.alias || [],
    redirect: sparkvZKWohaP0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkvZKWohaP0gMeta?.name ?? "spark___fr",
    path: sparkvZKWohaP0gMeta?.path ?? "/fr/spark",
    meta: sparkvZKWohaP0gMeta || {},
    alias: sparkvZKWohaP0gMeta?.alias || [],
    redirect: sparkvZKWohaP0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: sparkvZKWohaP0gMeta?.name ?? "spark___fr-ca",
    path: sparkvZKWohaP0gMeta?.path ?? "/fr-ca/spark",
    meta: sparkvZKWohaP0gMeta || {},
    alias: sparkvZKWohaP0gMeta?.alias || [],
    redirect: sparkvZKWohaP0gMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E7oW3W1KcXMeta?.name ?? "styles-slug___en-US",
    path: _91slug_93E7oW3W1KcXMeta?.path ?? "/styles/:slug()",
    meta: _91slug_93E7oW3W1KcXMeta || {},
    alias: _91slug_93E7oW3W1KcXMeta?.alias || [],
    redirect: _91slug_93E7oW3W1KcXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E7oW3W1KcXMeta?.name ?? "styles-slug___ca",
    path: _91slug_93E7oW3W1KcXMeta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_93E7oW3W1KcXMeta || {},
    alias: _91slug_93E7oW3W1KcXMeta?.alias || [],
    redirect: _91slug_93E7oW3W1KcXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E7oW3W1KcXMeta?.name ?? "styles-slug___nl",
    path: _91slug_93E7oW3W1KcXMeta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_93E7oW3W1KcXMeta || {},
    alias: _91slug_93E7oW3W1KcXMeta?.alias || [],
    redirect: _91slug_93E7oW3W1KcXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E7oW3W1KcXMeta?.name ?? "styles-slug___uk",
    path: _91slug_93E7oW3W1KcXMeta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_93E7oW3W1KcXMeta || {},
    alias: _91slug_93E7oW3W1KcXMeta?.alias || [],
    redirect: _91slug_93E7oW3W1KcXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E7oW3W1KcXMeta?.name ?? "styles-slug___de",
    path: _91slug_93E7oW3W1KcXMeta?.path ?? "/de/styles/:slug()",
    meta: _91slug_93E7oW3W1KcXMeta || {},
    alias: _91slug_93E7oW3W1KcXMeta?.alias || [],
    redirect: _91slug_93E7oW3W1KcXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E7oW3W1KcXMeta?.name ?? "styles-slug___mx",
    path: _91slug_93E7oW3W1KcXMeta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_93E7oW3W1KcXMeta || {},
    alias: _91slug_93E7oW3W1KcXMeta?.alias || [],
    redirect: _91slug_93E7oW3W1KcXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E7oW3W1KcXMeta?.name ?? "styles-slug___fr",
    path: _91slug_93E7oW3W1KcXMeta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_93E7oW3W1KcXMeta || {},
    alias: _91slug_93E7oW3W1KcXMeta?.alias || [],
    redirect: _91slug_93E7oW3W1KcXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93E7oW3W1KcXMeta?.name ?? "styles-slug___fr-ca",
    path: _91slug_93E7oW3W1KcXMeta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_93E7oW3W1KcXMeta || {},
    alias: _91slug_93E7oW3W1KcXMeta?.alias || [],
    redirect: _91slug_93E7oW3W1KcXMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aapXIBEJymMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_93aapXIBEJymMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_93aapXIBEJymMeta || {},
    alias: _91slug_93aapXIBEJymMeta?.alias || [],
    redirect: _91slug_93aapXIBEJymMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aapXIBEJymMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_93aapXIBEJymMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_93aapXIBEJymMeta || {},
    alias: _91slug_93aapXIBEJymMeta?.alias || [],
    redirect: _91slug_93aapXIBEJymMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aapXIBEJymMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_93aapXIBEJymMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_93aapXIBEJymMeta || {},
    alias: _91slug_93aapXIBEJymMeta?.alias || [],
    redirect: _91slug_93aapXIBEJymMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aapXIBEJymMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_93aapXIBEJymMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_93aapXIBEJymMeta || {},
    alias: _91slug_93aapXIBEJymMeta?.alias || [],
    redirect: _91slug_93aapXIBEJymMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aapXIBEJymMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_93aapXIBEJymMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_93aapXIBEJymMeta || {},
    alias: _91slug_93aapXIBEJymMeta?.alias || [],
    redirect: _91slug_93aapXIBEJymMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aapXIBEJymMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_93aapXIBEJymMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_93aapXIBEJymMeta || {},
    alias: _91slug_93aapXIBEJymMeta?.alias || [],
    redirect: _91slug_93aapXIBEJymMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aapXIBEJymMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_93aapXIBEJymMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_93aapXIBEJymMeta || {},
    alias: _91slug_93aapXIBEJymMeta?.alias || [],
    redirect: _91slug_93aapXIBEJymMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aapXIBEJymMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_93aapXIBEJymMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_93aapXIBEJymMeta || {},
    alias: _91slug_93aapXIBEJymMeta?.alias || [],
    redirect: _91slug_93aapXIBEJymMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932XNRJJC65zMeta?.name ?? "support-slug___en-US",
    path: _91slug_932XNRJJC65zMeta?.path ?? "/support/:slug()",
    meta: _91slug_932XNRJJC65zMeta || {},
    alias: _91slug_932XNRJJC65zMeta?.alias || [],
    redirect: _91slug_932XNRJJC65zMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932XNRJJC65zMeta?.name ?? "support-slug___ca",
    path: _91slug_932XNRJJC65zMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_932XNRJJC65zMeta || {},
    alias: _91slug_932XNRJJC65zMeta?.alias || [],
    redirect: _91slug_932XNRJJC65zMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932XNRJJC65zMeta?.name ?? "support-slug___nl",
    path: _91slug_932XNRJJC65zMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_932XNRJJC65zMeta || {},
    alias: _91slug_932XNRJJC65zMeta?.alias || [],
    redirect: _91slug_932XNRJJC65zMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932XNRJJC65zMeta?.name ?? "support-slug___uk",
    path: _91slug_932XNRJJC65zMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_932XNRJJC65zMeta || {},
    alias: _91slug_932XNRJJC65zMeta?.alias || [],
    redirect: _91slug_932XNRJJC65zMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932XNRJJC65zMeta?.name ?? "support-slug___de",
    path: _91slug_932XNRJJC65zMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_932XNRJJC65zMeta || {},
    alias: _91slug_932XNRJJC65zMeta?.alias || [],
    redirect: _91slug_932XNRJJC65zMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932XNRJJC65zMeta?.name ?? "support-slug___mx",
    path: _91slug_932XNRJJC65zMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_932XNRJJC65zMeta || {},
    alias: _91slug_932XNRJJC65zMeta?.alias || [],
    redirect: _91slug_932XNRJJC65zMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932XNRJJC65zMeta?.name ?? "support-slug___fr",
    path: _91slug_932XNRJJC65zMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_932XNRJJC65zMeta || {},
    alias: _91slug_932XNRJJC65zMeta?.alias || [],
    redirect: _91slug_932XNRJJC65zMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932XNRJJC65zMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_932XNRJJC65zMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_932XNRJJC65zMeta || {},
    alias: _91slug_932XNRJJC65zMeta?.alias || [],
    redirect: _91slug_932XNRJJC65zMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyeqjh8B9RMmMeta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyeqjh8B9RMmMeta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policyeqjh8B9RMmMeta || {},
    alias: apps_45privacy_45policyeqjh8B9RMmMeta?.alias || [],
    redirect: apps_45privacy_45policyeqjh8B9RMmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyeqjh8B9RMmMeta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policyeqjh8B9RMmMeta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyeqjh8B9RMmMeta || {},
    alias: apps_45privacy_45policyeqjh8B9RMmMeta?.alias || [],
    redirect: apps_45privacy_45policyeqjh8B9RMmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyeqjh8B9RMmMeta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policyeqjh8B9RMmMeta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policyeqjh8B9RMmMeta || {},
    alias: apps_45privacy_45policyeqjh8B9RMmMeta?.alias || [],
    redirect: apps_45privacy_45policyeqjh8B9RMmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyeqjh8B9RMmMeta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policyeqjh8B9RMmMeta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policyeqjh8B9RMmMeta || {},
    alias: apps_45privacy_45policyeqjh8B9RMmMeta?.alias || [],
    redirect: apps_45privacy_45policyeqjh8B9RMmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyeqjh8B9RMmMeta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policyeqjh8B9RMmMeta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policyeqjh8B9RMmMeta || {},
    alias: apps_45privacy_45policyeqjh8B9RMmMeta?.alias || [],
    redirect: apps_45privacy_45policyeqjh8B9RMmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyeqjh8B9RMmMeta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policyeqjh8B9RMmMeta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policyeqjh8B9RMmMeta || {},
    alias: apps_45privacy_45policyeqjh8B9RMmMeta?.alias || [],
    redirect: apps_45privacy_45policyeqjh8B9RMmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyeqjh8B9RMmMeta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policyeqjh8B9RMmMeta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policyeqjh8B9RMmMeta || {},
    alias: apps_45privacy_45policyeqjh8B9RMmMeta?.alias || [],
    redirect: apps_45privacy_45policyeqjh8B9RMmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyeqjh8B9RMmMeta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyeqjh8B9RMmMeta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyeqjh8B9RMmMeta || {},
    alias: apps_45privacy_45policyeqjh8B9RMmMeta?.alias || [],
    redirect: apps_45privacy_45policyeqjh8B9RMmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnTkxFFQku6Meta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faqnTkxFFQku6Meta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnTkxFFQku6Meta || {},
    alias: customer_45referrals_45faqnTkxFFQku6Meta?.alias || [],
    redirect: customer_45referrals_45faqnTkxFFQku6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnTkxFFQku6Meta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faqnTkxFFQku6Meta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnTkxFFQku6Meta || {},
    alias: customer_45referrals_45faqnTkxFFQku6Meta?.alias || [],
    redirect: customer_45referrals_45faqnTkxFFQku6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnTkxFFQku6Meta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faqnTkxFFQku6Meta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnTkxFFQku6Meta || {},
    alias: customer_45referrals_45faqnTkxFFQku6Meta?.alias || [],
    redirect: customer_45referrals_45faqnTkxFFQku6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnTkxFFQku6Meta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faqnTkxFFQku6Meta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnTkxFFQku6Meta || {},
    alias: customer_45referrals_45faqnTkxFFQku6Meta?.alias || [],
    redirect: customer_45referrals_45faqnTkxFFQku6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnTkxFFQku6Meta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faqnTkxFFQku6Meta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnTkxFFQku6Meta || {},
    alias: customer_45referrals_45faqnTkxFFQku6Meta?.alias || [],
    redirect: customer_45referrals_45faqnTkxFFQku6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnTkxFFQku6Meta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faqnTkxFFQku6Meta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnTkxFFQku6Meta || {},
    alias: customer_45referrals_45faqnTkxFFQku6Meta?.alias || [],
    redirect: customer_45referrals_45faqnTkxFFQku6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnTkxFFQku6Meta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faqnTkxFFQku6Meta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnTkxFFQku6Meta || {},
    alias: customer_45referrals_45faqnTkxFFQku6Meta?.alias || [],
    redirect: customer_45referrals_45faqnTkxFFQku6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqnTkxFFQku6Meta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faqnTkxFFQku6Meta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqnTkxFFQku6Meta || {},
    alias: customer_45referrals_45faqnTkxFFQku6Meta?.alias || [],
    redirect: customer_45referrals_45faqnTkxFFQku6Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk3UtU6ieWHMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanishk3UtU6ieWHMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanishk3UtU6ieWHMeta || {},
    alias: driver_45training_45spanishk3UtU6ieWHMeta?.alias || [],
    redirect: driver_45training_45spanishk3UtU6ieWHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk3UtU6ieWHMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanishk3UtU6ieWHMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanishk3UtU6ieWHMeta || {},
    alias: driver_45training_45spanishk3UtU6ieWHMeta?.alias || [],
    redirect: driver_45training_45spanishk3UtU6ieWHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk3UtU6ieWHMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanishk3UtU6ieWHMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanishk3UtU6ieWHMeta || {},
    alias: driver_45training_45spanishk3UtU6ieWHMeta?.alias || [],
    redirect: driver_45training_45spanishk3UtU6ieWHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk3UtU6ieWHMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanishk3UtU6ieWHMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanishk3UtU6ieWHMeta || {},
    alias: driver_45training_45spanishk3UtU6ieWHMeta?.alias || [],
    redirect: driver_45training_45spanishk3UtU6ieWHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk3UtU6ieWHMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanishk3UtU6ieWHMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanishk3UtU6ieWHMeta || {},
    alias: driver_45training_45spanishk3UtU6ieWHMeta?.alias || [],
    redirect: driver_45training_45spanishk3UtU6ieWHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk3UtU6ieWHMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanishk3UtU6ieWHMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanishk3UtU6ieWHMeta || {},
    alias: driver_45training_45spanishk3UtU6ieWHMeta?.alias || [],
    redirect: driver_45training_45spanishk3UtU6ieWHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk3UtU6ieWHMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanishk3UtU6ieWHMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanishk3UtU6ieWHMeta || {},
    alias: driver_45training_45spanishk3UtU6ieWHMeta?.alias || [],
    redirect: driver_45training_45spanishk3UtU6ieWHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishk3UtU6ieWHMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanishk3UtU6ieWHMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanishk3UtU6ieWHMeta || {},
    alias: driver_45training_45spanishk3UtU6ieWHMeta?.alias || [],
    redirect: driver_45training_45spanishk3UtU6ieWHMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingckSbGAxpo5Meta?.name ?? "support-driver-training___en-US",
    path: driver_45trainingckSbGAxpo5Meta?.path ?? "/support/driver-training",
    meta: driver_45trainingckSbGAxpo5Meta || {},
    alias: driver_45trainingckSbGAxpo5Meta?.alias || [],
    redirect: driver_45trainingckSbGAxpo5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingckSbGAxpo5Meta?.name ?? "support-driver-training___ca",
    path: driver_45trainingckSbGAxpo5Meta?.path ?? "/ca/support/driver-training",
    meta: driver_45trainingckSbGAxpo5Meta || {},
    alias: driver_45trainingckSbGAxpo5Meta?.alias || [],
    redirect: driver_45trainingckSbGAxpo5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingckSbGAxpo5Meta?.name ?? "support-driver-training___nl",
    path: driver_45trainingckSbGAxpo5Meta?.path ?? "/nl/support/driver-training",
    meta: driver_45trainingckSbGAxpo5Meta || {},
    alias: driver_45trainingckSbGAxpo5Meta?.alias || [],
    redirect: driver_45trainingckSbGAxpo5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingckSbGAxpo5Meta?.name ?? "support-driver-training___uk",
    path: driver_45trainingckSbGAxpo5Meta?.path ?? "/uk/support/driver-training",
    meta: driver_45trainingckSbGAxpo5Meta || {},
    alias: driver_45trainingckSbGAxpo5Meta?.alias || [],
    redirect: driver_45trainingckSbGAxpo5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingckSbGAxpo5Meta?.name ?? "support-driver-training___de",
    path: driver_45trainingckSbGAxpo5Meta?.path ?? "/de/support/driver-training",
    meta: driver_45trainingckSbGAxpo5Meta || {},
    alias: driver_45trainingckSbGAxpo5Meta?.alias || [],
    redirect: driver_45trainingckSbGAxpo5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingckSbGAxpo5Meta?.name ?? "support-driver-training___mx",
    path: driver_45trainingckSbGAxpo5Meta?.path ?? "/mx/support/driver-training",
    meta: driver_45trainingckSbGAxpo5Meta || {},
    alias: driver_45trainingckSbGAxpo5Meta?.alias || [],
    redirect: driver_45trainingckSbGAxpo5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingckSbGAxpo5Meta?.name ?? "support-driver-training___fr",
    path: driver_45trainingckSbGAxpo5Meta?.path ?? "/fr/support/driver-training",
    meta: driver_45trainingckSbGAxpo5Meta || {},
    alias: driver_45trainingckSbGAxpo5Meta?.alias || [],
    redirect: driver_45trainingckSbGAxpo5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingckSbGAxpo5Meta?.name ?? "support-driver-training___fr-ca",
    path: driver_45trainingckSbGAxpo5Meta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45trainingckSbGAxpo5Meta || {},
    alias: driver_45trainingckSbGAxpo5Meta?.alias || [],
    redirect: driver_45trainingckSbGAxpo5Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: indextiCSl59T8jMeta?.name ?? "support___en-US",
    path: indextiCSl59T8jMeta?.path ?? "/support",
    meta: indextiCSl59T8jMeta || {},
    alias: indextiCSl59T8jMeta?.alias || [],
    redirect: indextiCSl59T8jMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indextiCSl59T8jMeta?.name ?? "support___ca",
    path: indextiCSl59T8jMeta?.path ?? "/ca/support",
    meta: indextiCSl59T8jMeta || {},
    alias: indextiCSl59T8jMeta?.alias || [],
    redirect: indextiCSl59T8jMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indextiCSl59T8jMeta?.name ?? "support___nl",
    path: indextiCSl59T8jMeta?.path ?? "/nl/support",
    meta: indextiCSl59T8jMeta || {},
    alias: indextiCSl59T8jMeta?.alias || [],
    redirect: indextiCSl59T8jMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indextiCSl59T8jMeta?.name ?? "support___uk",
    path: indextiCSl59T8jMeta?.path ?? "/uk/support",
    meta: indextiCSl59T8jMeta || {},
    alias: indextiCSl59T8jMeta?.alias || [],
    redirect: indextiCSl59T8jMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indextiCSl59T8jMeta?.name ?? "support___de",
    path: indextiCSl59T8jMeta?.path ?? "/de/support",
    meta: indextiCSl59T8jMeta || {},
    alias: indextiCSl59T8jMeta?.alias || [],
    redirect: indextiCSl59T8jMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indextiCSl59T8jMeta?.name ?? "support___mx",
    path: indextiCSl59T8jMeta?.path ?? "/mx/support",
    meta: indextiCSl59T8jMeta || {},
    alias: indextiCSl59T8jMeta?.alias || [],
    redirect: indextiCSl59T8jMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indextiCSl59T8jMeta?.name ?? "support___fr",
    path: indextiCSl59T8jMeta?.path ?? "/fr/support",
    meta: indextiCSl59T8jMeta || {},
    alias: indextiCSl59T8jMeta?.alias || [],
    redirect: indextiCSl59T8jMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indextiCSl59T8jMeta?.name ?? "support___fr-ca",
    path: indextiCSl59T8jMeta?.path ?? "/fr-ca/support",
    meta: indextiCSl59T8jMeta || {},
    alias: indextiCSl59T8jMeta?.alias || [],
    redirect: indextiCSl59T8jMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industrialUdZmMmJd3hMeta?.name ?? "support-industrial___en-US",
    path: industrialUdZmMmJd3hMeta?.path ?? "/support/industrial",
    meta: industrialUdZmMmJd3hMeta || {},
    alias: industrialUdZmMmJd3hMeta?.alias || [],
    redirect: industrialUdZmMmJd3hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUdZmMmJd3hMeta?.name ?? "support-industrial___ca",
    path: industrialUdZmMmJd3hMeta?.path ?? "/ca/support/industrial",
    meta: industrialUdZmMmJd3hMeta || {},
    alias: industrialUdZmMmJd3hMeta?.alias || [],
    redirect: industrialUdZmMmJd3hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUdZmMmJd3hMeta?.name ?? "support-industrial___nl",
    path: industrialUdZmMmJd3hMeta?.path ?? "/nl/support/industrial",
    meta: industrialUdZmMmJd3hMeta || {},
    alias: industrialUdZmMmJd3hMeta?.alias || [],
    redirect: industrialUdZmMmJd3hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUdZmMmJd3hMeta?.name ?? "support-industrial___uk",
    path: industrialUdZmMmJd3hMeta?.path ?? "/uk/support/industrial",
    meta: industrialUdZmMmJd3hMeta || {},
    alias: industrialUdZmMmJd3hMeta?.alias || [],
    redirect: industrialUdZmMmJd3hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUdZmMmJd3hMeta?.name ?? "support-industrial___de",
    path: industrialUdZmMmJd3hMeta?.path ?? "/de/support/industrial",
    meta: industrialUdZmMmJd3hMeta || {},
    alias: industrialUdZmMmJd3hMeta?.alias || [],
    redirect: industrialUdZmMmJd3hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUdZmMmJd3hMeta?.name ?? "support-industrial___mx",
    path: industrialUdZmMmJd3hMeta?.path ?? "/mx/support/industrial",
    meta: industrialUdZmMmJd3hMeta || {},
    alias: industrialUdZmMmJd3hMeta?.alias || [],
    redirect: industrialUdZmMmJd3hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUdZmMmJd3hMeta?.name ?? "support-industrial___fr",
    path: industrialUdZmMmJd3hMeta?.path ?? "/fr/support/industrial",
    meta: industrialUdZmMmJd3hMeta || {},
    alias: industrialUdZmMmJd3hMeta?.alias || [],
    redirect: industrialUdZmMmJd3hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUdZmMmJd3hMeta?.name ?? "support-industrial___fr-ca",
    path: industrialUdZmMmJd3hMeta?.path ?? "/fr-ca/support/industrial",
    meta: industrialUdZmMmJd3hMeta || {},
    alias: industrialUdZmMmJd3hMeta?.alias || [],
    redirect: industrialUdZmMmJd3hMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdY1HbKgXbnMeta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statementdY1HbKgXbnMeta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdY1HbKgXbnMeta || {},
    alias: modern_45slavery_45statementdY1HbKgXbnMeta?.alias || [],
    redirect: modern_45slavery_45statementdY1HbKgXbnMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdY1HbKgXbnMeta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statementdY1HbKgXbnMeta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdY1HbKgXbnMeta || {},
    alias: modern_45slavery_45statementdY1HbKgXbnMeta?.alias || [],
    redirect: modern_45slavery_45statementdY1HbKgXbnMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdY1HbKgXbnMeta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statementdY1HbKgXbnMeta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdY1HbKgXbnMeta || {},
    alias: modern_45slavery_45statementdY1HbKgXbnMeta?.alias || [],
    redirect: modern_45slavery_45statementdY1HbKgXbnMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdY1HbKgXbnMeta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statementdY1HbKgXbnMeta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdY1HbKgXbnMeta || {},
    alias: modern_45slavery_45statementdY1HbKgXbnMeta?.alias || [],
    redirect: modern_45slavery_45statementdY1HbKgXbnMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdY1HbKgXbnMeta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statementdY1HbKgXbnMeta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdY1HbKgXbnMeta || {},
    alias: modern_45slavery_45statementdY1HbKgXbnMeta?.alias || [],
    redirect: modern_45slavery_45statementdY1HbKgXbnMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdY1HbKgXbnMeta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statementdY1HbKgXbnMeta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdY1HbKgXbnMeta || {},
    alias: modern_45slavery_45statementdY1HbKgXbnMeta?.alias || [],
    redirect: modern_45slavery_45statementdY1HbKgXbnMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdY1HbKgXbnMeta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statementdY1HbKgXbnMeta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdY1HbKgXbnMeta || {},
    alias: modern_45slavery_45statementdY1HbKgXbnMeta?.alias || [],
    redirect: modern_45slavery_45statementdY1HbKgXbnMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statementdY1HbKgXbnMeta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statementdY1HbKgXbnMeta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statementdY1HbKgXbnMeta || {},
    alias: modern_45slavery_45statementdY1HbKgXbnMeta?.alias || [],
    redirect: modern_45slavery_45statementdY1HbKgXbnMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy5FBfJteFgmMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policy5FBfJteFgmMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy5FBfJteFgmMeta || {},
    alias: apps_45privacy_45policy5FBfJteFgmMeta?.alias || [],
    redirect: apps_45privacy_45policy5FBfJteFgmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy5FBfJteFgmMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policy5FBfJteFgmMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy5FBfJteFgmMeta || {},
    alias: apps_45privacy_45policy5FBfJteFgmMeta?.alias || [],
    redirect: apps_45privacy_45policy5FBfJteFgmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy5FBfJteFgmMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policy5FBfJteFgmMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy5FBfJteFgmMeta || {},
    alias: apps_45privacy_45policy5FBfJteFgmMeta?.alias || [],
    redirect: apps_45privacy_45policy5FBfJteFgmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy5FBfJteFgmMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policy5FBfJteFgmMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy5FBfJteFgmMeta || {},
    alias: apps_45privacy_45policy5FBfJteFgmMeta?.alias || [],
    redirect: apps_45privacy_45policy5FBfJteFgmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy5FBfJteFgmMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policy5FBfJteFgmMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy5FBfJteFgmMeta || {},
    alias: apps_45privacy_45policy5FBfJteFgmMeta?.alias || [],
    redirect: apps_45privacy_45policy5FBfJteFgmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy5FBfJteFgmMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policy5FBfJteFgmMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy5FBfJteFgmMeta || {},
    alias: apps_45privacy_45policy5FBfJteFgmMeta?.alias || [],
    redirect: apps_45privacy_45policy5FBfJteFgmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy5FBfJteFgmMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policy5FBfJteFgmMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy5FBfJteFgmMeta || {},
    alias: apps_45privacy_45policy5FBfJteFgmMeta?.alias || [],
    redirect: apps_45privacy_45policy5FBfJteFgmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policy5FBfJteFgmMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policy5FBfJteFgmMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policy5FBfJteFgmMeta || {},
    alias: apps_45privacy_45policy5FBfJteFgmMeta?.alias || [],
    redirect: apps_45privacy_45policy5FBfJteFgmMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexXSyJEaYsJsMeta?.name ?? "support-privacy___en-US",
    path: indexXSyJEaYsJsMeta?.path ?? "/support/privacy",
    meta: indexXSyJEaYsJsMeta || {},
    alias: indexXSyJEaYsJsMeta?.alias || [],
    redirect: indexXSyJEaYsJsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexXSyJEaYsJsMeta?.name ?? "support-privacy___ca",
    path: indexXSyJEaYsJsMeta?.path ?? "/ca/support/privacy",
    meta: indexXSyJEaYsJsMeta || {},
    alias: indexXSyJEaYsJsMeta?.alias || [],
    redirect: indexXSyJEaYsJsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexXSyJEaYsJsMeta?.name ?? "support-privacy___nl",
    path: indexXSyJEaYsJsMeta?.path ?? "/nl/support/privacy",
    meta: indexXSyJEaYsJsMeta || {},
    alias: indexXSyJEaYsJsMeta?.alias || [],
    redirect: indexXSyJEaYsJsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexXSyJEaYsJsMeta?.name ?? "support-privacy___uk",
    path: indexXSyJEaYsJsMeta?.path ?? "/uk/support/privacy",
    meta: indexXSyJEaYsJsMeta || {},
    alias: indexXSyJEaYsJsMeta?.alias || [],
    redirect: indexXSyJEaYsJsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexXSyJEaYsJsMeta?.name ?? "support-privacy___de",
    path: indexXSyJEaYsJsMeta?.path ?? "/de/support/privacy",
    meta: indexXSyJEaYsJsMeta || {},
    alias: indexXSyJEaYsJsMeta?.alias || [],
    redirect: indexXSyJEaYsJsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexXSyJEaYsJsMeta?.name ?? "support-privacy___mx",
    path: indexXSyJEaYsJsMeta?.path ?? "/mx/support/privacy",
    meta: indexXSyJEaYsJsMeta || {},
    alias: indexXSyJEaYsJsMeta?.alias || [],
    redirect: indexXSyJEaYsJsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexXSyJEaYsJsMeta?.name ?? "support-privacy___fr",
    path: indexXSyJEaYsJsMeta?.path ?? "/fr/support/privacy",
    meta: indexXSyJEaYsJsMeta || {},
    alias: indexXSyJEaYsJsMeta?.alias || [],
    redirect: indexXSyJEaYsJsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexXSyJEaYsJsMeta?.name ?? "support-privacy___fr-ca",
    path: indexXSyJEaYsJsMeta?.path ?? "/fr-ca/support/privacy",
    meta: indexXSyJEaYsJsMeta || {},
    alias: indexXSyJEaYsJsMeta?.alias || [],
    redirect: indexXSyJEaYsJsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45features4p9tFFzp6dMeta?.name ?? "support-privacy-special-features___en-US",
    path: special_45features4p9tFFzp6dMeta?.path ?? "/support/privacy/special-features",
    meta: special_45features4p9tFFzp6dMeta || {},
    alias: special_45features4p9tFFzp6dMeta?.alias || [],
    redirect: special_45features4p9tFFzp6dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45features4p9tFFzp6dMeta?.name ?? "support-privacy-special-features___ca",
    path: special_45features4p9tFFzp6dMeta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45features4p9tFFzp6dMeta || {},
    alias: special_45features4p9tFFzp6dMeta?.alias || [],
    redirect: special_45features4p9tFFzp6dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45features4p9tFFzp6dMeta?.name ?? "support-privacy-special-features___nl",
    path: special_45features4p9tFFzp6dMeta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45features4p9tFFzp6dMeta || {},
    alias: special_45features4p9tFFzp6dMeta?.alias || [],
    redirect: special_45features4p9tFFzp6dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45features4p9tFFzp6dMeta?.name ?? "support-privacy-special-features___uk",
    path: special_45features4p9tFFzp6dMeta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45features4p9tFFzp6dMeta || {},
    alias: special_45features4p9tFFzp6dMeta?.alias || [],
    redirect: special_45features4p9tFFzp6dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45features4p9tFFzp6dMeta?.name ?? "support-privacy-special-features___de",
    path: special_45features4p9tFFzp6dMeta?.path ?? "/de/support/privacy/special-features",
    meta: special_45features4p9tFFzp6dMeta || {},
    alias: special_45features4p9tFFzp6dMeta?.alias || [],
    redirect: special_45features4p9tFFzp6dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45features4p9tFFzp6dMeta?.name ?? "support-privacy-special-features___mx",
    path: special_45features4p9tFFzp6dMeta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45features4p9tFFzp6dMeta || {},
    alias: special_45features4p9tFFzp6dMeta?.alias || [],
    redirect: special_45features4p9tFFzp6dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45features4p9tFFzp6dMeta?.name ?? "support-privacy-special-features___fr",
    path: special_45features4p9tFFzp6dMeta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45features4p9tFFzp6dMeta || {},
    alias: special_45features4p9tFFzp6dMeta?.alias || [],
    redirect: special_45features4p9tFFzp6dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45features4p9tFFzp6dMeta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45features4p9tFFzp6dMeta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45features4p9tFFzp6dMeta || {},
    alias: special_45features4p9tFFzp6dMeta?.alias || [],
    redirect: special_45features4p9tFFzp6dMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listfr6w7X571UMeta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45listfr6w7X571UMeta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45listfr6w7X571UMeta || {},
    alias: subprocessor_45listfr6w7X571UMeta?.alias || [],
    redirect: subprocessor_45listfr6w7X571UMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listfr6w7X571UMeta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45listfr6w7X571UMeta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listfr6w7X571UMeta || {},
    alias: subprocessor_45listfr6w7X571UMeta?.alias || [],
    redirect: subprocessor_45listfr6w7X571UMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listfr6w7X571UMeta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45listfr6w7X571UMeta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45listfr6w7X571UMeta || {},
    alias: subprocessor_45listfr6w7X571UMeta?.alias || [],
    redirect: subprocessor_45listfr6w7X571UMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listfr6w7X571UMeta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45listfr6w7X571UMeta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45listfr6w7X571UMeta || {},
    alias: subprocessor_45listfr6w7X571UMeta?.alias || [],
    redirect: subprocessor_45listfr6w7X571UMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listfr6w7X571UMeta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45listfr6w7X571UMeta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45listfr6w7X571UMeta || {},
    alias: subprocessor_45listfr6w7X571UMeta?.alias || [],
    redirect: subprocessor_45listfr6w7X571UMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listfr6w7X571UMeta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45listfr6w7X571UMeta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45listfr6w7X571UMeta || {},
    alias: subprocessor_45listfr6w7X571UMeta?.alias || [],
    redirect: subprocessor_45listfr6w7X571UMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listfr6w7X571UMeta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45listfr6w7X571UMeta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45listfr6w7X571UMeta || {},
    alias: subprocessor_45listfr6w7X571UMeta?.alias || [],
    redirect: subprocessor_45listfr6w7X571UMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listfr6w7X571UMeta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45listfr6w7X571UMeta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listfr6w7X571UMeta || {},
    alias: subprocessor_45listfr6w7X571UMeta?.alias || [],
    redirect: subprocessor_45listfr6w7X571UMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesFeWOilHESwMeta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articlesFeWOilHESwMeta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articlesFeWOilHESwMeta || {},
    alias: support_45kb_45articlesFeWOilHESwMeta?.alias || [],
    redirect: support_45kb_45articlesFeWOilHESwMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesFeWOilHESwMeta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articlesFeWOilHESwMeta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articlesFeWOilHESwMeta || {},
    alias: support_45kb_45articlesFeWOilHESwMeta?.alias || [],
    redirect: support_45kb_45articlesFeWOilHESwMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesFeWOilHESwMeta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articlesFeWOilHESwMeta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articlesFeWOilHESwMeta || {},
    alias: support_45kb_45articlesFeWOilHESwMeta?.alias || [],
    redirect: support_45kb_45articlesFeWOilHESwMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesFeWOilHESwMeta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articlesFeWOilHESwMeta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articlesFeWOilHESwMeta || {},
    alias: support_45kb_45articlesFeWOilHESwMeta?.alias || [],
    redirect: support_45kb_45articlesFeWOilHESwMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagex9ygZipSw7Meta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45pagex9ygZipSw7Meta?.path ?? "/thank-you-page",
    meta: thank_45you_45pagex9ygZipSw7Meta || {},
    alias: thank_45you_45pagex9ygZipSw7Meta?.alias || [],
    redirect: thank_45you_45pagex9ygZipSw7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagex9ygZipSw7Meta?.name ?? "thank-you-page___ca",
    path: thank_45you_45pagex9ygZipSw7Meta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45pagex9ygZipSw7Meta || {},
    alias: thank_45you_45pagex9ygZipSw7Meta?.alias || [],
    redirect: thank_45you_45pagex9ygZipSw7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagex9ygZipSw7Meta?.name ?? "thank-you-page___nl",
    path: thank_45you_45pagex9ygZipSw7Meta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45pagex9ygZipSw7Meta || {},
    alias: thank_45you_45pagex9ygZipSw7Meta?.alias || [],
    redirect: thank_45you_45pagex9ygZipSw7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagex9ygZipSw7Meta?.name ?? "thank-you-page___uk",
    path: thank_45you_45pagex9ygZipSw7Meta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45pagex9ygZipSw7Meta || {},
    alias: thank_45you_45pagex9ygZipSw7Meta?.alias || [],
    redirect: thank_45you_45pagex9ygZipSw7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagex9ygZipSw7Meta?.name ?? "thank-you-page___de",
    path: thank_45you_45pagex9ygZipSw7Meta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45pagex9ygZipSw7Meta || {},
    alias: thank_45you_45pagex9ygZipSw7Meta?.alias || [],
    redirect: thank_45you_45pagex9ygZipSw7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagex9ygZipSw7Meta?.name ?? "thank-you-page___mx",
    path: thank_45you_45pagex9ygZipSw7Meta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45pagex9ygZipSw7Meta || {},
    alias: thank_45you_45pagex9ygZipSw7Meta?.alias || [],
    redirect: thank_45you_45pagex9ygZipSw7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagex9ygZipSw7Meta?.name ?? "thank-you-page___fr",
    path: thank_45you_45pagex9ygZipSw7Meta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45pagex9ygZipSw7Meta || {},
    alias: thank_45you_45pagex9ygZipSw7Meta?.alias || [],
    redirect: thank_45you_45pagex9ygZipSw7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pagex9ygZipSw7Meta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45pagex9ygZipSw7Meta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45pagex9ygZipSw7Meta || {},
    alias: thank_45you_45pagex9ygZipSw7Meta?.alias || [],
    redirect: thank_45you_45pagex9ygZipSw7Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingvSWGtuBsn0Meta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruitingvSWGtuBsn0Meta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruitingvSWGtuBsn0Meta || {},
    alias: thankyou_45recruitingvSWGtuBsn0Meta?.alias || [],
    redirect: thankyou_45recruitingvSWGtuBsn0Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingvSWGtuBsn0Meta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruitingvSWGtuBsn0Meta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruitingvSWGtuBsn0Meta || {},
    alias: thankyou_45recruitingvSWGtuBsn0Meta?.alias || [],
    redirect: thankyou_45recruitingvSWGtuBsn0Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingvSWGtuBsn0Meta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruitingvSWGtuBsn0Meta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruitingvSWGtuBsn0Meta || {},
    alias: thankyou_45recruitingvSWGtuBsn0Meta?.alias || [],
    redirect: thankyou_45recruitingvSWGtuBsn0Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingvSWGtuBsn0Meta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruitingvSWGtuBsn0Meta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruitingvSWGtuBsn0Meta || {},
    alias: thankyou_45recruitingvSWGtuBsn0Meta?.alias || [],
    redirect: thankyou_45recruitingvSWGtuBsn0Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingvSWGtuBsn0Meta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruitingvSWGtuBsn0Meta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruitingvSWGtuBsn0Meta || {},
    alias: thankyou_45recruitingvSWGtuBsn0Meta?.alias || [],
    redirect: thankyou_45recruitingvSWGtuBsn0Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingvSWGtuBsn0Meta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruitingvSWGtuBsn0Meta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruitingvSWGtuBsn0Meta || {},
    alias: thankyou_45recruitingvSWGtuBsn0Meta?.alias || [],
    redirect: thankyou_45recruitingvSWGtuBsn0Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingvSWGtuBsn0Meta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruitingvSWGtuBsn0Meta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruitingvSWGtuBsn0Meta || {},
    alias: thankyou_45recruitingvSWGtuBsn0Meta?.alias || [],
    redirect: thankyou_45recruitingvSWGtuBsn0Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingvSWGtuBsn0Meta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruitingvSWGtuBsn0Meta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruitingvSWGtuBsn0Meta || {},
    alias: thankyou_45recruitingvSWGtuBsn0Meta?.alias || [],
    redirect: thankyou_45recruitingvSWGtuBsn0Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referraltgK9BjQfOTMeta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referraltgK9BjQfOTMeta?.path ?? "/thankyou-referral",
    meta: thankyou_45referraltgK9BjQfOTMeta || {},
    alias: thankyou_45referraltgK9BjQfOTMeta?.alias || [],
    redirect: thankyou_45referraltgK9BjQfOTMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referraltgK9BjQfOTMeta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referraltgK9BjQfOTMeta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referraltgK9BjQfOTMeta || {},
    alias: thankyou_45referraltgK9BjQfOTMeta?.alias || [],
    redirect: thankyou_45referraltgK9BjQfOTMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referraltgK9BjQfOTMeta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referraltgK9BjQfOTMeta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referraltgK9BjQfOTMeta || {},
    alias: thankyou_45referraltgK9BjQfOTMeta?.alias || [],
    redirect: thankyou_45referraltgK9BjQfOTMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referraltgK9BjQfOTMeta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referraltgK9BjQfOTMeta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referraltgK9BjQfOTMeta || {},
    alias: thankyou_45referraltgK9BjQfOTMeta?.alias || [],
    redirect: thankyou_45referraltgK9BjQfOTMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referraltgK9BjQfOTMeta?.name ?? "thankyou-referral___de",
    path: thankyou_45referraltgK9BjQfOTMeta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referraltgK9BjQfOTMeta || {},
    alias: thankyou_45referraltgK9BjQfOTMeta?.alias || [],
    redirect: thankyou_45referraltgK9BjQfOTMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referraltgK9BjQfOTMeta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referraltgK9BjQfOTMeta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referraltgK9BjQfOTMeta || {},
    alias: thankyou_45referraltgK9BjQfOTMeta?.alias || [],
    redirect: thankyou_45referraltgK9BjQfOTMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referraltgK9BjQfOTMeta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referraltgK9BjQfOTMeta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referraltgK9BjQfOTMeta || {},
    alias: thankyou_45referraltgK9BjQfOTMeta?.alias || [],
    redirect: thankyou_45referraltgK9BjQfOTMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referraltgK9BjQfOTMeta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referraltgK9BjQfOTMeta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referraltgK9BjQfOTMeta || {},
    alias: thankyou_45referraltgK9BjQfOTMeta?.alias || [],
    redirect: thankyou_45referraltgK9BjQfOTMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyouq81R1a4fQSMeta?.name ?? "thankyou___en-US",
    path: thankyouq81R1a4fQSMeta?.path ?? "/thankyou",
    meta: thankyouq81R1a4fQSMeta || {},
    alias: thankyouq81R1a4fQSMeta?.alias || [],
    redirect: thankyouq81R1a4fQSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouq81R1a4fQSMeta?.name ?? "thankyou___ca",
    path: thankyouq81R1a4fQSMeta?.path ?? "/ca/thankyou",
    meta: thankyouq81R1a4fQSMeta || {},
    alias: thankyouq81R1a4fQSMeta?.alias || [],
    redirect: thankyouq81R1a4fQSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouq81R1a4fQSMeta?.name ?? "thankyou___nl",
    path: thankyouq81R1a4fQSMeta?.path ?? "/nl/thankyou",
    meta: thankyouq81R1a4fQSMeta || {},
    alias: thankyouq81R1a4fQSMeta?.alias || [],
    redirect: thankyouq81R1a4fQSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouq81R1a4fQSMeta?.name ?? "thankyou___uk",
    path: thankyouq81R1a4fQSMeta?.path ?? "/uk/thankyou",
    meta: thankyouq81R1a4fQSMeta || {},
    alias: thankyouq81R1a4fQSMeta?.alias || [],
    redirect: thankyouq81R1a4fQSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouq81R1a4fQSMeta?.name ?? "thankyou___de",
    path: thankyouq81R1a4fQSMeta?.path ?? "/de/thankyou",
    meta: thankyouq81R1a4fQSMeta || {},
    alias: thankyouq81R1a4fQSMeta?.alias || [],
    redirect: thankyouq81R1a4fQSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouq81R1a4fQSMeta?.name ?? "thankyou___mx",
    path: thankyouq81R1a4fQSMeta?.path ?? "/mx/thankyou",
    meta: thankyouq81R1a4fQSMeta || {},
    alias: thankyouq81R1a4fQSMeta?.alias || [],
    redirect: thankyouq81R1a4fQSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouq81R1a4fQSMeta?.name ?? "thankyou___fr",
    path: thankyouq81R1a4fQSMeta?.path ?? "/fr/thankyou",
    meta: thankyouq81R1a4fQSMeta || {},
    alias: thankyouq81R1a4fQSMeta?.alias || [],
    redirect: thankyouq81R1a4fQSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouq81R1a4fQSMeta?.name ?? "thankyou___fr-ca",
    path: thankyouq81R1a4fQSMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyouq81R1a4fQSMeta || {},
    alias: thankyouq81R1a4fQSMeta?.alias || [],
    redirect: thankyouq81R1a4fQSMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: eliteQyxMSOUcZ4Meta?.name ?? "tiers-elite___uk",
    path: eliteQyxMSOUcZ4Meta?.path ?? "/uk/tiers/elite",
    meta: eliteQyxMSOUcZ4Meta || {},
    alias: eliteQyxMSOUcZ4Meta?.alias || [],
    redirect: eliteQyxMSOUcZ4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteQyxMSOUcZ4Meta?.name ?? "tiers-elite___en-US",
    path: eliteQyxMSOUcZ4Meta?.path ?? "/tiers/elite",
    meta: eliteQyxMSOUcZ4Meta || {},
    alias: eliteQyxMSOUcZ4Meta?.alias || [],
    redirect: eliteQyxMSOUcZ4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteQyxMSOUcZ4Meta?.name ?? "tiers-elite___ca",
    path: eliteQyxMSOUcZ4Meta?.path ?? "/ca/tiers/elite",
    meta: eliteQyxMSOUcZ4Meta || {},
    alias: eliteQyxMSOUcZ4Meta?.alias || [],
    redirect: eliteQyxMSOUcZ4Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: plusvBMspXsz8TMeta?.name ?? "tiers-plus___uk",
    path: plusvBMspXsz8TMeta?.path ?? "/uk/tiers/plus",
    meta: plusvBMspXsz8TMeta || {},
    alias: plusvBMspXsz8TMeta?.alias || [],
    redirect: plusvBMspXsz8TMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusvBMspXsz8TMeta?.name ?? "tiers-plus___en-US",
    path: plusvBMspXsz8TMeta?.path ?? "/tiers/plus",
    meta: plusvBMspXsz8TMeta || {},
    alias: plusvBMspXsz8TMeta?.alias || [],
    redirect: plusvBMspXsz8TMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusvBMspXsz8TMeta?.name ?? "tiers-plus___ca",
    path: plusvBMspXsz8TMeta?.path ?? "/ca/tiers/plus",
    meta: plusvBMspXsz8TMeta || {},
    alias: plusvBMspXsz8TMeta?.alias || [],
    redirect: plusvBMspXsz8TMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premierm3iOrktZiNMeta?.name ?? "tiers-premier___uk",
    path: premierm3iOrktZiNMeta?.path ?? "/uk/tiers/premier",
    meta: premierm3iOrktZiNMeta || {},
    alias: premierm3iOrktZiNMeta?.alias || [],
    redirect: premierm3iOrktZiNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierm3iOrktZiNMeta?.name ?? "tiers-premier___en-US",
    path: premierm3iOrktZiNMeta?.path ?? "/tiers/premier",
    meta: premierm3iOrktZiNMeta || {},
    alias: premierm3iOrktZiNMeta?.alias || [],
    redirect: premierm3iOrktZiNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierm3iOrktZiNMeta?.name ?? "tiers-premier___ca",
    path: premierm3iOrktZiNMeta?.path ?? "/ca/tiers/premier",
    meta: premierm3iOrktZiNMeta || {},
    alias: premierm3iOrktZiNMeta?.alias || [],
    redirect: premierm3iOrktZiNMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starterFbtt011i9vMeta?.name ?? "tiers-starter___uk",
    path: starterFbtt011i9vMeta?.path ?? "/uk/tiers/starter",
    meta: starterFbtt011i9vMeta || {},
    alias: starterFbtt011i9vMeta?.alias || [],
    redirect: starterFbtt011i9vMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterFbtt011i9vMeta?.name ?? "tiers-starter___en-US",
    path: starterFbtt011i9vMeta?.path ?? "/tiers/starter",
    meta: starterFbtt011i9vMeta || {},
    alias: starterFbtt011i9vMeta?.alias || [],
    redirect: starterFbtt011i9vMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterFbtt011i9vMeta?.name ?? "tiers-starter___ca",
    path: starterFbtt011i9vMeta?.path ?? "/ca/tiers/starter",
    meta: starterFbtt011i9vMeta || {},
    alias: starterFbtt011i9vMeta?.alias || [],
    redirect: starterFbtt011i9vMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: processc0CdcLatrvMeta?.name ?? "trial-process___en-US",
    path: processc0CdcLatrvMeta?.path ?? "/trial/process",
    meta: processc0CdcLatrvMeta || {},
    alias: processc0CdcLatrvMeta?.alias || [],
    redirect: processc0CdcLatrvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processc0CdcLatrvMeta?.name ?? "trial-process___ca",
    path: processc0CdcLatrvMeta?.path ?? "/ca/trial/process",
    meta: processc0CdcLatrvMeta || {},
    alias: processc0CdcLatrvMeta?.alias || [],
    redirect: processc0CdcLatrvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processc0CdcLatrvMeta?.name ?? "trial-process___nl",
    path: processc0CdcLatrvMeta?.path ?? "/nl/trial/process",
    meta: processc0CdcLatrvMeta || {},
    alias: processc0CdcLatrvMeta?.alias || [],
    redirect: processc0CdcLatrvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processc0CdcLatrvMeta?.name ?? "trial-process___uk",
    path: processc0CdcLatrvMeta?.path ?? "/uk/trial/process",
    meta: processc0CdcLatrvMeta || {},
    alias: processc0CdcLatrvMeta?.alias || [],
    redirect: processc0CdcLatrvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processc0CdcLatrvMeta?.name ?? "trial-process___de",
    path: processc0CdcLatrvMeta?.path ?? "/de/trial/process",
    meta: processc0CdcLatrvMeta || {},
    alias: processc0CdcLatrvMeta?.alias || [],
    redirect: processc0CdcLatrvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processc0CdcLatrvMeta?.name ?? "trial-process___mx",
    path: processc0CdcLatrvMeta?.path ?? "/mx/trial/process",
    meta: processc0CdcLatrvMeta || {},
    alias: processc0CdcLatrvMeta?.alias || [],
    redirect: processc0CdcLatrvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processc0CdcLatrvMeta?.name ?? "trial-process___fr",
    path: processc0CdcLatrvMeta?.path ?? "/fr/trial/process",
    meta: processc0CdcLatrvMeta || {},
    alias: processc0CdcLatrvMeta?.alias || [],
    redirect: processc0CdcLatrvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processc0CdcLatrvMeta?.name ?? "trial-process___fr-ca",
    path: processc0CdcLatrvMeta?.path ?? "/fr-ca/trial/process",
    meta: processc0CdcLatrvMeta || {},
    alias: processc0CdcLatrvMeta?.alias || [],
    redirect: processc0CdcLatrvMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: supportMXB8D6RRhsMeta?.name ?? "warranty-support___uk",
    path: supportMXB8D6RRhsMeta?.path ?? "/uk/warranty/support",
    meta: supportMXB8D6RRhsMeta || {},
    alias: supportMXB8D6RRhsMeta?.alias || [],
    redirect: supportMXB8D6RRhsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportMXB8D6RRhsMeta?.name ?? "warranty-support___en-US",
    path: supportMXB8D6RRhsMeta?.path ?? "/warranty/support",
    meta: supportMXB8D6RRhsMeta || {},
    alias: supportMXB8D6RRhsMeta?.alias || [],
    redirect: supportMXB8D6RRhsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportMXB8D6RRhsMeta?.name ?? "warranty-support___ca",
    path: supportMXB8D6RRhsMeta?.path ?? "/ca/warranty/support",
    meta: supportMXB8D6RRhsMeta || {},
    alias: supportMXB8D6RRhsMeta?.alias || [],
    redirect: supportMXB8D6RRhsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportMXB8D6RRhsMeta?.name ?? "warranty-support___ie",
    path: supportMXB8D6RRhsMeta?.path ?? "/ie/warranty/support",
    meta: supportMXB8D6RRhsMeta || {},
    alias: supportMXB8D6RRhsMeta?.alias || [],
    redirect: supportMXB8D6RRhsMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ILlhcSalJaMeta?.name ?? "webinars-slug___en-US",
    path: _91slug_93ILlhcSalJaMeta?.path ?? "/webinars/:slug()",
    meta: _91slug_93ILlhcSalJaMeta || {},
    alias: _91slug_93ILlhcSalJaMeta?.alias || [],
    redirect: _91slug_93ILlhcSalJaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ILlhcSalJaMeta?.name ?? "webinars-slug___ca",
    path: _91slug_93ILlhcSalJaMeta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93ILlhcSalJaMeta || {},
    alias: _91slug_93ILlhcSalJaMeta?.alias || [],
    redirect: _91slug_93ILlhcSalJaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ILlhcSalJaMeta?.name ?? "webinars-slug___nl",
    path: _91slug_93ILlhcSalJaMeta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93ILlhcSalJaMeta || {},
    alias: _91slug_93ILlhcSalJaMeta?.alias || [],
    redirect: _91slug_93ILlhcSalJaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ILlhcSalJaMeta?.name ?? "webinars-slug___uk",
    path: _91slug_93ILlhcSalJaMeta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93ILlhcSalJaMeta || {},
    alias: _91slug_93ILlhcSalJaMeta?.alias || [],
    redirect: _91slug_93ILlhcSalJaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ILlhcSalJaMeta?.name ?? "webinars-slug___de",
    path: _91slug_93ILlhcSalJaMeta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93ILlhcSalJaMeta || {},
    alias: _91slug_93ILlhcSalJaMeta?.alias || [],
    redirect: _91slug_93ILlhcSalJaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ILlhcSalJaMeta?.name ?? "webinars-slug___mx",
    path: _91slug_93ILlhcSalJaMeta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93ILlhcSalJaMeta || {},
    alias: _91slug_93ILlhcSalJaMeta?.alias || [],
    redirect: _91slug_93ILlhcSalJaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ILlhcSalJaMeta?.name ?? "webinars-slug___fr",
    path: _91slug_93ILlhcSalJaMeta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93ILlhcSalJaMeta || {},
    alias: _91slug_93ILlhcSalJaMeta?.alias || [],
    redirect: _91slug_93ILlhcSalJaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ILlhcSalJaMeta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93ILlhcSalJaMeta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93ILlhcSalJaMeta || {},
    alias: _91slug_93ILlhcSalJaMeta?.alias || [],
    redirect: _91slug_93ILlhcSalJaMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: index15anEVp2m9Meta?.name ?? "webinars___en-US",
    path: index15anEVp2m9Meta?.path ?? "/webinars",
    meta: index15anEVp2m9Meta || {},
    alias: index15anEVp2m9Meta?.alias || [],
    redirect: index15anEVp2m9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index15anEVp2m9Meta?.name ?? "webinars___ca",
    path: index15anEVp2m9Meta?.path ?? "/ca/webinars",
    meta: index15anEVp2m9Meta || {},
    alias: index15anEVp2m9Meta?.alias || [],
    redirect: index15anEVp2m9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index15anEVp2m9Meta?.name ?? "webinars___nl",
    path: index15anEVp2m9Meta?.path ?? "/nl/webinars",
    meta: index15anEVp2m9Meta || {},
    alias: index15anEVp2m9Meta?.alias || [],
    redirect: index15anEVp2m9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index15anEVp2m9Meta?.name ?? "webinars___uk",
    path: index15anEVp2m9Meta?.path ?? "/uk/webinars",
    meta: index15anEVp2m9Meta || {},
    alias: index15anEVp2m9Meta?.alias || [],
    redirect: index15anEVp2m9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index15anEVp2m9Meta?.name ?? "webinars___de",
    path: index15anEVp2m9Meta?.path ?? "/de/webinars",
    meta: index15anEVp2m9Meta || {},
    alias: index15anEVp2m9Meta?.alias || [],
    redirect: index15anEVp2m9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index15anEVp2m9Meta?.name ?? "webinars___mx",
    path: index15anEVp2m9Meta?.path ?? "/mx/webinars",
    meta: index15anEVp2m9Meta || {},
    alias: index15anEVp2m9Meta?.alias || [],
    redirect: index15anEVp2m9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index15anEVp2m9Meta?.name ?? "webinars___fr",
    path: index15anEVp2m9Meta?.path ?? "/fr/webinars",
    meta: index15anEVp2m9Meta || {},
    alias: index15anEVp2m9Meta?.alias || [],
    redirect: index15anEVp2m9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: index15anEVp2m9Meta?.name ?? "webinars___fr-ca",
    path: index15anEVp2m9Meta?.path ?? "/fr-ca/webinars",
    meta: index15anEVp2m9Meta || {},
    alias: index15anEVp2m9Meta?.alias || [],
    redirect: index15anEVp2m9Meta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qwBmwClP0kMeta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_93qwBmwClP0kMeta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_93qwBmwClP0kMeta || {},
    alias: _91slug_93qwBmwClP0kMeta?.alias || [],
    redirect: _91slug_93qwBmwClP0kMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qwBmwClP0kMeta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_93qwBmwClP0kMeta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_93qwBmwClP0kMeta || {},
    alias: _91slug_93qwBmwClP0kMeta?.alias || [],
    redirect: _91slug_93qwBmwClP0kMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qwBmwClP0kMeta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_93qwBmwClP0kMeta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_93qwBmwClP0kMeta || {},
    alias: _91slug_93qwBmwClP0kMeta?.alias || [],
    redirect: _91slug_93qwBmwClP0kMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qwBmwClP0kMeta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_93qwBmwClP0kMeta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_93qwBmwClP0kMeta || {},
    alias: _91slug_93qwBmwClP0kMeta?.alias || [],
    redirect: _91slug_93qwBmwClP0kMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qwBmwClP0kMeta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_93qwBmwClP0kMeta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_93qwBmwClP0kMeta || {},
    alias: _91slug_93qwBmwClP0kMeta?.alias || [],
    redirect: _91slug_93qwBmwClP0kMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qwBmwClP0kMeta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_93qwBmwClP0kMeta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_93qwBmwClP0kMeta || {},
    alias: _91slug_93qwBmwClP0kMeta?.alias || [],
    redirect: _91slug_93qwBmwClP0kMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qwBmwClP0kMeta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_93qwBmwClP0kMeta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_93qwBmwClP0kMeta || {},
    alias: _91slug_93qwBmwClP0kMeta?.alias || [],
    redirect: _91slug_93qwBmwClP0kMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93qwBmwClP0kMeta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_93qwBmwClP0kMeta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_93qwBmwClP0kMeta || {},
    alias: _91slug_93qwBmwClP0kMeta?.alias || [],
    redirect: _91slug_93qwBmwClP0kMeta?.redirect,
    component: () => import("/codebuild/output/src4175480164/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  }
]